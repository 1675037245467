"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PUBLIC_PROJECT_TEMPLATE = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_PUBLIC_PROJECT_TEMPLATE = (0, core_1.gql) `
  query FindPublicProjectTemplate($projectTemplateId: String!) {
    findPublicProjectTemplate(id: $projectTemplateId) {
      id
      name
      type
      subtitle
      githubRepoTemplateName
      isPublished
      orderIndex
      theme
      viewOnMarketplace
      briefId
      tags {
        name
      }
    }
  }
`;
