"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialUserValues = void 0;
const constants_1 = require("../rating/constants");
exports.initialUserValues = {
    id: "",
    username: "",
    groups: [],
    email: "",
    roles: [],
    avatarUrl: "",
    bio: "",
    isBlocked: false,
    isActive: false,
    nerdcoins: 0,
    organisationId: "",
    rating: constants_1.initialRating,
    isOnboardingComplete: false,
    createdAt: "",
    lastSeenAt: "",
};
