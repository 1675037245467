"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REMOVE_HACKAPOINTS_FOR_TEAM = void 0;
const core_1 = require("@apollo/client/core");
exports.REMOVE_HACKAPOINTS_FOR_TEAM = (0, core_1.gql) `
  mutation RemoveHackapointsForTeam(
    $teamId: String!
    $hackapoints: Int!
    $reason: String!
  ) {
    removeHackapointsForTeam(
      teamId: $teamId
      hackapoints: $hackapoints
      reason: $reason
    ) {
      id
      hackapoints
    }
  }
`;
