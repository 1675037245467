"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.USER_UPDATED = void 0;
const client_1 = require("@apollo/client");
exports.USER_UPDATED = (0, client_1.gql) `
  subscription onUserUpdated($userId: String!) {
    userUpdated(userId: $userId) {
      id
      email
      username
      avatarUrl
      isBlocked
      organisationId
      discordUserId
      roles
      isOnboardingComplete
    }
  }
`;
