"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_HACKATHON_RATINGS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_HACKATHON_RATINGS = (0, client_1.gql) `
  query FindHackathonRatings($take: Int) {
    findHackathonRatings(take: $take) {
      username
      userId
      avatarUrl
      githubUsername
      hackapoints
      hackathons
    }
  }
`;
