"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateTaskTemplate = void 0;
const client_1 = require("@apollo/client");
const updateTaskTemplate_1 = require("../mutations/updateTaskTemplate");
const useUpdateTaskTemplate = () => {
    const [update, { error, loading }] = (0, client_1.useMutation)(updateTaskTemplate_1.UPDATE_TASK_TEMPLATE_TITLE);
    const updateTaskTemplateTitle = (id, title) => __awaiter(void 0, void 0, void 0, function* () {
        return yield update({
            variables: {
                id,
                title,
            },
            optimisticResponse: {
                updateTaskTemplateTitle: {
                    id,
                    //@ts-ignore
                    __typename: "TaskTemplateEntity",
                    title,
                },
            },
            update: (cache) => {
                updateTaskTemplateCache(cache, id, title);
            },
        });
    });
    const updateTaskTemplateCache = (cache, taskTemplateId, title) => {
        const taskToUpdate = {
            id: taskTemplateId,
            __typename: "TaskTemplateEntity",
        };
        cache.modify({
            id: cache.identify(taskToUpdate),
            fields: {
                title(prev) {
                    return title || prev;
                },
            },
        });
    };
    return {
        updateTaskTemplateTitle,
        loading,
        error,
    };
};
exports.useUpdateTaskTemplate = useUpdateTaskTemplate;
