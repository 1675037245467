"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_TEAM_MEMBERS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_TEAM_MEMBERS = (0, client_1.gql) `
  query FindTeamMembers($teamId: String!) {
    findTeamMembers(teamId: $teamId) {
      id
      username
      avatarUrl
    }
  }
`;
