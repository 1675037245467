"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPublicTaskTemplate = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findPublicTaskTemplate_1 = require("../queries/findPublicTaskTemplate");
const useFindPublicTaskTemplate = (taskTemplateId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findPublicTaskTemplate_1.FIND_PUBLIC_TASK_TEMPLATE, {
        fetchPolicy: "cache-first",
        variables: {
            taskTemplateId,
        },
    });
    return {
        taskTemplate: loading
            ? constants_1.defaultTaskTemplate
            : (data === null || data === void 0 ? void 0 : data.findPublicTaskTemplate) || constants_1.defaultTaskTemplate,
        loading,
        refetch,
        error,
    };
};
exports.useFindPublicTaskTemplate = useFindPublicTaskTemplate;
