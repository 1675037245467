"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindGroupTags = void 0;
const client_1 = require("@apollo/client");
const findGroupTags_1 = require("../queries/findGroupTags");
const useFindGroupTags = (groupId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findGroupTags_1.FIND_GROUP_TAGS, {
        variables: { groupId },
    });
    return {
        tags: loading ? [] : (data === null || data === void 0 ? void 0 : data.findGroupTags) || [],
        loading,
        refetch,
        error,
    };
};
exports.useFindGroupTags = useFindGroupTags;
