"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateOwnedProjectTemplate = void 0;
const client_1 = require("@apollo/client");
const functions_1 = require("../functions");
const updateOwnedProjectTemplate_1 = require("../mutations/updateOwnedProjectTemplate");
const findProjectTemplates_1 = require("../queries/findProjectTemplates");
const useUpdateOwnedProjectTemplate = () => {
    const [updateSingleProject, { loading, error }] = (0, client_1.useMutation)(updateOwnedProjectTemplate_1.UPDATE_OWNED_PROJECT_TEMPLATE, {
        refetchQueries: [findProjectTemplates_1.FIND_MY_PROJECTS_TEMPLATES],
    });
    const updateProjectTemplate = (id, data) => __awaiter(void 0, void 0, void 0, function* () {
        return yield updateSingleProject({
            variables: {
                input: {
                    where: { id },
                    data,
                },
            },
            optimisticResponse: {
                updateOwnedProjectTemplate: {
                    id,
                    //@ts-ignore
                    __typename: "ProjectTemplateEntity",
                    name: data === null || data === void 0 ? void 0 : data.name,
                    githubRepoDistribution: data === null || data === void 0 ? void 0 : data.githubRepoDistribution,
                    livePreviewUrl: data === null || data === void 0 ? void 0 : data.livePreviewUrl,
                },
            },
            update(cache) {
                updateProjectTemplateInCache(cache, id, data);
                data.theme && (0, functions_1.updateProjectTemplateLessonThemes)(cache)(id, data.theme);
            },
        });
    });
    const updateProjectTemplateInCache = (cache, projectTemplateId, data) => {
        const projectTemplateToUpdate = {
            id: projectTemplateId,
            __typename: "ProjectTemplateEntity",
        };
        cache.modify({
            id: cache.identify(projectTemplateToUpdate),
            fields: {
                name(prev) {
                    return (data === null || data === void 0 ? void 0 : data.name) || prev;
                },
                subtitle(prev) {
                    return (data === null || data === void 0 ? void 0 : data.subtitle) || prev;
                },
                theme(prev) {
                    return (data === null || data === void 0 ? void 0 : data.theme) || prev;
                },
                eta(prev) {
                    return (data === null || data === void 0 ? void 0 : data.eta) || prev;
                },
                difficultyLevel(prev) {
                    return (data === null || data === void 0 ? void 0 : data.difficultyLevel) || prev;
                },
                isExpGainOn(prev) {
                    return (data === null || data === void 0 ? void 0 : data.isExpGainOn) || prev;
                },
                livePreviewUrl(prev) {
                    return (data === null || data === void 0 ? void 0 : data.livePreviewUrl) || prev;
                },
                githubRepoTemplateName(prev) {
                    return (data === null || data === void 0 ? void 0 : data.githubRepoTemplateName) || prev;
                },
                githubRepoDistribution(prev) {
                    return (data === null || data === void 0 ? void 0 : data.githubRepoDistribution) || prev;
                },
            },
        });
    };
    return { updateProjectTemplate, loading, error };
};
exports.useUpdateOwnedProjectTemplate = useUpdateOwnedProjectTemplate;
