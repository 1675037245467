"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TASK_DELETED = void 0;
const client_1 = require("@apollo/client");
exports.TASK_DELETED = (0, client_1.gql) `
  subscription TaskCreated($projectId: String!) {
    taskDeleted(projectId: $projectId) {
      id
      state
      taskTemplate {
        title
      }
    }
  }
`;
