"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_GROUPS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_GROUPS = (0, client_1.gql) `
  query FindMyGroups {
    findMyGroups {
      id
      name
      state
      accesses {
        id
      }
    }
  }
`;
