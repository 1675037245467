"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRequestAiReview = void 0;
const client_1 = require("@apollo/client");
const __1 = require("../..");
const requestAiReview_1 = require("../mutations/requestAiReview");
const useRequestAiReview = () => {
    const [requestInstantReview, { error, loading }] = (0, client_1.useMutation)(requestAiReview_1.REQUEST_AI_REVIEW);
    const requestAiReview = (taskId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield requestInstantReview({
            variables: {
                taskId,
            },
            update: (cache, { data }) => {
                var _a;
                if (!((_a = data === null || data === void 0 ? void 0 : data.requestAiReview.taskReview) === null || _a === void 0 ? void 0 : _a.id))
                    return;
                (0, __1.updateTaskReviewStateInCache)(cache, data.requestAiReview.taskReview.id, __1.TaskReviewState.REVIEW_REQUESTED);
            },
        });
    });
    return {
        requestAiReview,
        loading,
        error,
    };
};
exports.useRequestAiReview = useRequestAiReview;
