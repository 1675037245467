"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRequestSupport = void 0;
const client_1 = require("@apollo/client");
const requestSupport_1 = require("../mutations/requestSupport");
const useRequestSupport = () => {
    const [send, { loading, called }] = (0, client_1.useMutation)(requestSupport_1.REQUEST_SUPPORT);
    const requestSupport = (description) => __awaiter(void 0, void 0, void 0, function* () {
        return yield send({
            variables: {
                description,
            },
        });
    });
    return { requestSupport, loading, called };
};
exports.useRequestSupport = useRequestSupport;
