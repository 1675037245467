"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResetTextSolution = void 0;
const client_1 = require("@apollo/client");
const getDocument_1 = require("../../document/queries/getDocument");
const resetTextSolution_1 = require("../mutations/resetTextSolution");
const useResetTextSolution = () => {
    const [reset, { error, loading }] = (0, client_1.useMutation)(resetTextSolution_1.RESET_TEXT_SOLUTION);
    const resetTextSolution = (taskId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield reset({
            variables: {
                taskId,
            },
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.resetTextSolution))
                    return;
                const prevDocumentData = cache.readQuery({
                    query: getDocument_1.GET_DOCUMENT,
                    variables: { id: data.resetTextSolution.id },
                });
                if (!prevDocumentData)
                    return;
                cache.writeQuery({
                    query: getDocument_1.GET_DOCUMENT,
                    variables: { id: data.resetTextSolution.id },
                    data: {
                        findDocument: Object.assign(Object.assign({}, prevDocumentData.findDocument), { content: data.resetTextSolution.content }),
                    },
                });
            },
        });
    });
    return {
        resetTextSolution,
        loading,
        error,
    };
};
exports.useResetTextSolution = useResetTextSolution;
