"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_RECRUITMENT_GROUP = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_RECRUITMENT_GROUP = (0, client_1.gql) `
  mutation CreateRecruitmentGroup($jobOfferUrl: String!, $prompt: String) {
    createRecruitmentGroup(jobOfferUrl: $jobOfferUrl, prompt: $prompt) {
      id
      name
      state
    }
  }
`;
