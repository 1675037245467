"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ARCHIVE_GROUP = void 0;
const client_1 = require("@apollo/client");
exports.ARCHIVE_GROUP = (0, client_1.gql) `
  mutation ArchiveGroup($id: String!) {
    archiveGroup(id: $id) {
      id
      name
      state
    }
  }
`;
