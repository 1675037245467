"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialOrg = exports.initialSubscription = void 0;
exports.initialSubscription = {
    createdAt: undefined,
    id: "",
    seats: 0,
    organisationId: "",
    isOnExpirationPeriod: false,
    stripeCustomerId: "",
    plan: "",
    price: 0,
    updatedAt: undefined,
};
exports.initialOrg = {
    avatarSrc: "",
    createdAt: "",
    email: "",
    id: "",
    isOnboardingComplete: false,
    subscription: exports.initialSubscription,
    name: "",
    members: [],
};
