"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUnresolvedTasksReviews = exports.updateTaskReviewStateInCache = void 0;
const findUnresolvedOrgTaskReviews_1 = require("../queries/findUnresolvedOrgTaskReviews");
const updateTaskReviewStateInCache = (cache, taskReviewId, state) => {
    const reviewToUpdate = {
        id: taskReviewId,
        __typename: "TaskReviewEntity",
    };
    cache.modify({
        id: cache.identify(reviewToUpdate),
        fields: {
            reviewState() {
                return state;
            },
            reviewRequests(prev) {
                return prev + 1;
            },
        },
    });
};
exports.updateTaskReviewStateInCache = updateTaskReviewStateInCache;
const updateUnresolvedTasksReviews = (cache, taskReviewId) => {
    const reviews = cache.readQuery({
        query: findUnresolvedOrgTaskReviews_1.FIND_UNRESOLVED_ORG_TASK_REVIEWS,
    });
    const filteredReviews = reviews.findUnresolvedOrgTaskReviews.filter((review) => review.id !== taskReviewId);
    cache.writeQuery({
        query: findUnresolvedOrgTaskReviews_1.FIND_UNRESOLVED_ORG_TASK_REVIEWS,
        data: {
            findUnresolvedOrgTaskReviews: filteredReviews,
        },
    });
};
exports.updateUnresolvedTasksReviews = updateUnresolvedTasksReviews;
