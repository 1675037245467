"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_TEAM = void 0;
const client_1 = require("@apollo/client");
exports.FIND_TEAM = (0, client_1.gql) `
  query FindTeam($teamId: String!) {
    findTeam(teamId: $teamId) {
      id
      name
      discordTextChannelId
      discordVoiceChannelId
      apiKey
      pinCode
      hackapoints
    }
  }
`;
