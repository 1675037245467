"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INIT_LESSON = void 0;
const client_1 = require("@apollo/client");
exports.INIT_LESSON = (0, client_1.gql) `
  mutation InitLesson($lessonScheduleId: String!, $projectId: String!) {
    initLesson(lessonScheduleId: $lessonScheduleId, projectId: $projectId) {
      lesson {
        id
        state
        isVisibleOnBoard
        lessonScheduleId
        lessonTemplate {
          id
          orderIndex
          name
          briefId
        }
      }
      progress {
        completedTasksAmount
        scheduledTasksAmount
      }
    }
  }
`;
