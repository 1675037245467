"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMPILE_CODE = void 0;
const client_1 = require("@apollo/client");
exports.COMPILE_CODE = (0, client_1.gql) `
  mutation CompileCode($code: String!, $lang: CodeLanguage!) {
    compileCode(code: $code, lang: $lang) {
      language
      run {
        stderr
        stdout
        output
      }
    }
  }
`;
