"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useForceApproveTaskPull = void 0;
const client_1 = require("@apollo/client");
const __1 = require("../..");
const helpers_1 = require("../helpers");
const forceApproveTaskPullRequest_1 = require("../mutations/forceApproveTaskPullRequest");
const useForceApproveTaskPull = () => {
    const [approve, { error, loading }] = (0, client_1.useMutation)(forceApproveTaskPullRequest_1.FORCE_APPROVE_TASK_PULL);
    const forceApproveTask = (taskId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield approve({
            variables: {
                taskId,
            },
            update: (cache, { data }) => {
                var _a, _b;
                if (!((_b = (_a = data === null || data === void 0 ? void 0 : data.forceApproveCodeReview) === null || _a === void 0 ? void 0 : _a.taskReview) === null || _b === void 0 ? void 0 : _b.id))
                    return;
                (0, helpers_1.updateTaskReviewStateInCache)(cache, data.forceApproveCodeReview.taskReview.id, __1.TaskReviewState.APPROVED);
            },
        });
    });
    return {
        forceApproveTask,
        loading,
        error,
    };
};
exports.useForceApproveTaskPull = useForceApproveTaskPull;
