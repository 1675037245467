"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_PROJECT_FROM_TEMPLATE = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_PROJECT_FROM_TEMPLATE = (0, client_1.gql) `
  mutation CreateProjectFromTemplate($projectTemplateId: String!) {
    createProjectFromTemplate(projectTemplateId: $projectTemplateId) {
      id
      name
      isPublished
      type
      state
      theme
      orderIndex
      githubRepoTemplateName
      lessonTemplates {
        name
        theme
        isPublished
        id
        taskTemplates {
          id
        }
      }
    }
  }
`;
