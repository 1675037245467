"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateTaskState = exports.useUpdateTasksOrder = exports.useUpdateTaskCodeSolution = exports.useUpdateAiSolution = exports.useTaskSubscriptions = exports.useSubmitTaskReview = exports.useRestartMyTask = exports.useResetTextSolution = exports.useResetCodeSolution = exports.useRequestTaskReview = exports.useRequestInstantCodeSolutionReview = exports.useRequestInstantCodeReview = exports.useRequestCodeReview = exports.useRequestAiReview = exports.useRejectTask = exports.useForceApproveTaskPull = exports.useFindUnresolvedTaskReviews = exports.useFindTaskTextSolution = exports.useFindTaskReview = exports.useFindTaskCodeSolution = exports.useFindTaskAssignee = exports.useFindTaskAiSolution = exports.useFindProjectTasks = exports.useFindMyTasks = exports.useFindMyTask = exports.useFindMyStudentTasks = exports.useFindMyStudentTask = exports.useFindGroupTaskReviews = exports.useDeleteTask = exports.useCreateNewTask = exports.useCheckIfTaskIsCollaborative = exports.useAssignUserToTask = exports.useApproveTask = void 0;
var useApproveTask_1 = require("./useApproveTask");
Object.defineProperty(exports, "useApproveTask", { enumerable: true, get: function () { return useApproveTask_1.useApproveTask; } });
var useAssignUserToTask_1 = require("./useAssignUserToTask");
Object.defineProperty(exports, "useAssignUserToTask", { enumerable: true, get: function () { return useAssignUserToTask_1.useAssignUserToTask; } });
var useCheckIfTaskIsCollaborative_1 = require("./useCheckIfTaskIsCollaborative");
Object.defineProperty(exports, "useCheckIfTaskIsCollaborative", { enumerable: true, get: function () { return useCheckIfTaskIsCollaborative_1.useCheckIfTaskIsCollaborative; } });
var useCreateNewTask_1 = require("./useCreateNewTask");
Object.defineProperty(exports, "useCreateNewTask", { enumerable: true, get: function () { return useCreateNewTask_1.useCreateNewTask; } });
var useDeleteTask_1 = require("./useDeleteTask");
Object.defineProperty(exports, "useDeleteTask", { enumerable: true, get: function () { return useDeleteTask_1.useDeleteTask; } });
var useFindGroupTaskReviews_1 = require("./useFindGroupTaskReviews");
Object.defineProperty(exports, "useFindGroupTaskReviews", { enumerable: true, get: function () { return useFindGroupTaskReviews_1.useFindGroupTaskReviews; } });
var useFindMyStudentTask_1 = require("./useFindMyStudentTask");
Object.defineProperty(exports, "useFindMyStudentTask", { enumerable: true, get: function () { return useFindMyStudentTask_1.useFindMyStudentTask; } });
var useFindMyStudentTasks_1 = require("./useFindMyStudentTasks");
Object.defineProperty(exports, "useFindMyStudentTasks", { enumerable: true, get: function () { return useFindMyStudentTasks_1.useFindMyStudentTasks; } });
var useFindMyTask_1 = require("./useFindMyTask");
Object.defineProperty(exports, "useFindMyTask", { enumerable: true, get: function () { return useFindMyTask_1.useFindMyTask; } });
var useFindMyTasks_1 = require("./useFindMyTasks");
Object.defineProperty(exports, "useFindMyTasks", { enumerable: true, get: function () { return useFindMyTasks_1.useFindMyTasks; } });
var useFindProjectTasks_1 = require("./useFindProjectTasks");
Object.defineProperty(exports, "useFindProjectTasks", { enumerable: true, get: function () { return useFindProjectTasks_1.useFindProjectTasks; } });
var useFindTaskAiSolution_1 = require("./useFindTaskAiSolution");
Object.defineProperty(exports, "useFindTaskAiSolution", { enumerable: true, get: function () { return useFindTaskAiSolution_1.useFindTaskAiSolution; } });
var useFindTaskAssignee_1 = require("./useFindTaskAssignee");
Object.defineProperty(exports, "useFindTaskAssignee", { enumerable: true, get: function () { return useFindTaskAssignee_1.useFindTaskAssignee; } });
var useFindTaskCodeSolution_1 = require("./useFindTaskCodeSolution");
Object.defineProperty(exports, "useFindTaskCodeSolution", { enumerable: true, get: function () { return useFindTaskCodeSolution_1.useFindTaskCodeSolution; } });
var useFindTaskReview_1 = require("./useFindTaskReview");
Object.defineProperty(exports, "useFindTaskReview", { enumerable: true, get: function () { return useFindTaskReview_1.useFindTaskReview; } });
var useFindTaskTextSolution_1 = require("./useFindTaskTextSolution");
Object.defineProperty(exports, "useFindTaskTextSolution", { enumerable: true, get: function () { return useFindTaskTextSolution_1.useFindTaskTextSolution; } });
var useFindUnresolvedTaskReviews_1 = require("./useFindUnresolvedTaskReviews");
Object.defineProperty(exports, "useFindUnresolvedTaskReviews", { enumerable: true, get: function () { return useFindUnresolvedTaskReviews_1.useFindUnresolvedTaskReviews; } });
var useForceApproveTaskPull_1 = require("./useForceApproveTaskPull");
Object.defineProperty(exports, "useForceApproveTaskPull", { enumerable: true, get: function () { return useForceApproveTaskPull_1.useForceApproveTaskPull; } });
var useRejectTask_1 = require("./useRejectTask");
Object.defineProperty(exports, "useRejectTask", { enumerable: true, get: function () { return useRejectTask_1.useRejectTask; } });
var useRequestAiReview_1 = require("./useRequestAiReview");
Object.defineProperty(exports, "useRequestAiReview", { enumerable: true, get: function () { return useRequestAiReview_1.useRequestAiReview; } });
var useRequestCodeReview_1 = require("./useRequestCodeReview");
Object.defineProperty(exports, "useRequestCodeReview", { enumerable: true, get: function () { return useRequestCodeReview_1.useRequestCodeReview; } });
var useRequestInstantCodeReview_1 = require("./useRequestInstantCodeReview");
Object.defineProperty(exports, "useRequestInstantCodeReview", { enumerable: true, get: function () { return useRequestInstantCodeReview_1.useRequestInstantCodeReview; } });
var useRequestInstantCodeSolutionReview_1 = require("./useRequestInstantCodeSolutionReview");
Object.defineProperty(exports, "useRequestInstantCodeSolutionReview", { enumerable: true, get: function () { return useRequestInstantCodeSolutionReview_1.useRequestInstantCodeSolutionReview; } });
var useRequestTaskReview_1 = require("./useRequestTaskReview");
Object.defineProperty(exports, "useRequestTaskReview", { enumerable: true, get: function () { return useRequestTaskReview_1.useRequestTaskReview; } });
var useResetCodeSolution_1 = require("./useResetCodeSolution");
Object.defineProperty(exports, "useResetCodeSolution", { enumerable: true, get: function () { return useResetCodeSolution_1.useResetCodeSolution; } });
var useResetTextSolution_1 = require("./useResetTextSolution");
Object.defineProperty(exports, "useResetTextSolution", { enumerable: true, get: function () { return useResetTextSolution_1.useResetTextSolution; } });
var useRestartMyTask_1 = require("./useRestartMyTask");
Object.defineProperty(exports, "useRestartMyTask", { enumerable: true, get: function () { return useRestartMyTask_1.useRestartMyTask; } });
var useSubmitTaskReview_1 = require("./useSubmitTaskReview");
Object.defineProperty(exports, "useSubmitTaskReview", { enumerable: true, get: function () { return useSubmitTaskReview_1.useSubmitTaskReview; } });
var useTaskSubscriptions_1 = require("./useTaskSubscriptions");
Object.defineProperty(exports, "useTaskSubscriptions", { enumerable: true, get: function () { return useTaskSubscriptions_1.useTaskSubscriptions; } });
var useUpdateAiSolution_1 = require("./useUpdateAiSolution");
Object.defineProperty(exports, "useUpdateAiSolution", { enumerable: true, get: function () { return useUpdateAiSolution_1.useUpdateAiSolution; } });
var useUpdateTaskCodeSolution_1 = require("./useUpdateTaskCodeSolution");
Object.defineProperty(exports, "useUpdateTaskCodeSolution", { enumerable: true, get: function () { return useUpdateTaskCodeSolution_1.useUpdateTaskCodeSolution; } });
var useUpdateTasksOrder_1 = require("./useUpdateTasksOrder");
Object.defineProperty(exports, "useUpdateTasksOrder", { enumerable: true, get: function () { return useUpdateTasksOrder_1.useUpdateTasksOrder; } });
var useUpdateTaskState_1 = require("./useUpdateTaskState");
Object.defineProperty(exports, "useUpdateTaskState", { enumerable: true, get: function () { return useUpdateTaskState_1.useUpdateTaskState; } });
