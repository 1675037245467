"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_ORGANISATION_TEAM_MEMBERS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_ORGANISATION_TEAM_MEMBERS = (0, client_1.gql) `
  query FindMyOrganisationTeamMembers {
    findMyOrgTeamMembers {
      id
      members {
        id
        username
        avatarUrl
        email
        roles
      }
    }
  }
`;
