"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CALCULATE_MY_PROGRAM_PROGRESS = void 0;
const client_1 = require("@apollo/client");
exports.CALCULATE_MY_PROGRAM_PROGRESS = (0, client_1.gql) `
  query CalculateMyProgramProgress {
    calculateMyProgramProgress {
      programProgressInPercent
    }
  }
`;
