"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialTaskSchedule = void 0;
const constants_1 = require("../task-template/constants");
exports.initialTaskSchedule = {
    id: "",
    lessonScheduleId: "",
    state: "",
    tasks: [],
    taskTemplate: constants_1.defaultTaskTemplate,
    taskTemplateId: "",
    assignee: null,
};
