"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateProjectTemplatesOrder = exports.useUpdateProjectTemplate = exports.useUpdateOwnedProjectTemplate = exports.useUnpublishProjectTemplate = exports.useSetProjectRepoAsTemplate = exports.usePublishProjectTemplate = exports.useMakePublicProjectTemplate = exports.useGenerateProjectTemplate = exports.useGenerateProjectRepo = exports.useFindPublicProjectTemplates = exports.useFindPublicProjectTemplateDocument = exports.useFindPublicProjectTemplate = exports.useFindProjectTemplatesLibrary = exports.useFindProjectsTemplates = exports.useFindOwnedProjectTemplate = exports.useDeleteProjectTemplate = exports.useCreateProjectTemplate = exports.useCreateProjectFromTemplate = void 0;
var useCreateProjectFromTemplate_1 = require("./useCreateProjectFromTemplate");
Object.defineProperty(exports, "useCreateProjectFromTemplate", { enumerable: true, get: function () { return useCreateProjectFromTemplate_1.useCreateProjectFromTemplate; } });
var useCreateProjectTemplate_1 = require("./useCreateProjectTemplate");
Object.defineProperty(exports, "useCreateProjectTemplate", { enumerable: true, get: function () { return useCreateProjectTemplate_1.useCreateProjectTemplate; } });
var useDeleteProjectTemplate_1 = require("./useDeleteProjectTemplate");
Object.defineProperty(exports, "useDeleteProjectTemplate", { enumerable: true, get: function () { return useDeleteProjectTemplate_1.useDeleteProjectTemplate; } });
var useFindOwnedProjectTemplate_1 = require("./useFindOwnedProjectTemplate");
Object.defineProperty(exports, "useFindOwnedProjectTemplate", { enumerable: true, get: function () { return useFindOwnedProjectTemplate_1.useFindOwnedProjectTemplate; } });
var useFindProjectsTemplates_1 = require("./useFindProjectsTemplates");
Object.defineProperty(exports, "useFindProjectsTemplates", { enumerable: true, get: function () { return useFindProjectsTemplates_1.useFindProjectsTemplates; } });
var useFindProjectTemplatesLibrary_1 = require("./useFindProjectTemplatesLibrary");
Object.defineProperty(exports, "useFindProjectTemplatesLibrary", { enumerable: true, get: function () { return useFindProjectTemplatesLibrary_1.useFindProjectTemplatesLibrary; } });
var useFindPublicProjectTemplate_1 = require("./useFindPublicProjectTemplate");
Object.defineProperty(exports, "useFindPublicProjectTemplate", { enumerable: true, get: function () { return useFindPublicProjectTemplate_1.useFindPublicProjectTemplate; } });
var useFindPublicProjectTemplateDocument_1 = require("./useFindPublicProjectTemplateDocument");
Object.defineProperty(exports, "useFindPublicProjectTemplateDocument", { enumerable: true, get: function () { return useFindPublicProjectTemplateDocument_1.useFindPublicProjectTemplateDocument; } });
var useFindPublicProjectTemplates_1 = require("./useFindPublicProjectTemplates");
Object.defineProperty(exports, "useFindPublicProjectTemplates", { enumerable: true, get: function () { return useFindPublicProjectTemplates_1.useFindPublicProjectTemplates; } });
var useGenerateProjectRepo_1 = require("./useGenerateProjectRepo");
Object.defineProperty(exports, "useGenerateProjectRepo", { enumerable: true, get: function () { return useGenerateProjectRepo_1.useGenerateProjectRepo; } });
var useGenerateProjectTemplate_1 = require("./useGenerateProjectTemplate");
Object.defineProperty(exports, "useGenerateProjectTemplate", { enumerable: true, get: function () { return useGenerateProjectTemplate_1.useGenerateProjectTemplate; } });
var useMakePublicProjectTemplate_1 = require("./useMakePublicProjectTemplate");
Object.defineProperty(exports, "useMakePublicProjectTemplate", { enumerable: true, get: function () { return useMakePublicProjectTemplate_1.useMakePublicProjectTemplate; } });
var usePublishProjectTemplate_1 = require("./usePublishProjectTemplate");
Object.defineProperty(exports, "usePublishProjectTemplate", { enumerable: true, get: function () { return usePublishProjectTemplate_1.usePublishProjectTemplate; } });
var useSetProjectRepoAsTemplate_1 = require("./useSetProjectRepoAsTemplate");
Object.defineProperty(exports, "useSetProjectRepoAsTemplate", { enumerable: true, get: function () { return useSetProjectRepoAsTemplate_1.useSetProjectRepoAsTemplate; } });
var useUnpublishProjectTemplate_1 = require("./useUnpublishProjectTemplate");
Object.defineProperty(exports, "useUnpublishProjectTemplate", { enumerable: true, get: function () { return useUnpublishProjectTemplate_1.useUnpublishProjectTemplate; } });
var useUpdateOwnedProjectTemplate_1 = require("./useUpdateOwnedProjectTemplate");
Object.defineProperty(exports, "useUpdateOwnedProjectTemplate", { enumerable: true, get: function () { return useUpdateOwnedProjectTemplate_1.useUpdateOwnedProjectTemplate; } });
var useUpdateProjectTemplate_1 = require("./useUpdateProjectTemplate");
Object.defineProperty(exports, "useUpdateProjectTemplate", { enumerable: true, get: function () { return useUpdateProjectTemplate_1.useUpdateProjectTemplate; } });
var useUpdateProjectTemplatesOrder_1 = require("./useUpdateProjectTemplatesOrder");
Object.defineProperty(exports, "useUpdateProjectTemplatesOrder", { enumerable: true, get: function () { return useUpdateProjectTemplatesOrder_1.useUpdateProjectTemplatesOrder; } });
