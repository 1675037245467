"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_ACTIVE_GROUP = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_ACTIVE_GROUP = (0, client_1.gql) `
  query FindMyActiveGroup {
    findMyActiveGroup {
      id
      name
      state
      pricePerSeat
      currency
      paymentMode
      discordSettings {
        id
        discordChannelUrl
      }
      settings {
        id
        allowTryForFree
        maxSeats
      }
      page {
        shortDsc
      }
      tags {
        name
      }
      _count {
        users
      }
    }
  }
`;
