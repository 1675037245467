"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateGroupPage = void 0;
const client_1 = require("@apollo/client");
const createGroupPage_1 = require("../mutations/createGroupPage");
const useCreateGroupPage = () => {
    const [createNewGroup, { loading, called,
    // client: { cache },
     },] = (0, client_1.useMutation)(createGroupPage_1.CREATE_GROUP_PAGE);
    const createGroupPage = (groupId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield createNewGroup({
            variables: { groupId },
            update: (_cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.initGroupPage))
                    return;
                // cache.writeQuery({
                //   query: FIND_MY_GROUP,
                //   variables: { groupId },
                //   data: {},
                // });
            },
        });
    });
    return { createGroupPage, loading, called };
};
exports.useCreateGroupPage = useCreateGroupPage;
