"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateTaskTemplate = void 0;
const client_1 = require("@apollo/client");
const createTaskTemplate_1 = require("../mutations/createTaskTemplate");
const findOwnedLessonTaskTemplates_1 = require("../queries/findOwnedLessonTaskTemplates");
const useCreateTaskTemplate = () => {
    const [createSingleTaskTemplate, { error, loading, client }] = (0, client_1.useMutation)(createTaskTemplate_1.CREATE_TASK_TEMPLATE);
    const createTaskTemplate = (lessonTemplateId, type) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const response = yield createSingleTaskTemplate({
            variables: {
                type,
                lessonTemplateId,
            },
            update: (_cache, res) => {
                var _a;
                if (!((_a = res.data) === null || _a === void 0 ? void 0 : _a.createTaskTemplate))
                    return;
                const { createTaskTemplate } = res.data;
                updateLessonTasks(lessonTemplateId, createTaskTemplate);
                increaseProjectTemplateTasksCount(lessonTemplateId);
                updateTasks(lessonTemplateId, createTaskTemplate);
            },
        });
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.createTaskTemplate;
    });
    const updateTasks = (lessonTemplateId, taskTemplate) => {
        const { cache } = client;
        const { findOwnedLessonTaskTemplates } = cache.readQuery({
            query: findOwnedLessonTaskTemplates_1.FIND_OWNED_LESSON_TASK_TEMPLATES,
            variables: {
                lessonTemplateId,
            },
        });
        cache.writeQuery({
            query: findOwnedLessonTaskTemplates_1.FIND_OWNED_LESSON_TASK_TEMPLATES,
            variables: {
                lessonTemplateId,
            },
            data: {
                findOwnedLessonTaskTemplates: [
                    ...findOwnedLessonTaskTemplates,
                    taskTemplate,
                ],
            },
        });
    };
    const increaseProjectTemplateTasksCount = (lessonTemplateId) => {
        const { cache } = client;
        const lessonTemplateToUpdate = {
            id: lessonTemplateId,
            __typename: "LessonTemplateEntity",
        };
        const lessonTemplateCount = client.readFragment({
            id: cache.identify(lessonTemplateToUpdate),
            fragment: (0, client_1.gql) `
        fragment LessonTemplateCount on LessonTemplateEntity {
          _count {
            taskTemplates
          }
        }
      `,
        });
        if (!lessonTemplateCount._count) {
            throw Error("Count not found on LessonTemplateEntity");
        }
        client.writeFragment({
            id: cache.identify(lessonTemplateToUpdate),
            fragment: (0, client_1.gql) `
        fragment LessonTemplateCount on LessonTemplateEntity {
          _count {
            taskTemplates
          }
        }
      `,
            data: {
                _count: {
                    taskTemplates: lessonTemplateCount._count.taskTemplates + 1,
                },
            },
        });
    };
    const updateLessonTasks = (lessonTemplateId, taskTemplate) => {
        const { cache } = client;
        const lessonTemplateToUpdate = {
            id: lessonTemplateId,
            __typename: "LessonTemplateEntity",
        };
        cache.modify({
            id: cache.identify(lessonTemplateToUpdate),
            fields: {
                taskTemplates(prev) {
                    return [...prev, taskTemplate];
                },
            },
        });
    };
    return { createTaskTemplate, loading, error };
};
exports.useCreateTaskTemplate = useCreateTaskTemplate;
