"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_PROJECT_TEMPLATES_TO_PROGRAM = void 0;
const client_1 = require("@apollo/client");
exports.ADD_PROJECT_TEMPLATES_TO_PROGRAM = (0, client_1.gql) `
  mutation AddProjectTemplatesToProgram(
    $programId: String!
    $projectTemplates: [JSONObject!]!
    $collaborationType: CollaborationType!
  ) {
    addProjectTemplatesToProgram(
      programId: $programId
      projectTemplates: $projectTemplates
      collaborationType: $collaborationType
    ) {
      id
      projectSchedules {
        id
        projectTemplate {
          name
          type
        }
        lessonSchedules {
          id
          lessonTemplate {
            name
          }
          taskSchedules {
            id
            taskTemplate {
              title
            }
          }
        }
      }
    }
  }
`;
