"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyOrgStripeTransactions = void 0;
const client_1 = require("@apollo/client");
const findMyOrgStripeTransactions_1 = require("../queries/findMyOrgStripeTransactions");
const useFindMyOrgStripeTransactions = () => {
    const { data, error, loading } = (0, client_1.useQuery)(findMyOrgStripeTransactions_1.FIND_MY_ORG_STRIPE_TRANSACTIONS, {
        fetchPolicy: "cache-first",
    });
    return {
        transactions: loading ? [] : (data === null || data === void 0 ? void 0 : data.findMyOrgStripeTransactions) || [],
        error,
        loading,
    };
};
exports.useFindMyOrgStripeTransactions = useFindMyOrgStripeTransactions;
