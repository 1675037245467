"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PUBLIC_PROJECT_TEMPLATE_DOCUMENT = void 0;
const client_1 = require("@apollo/client");
exports.FIND_PUBLIC_PROJECT_TEMPLATE_DOCUMENT = (0, client_1.gql) `
  query FindPublicProjectTemplateDocument($id: String!) {
    findPublicProjectTemplateDocument(id: $id) {
      id
      content
    }
  }
`;
