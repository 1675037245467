"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUEST_REVIEW = void 0;
const client_1 = require("@apollo/client");
exports.REQUEST_REVIEW = (0, client_1.gql) `
  mutation RequestTaskReview($taskId: String!) {
    requestTaskReview(taskId: $taskId) {
      id
      state
    }
  }
`;
