"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TASK_UPDATED = void 0;
const client_1 = require("@apollo/client");
exports.TASK_UPDATED = (0, client_1.gql) `
  subscription TaskUpdated($projectId: String!) {
    taskUpdated(projectId: $projectId) {
      id
      state
      orderIndex
      lessonId
      taskScheduleId
      taskReview {
        id
        reviewState
      }
      user {
        id
        username
        avatarUrl
        email
      }
      taskTemplate {
        id
        title
        orderIndex
        briefId
        type
        lessonTemplate {
          id
          name
          theme
          taskTemplates {
            id
          }
        }
      }
    }
  }
`;
