"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTeamProjects = void 0;
const client_1 = require("@apollo/client");
const __1 = require("../..");
const findTeamProjects_1 = require("../queries/findTeamProjects");
const useFindTeamProjects = (teamId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findTeamProjects_1.FIND_TEAM_PROJECTS, {
        variables: { teamId },
    });
    return {
        projects: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findTeamProjects.map(__1.mapProjectWithProgressToProject)) || [],
        error,
        loading,
        refetch,
    };
};
exports.useFindTeamProjects = useFindTeamProjects;
