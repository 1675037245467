"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_STUDENT_TASK = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_STUDENT_TASK = (0, client_1.gql) `
  query FindMyStudentTask($taskId: String!) {
    findMyStudentTask(taskId: $taskId) {
      id
      state
      orderIndex
      textSolutionId
      lessonId
      taskReview {
        reviewState
      }
      taskTemplate {
        id
        title
        type
        briefId
        tags {
          name
        }
        lessonTemplate {
          id
          name
          theme
          orderIndex
        }
      }
    }
  }
`;
