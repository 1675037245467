"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_PROJECT_COLLABORATOR = void 0;
const client_1 = require("@apollo/client");
exports.ADD_PROJECT_COLLABORATOR = (0, client_1.gql) `
  mutation AddProjectCollaborator(
    $projectScheduleId: String!
    $userId: String!
  ) {
    addProjectCollaborator(
      projectScheduleId: $projectScheduleId
      userId: $userId
    ) {
      id
    }
  }
`;
