"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnPublishLessonTemplate = void 0;
const client_1 = require("@apollo/client");
exports.UnPublishLessonTemplate = (0, client_1.gql) `
  mutation UnPublishCourseLessonTemplate($lessonTemplateId: String!) {
    unPublishLessonTemplate(lessonTemplateId: $lessonTemplateId) {
      id
    }
  }
`;
