"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindHackathonRatings = void 0;
const client_1 = require("@apollo/client");
const findHackathonRatings_1 = require("../queries/findHackathonRatings");
const useFindHackathonRatings = (take) => {
    const { data, loading } = (0, client_1.useQuery)(findHackathonRatings_1.FIND_HACKATHON_RATINGS, { variables: { take } });
    return {
        ratings: loading ? [] : (data === null || data === void 0 ? void 0 : data.findHackathonRatings) || [],
    };
};
exports.useFindHackathonRatings = useFindHackathonRatings;
