"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_PROJECTS_TEMPLATES = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_MY_PROJECTS_TEMPLATES = (0, core_1.gql) `
  query FindMyProjectTemplates {
    findProjectTemplates {
      id
      name
      isPublished
      type
      state
      theme
      authorId
      isExpGainOn
      author {
        email
        avatarUrl
        username
      }
      tags {
        id
        name
      }
      githubRepoTemplateName
      lessonTemplates {
        id
        _count {
          taskTemplates
        }
      }
      _count {
        projectSchedules
      }
    }
  }
`;
