"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindGroupAccessRequests = void 0;
const client_1 = require("@apollo/client");
const findGroupAccessRequests_1 = require("../queries/findGroupAccessRequests");
const useFindGroupAccessRequests = (groupId) => {
    const { data, error, loading, refetch, fetchMore } = (0, client_1.useQuery)(findGroupAccessRequests_1.FIND_GROUPS_ACCESS_REQUESTS, {
        fetchPolicy: "cache-first",
        pollInterval: 30000,
        variables: { groupId },
    });
    return {
        accesses: loading ? [] : (data === null || data === void 0 ? void 0 : data.findGroupAccessRequests) || [],
        error,
        loading,
        refetch,
        fetchMore,
    };
};
exports.useFindGroupAccessRequests = useFindGroupAccessRequests;
