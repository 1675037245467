"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_NEW_TASK = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_NEW_TASK = (0, client_1.gql) `
  mutation CreateNewTask($lessonId: String!) {
    createNewTask(lessonId: $lessonId) {
      id
      state
    }
  }
`;
