"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateTaskState = void 0;
const client_1 = require("@apollo/client");
const __1 = require("../..");
const updateTaskState_1 = require("../mutations/updateTaskState");
const useUpdateTaskState = () => {
    const [updateTask, { error, client }] = (0, client_1.useMutation)(updateTaskState_1.UPDATE_TASK_STATE);
    const updateState = (taskID, state) => __awaiter(void 0, void 0, void 0, function* () {
        yield updateTask({
            variables: { id: taskID, state },
            update: (cache) => {
                updateTaskStateInCache(cache, taskID, state);
            },
        });
    });
    const updateCourseTaskState = (taskID, state) => __awaiter(void 0, void 0, void 0, function* () {
        yield updateTask({
            variables: { id: taskID, state },
            update: (cache) => {
                updateTaskLessonInCache(cache, taskID, state);
                updateTaskStateInCache(cache, taskID, state);
            },
        });
    });
    const updateTaskStateInCache = (cache, taskId, newState) => {
        const updatedTask = {
            id: taskId,
            __typename: "TaskEntity",
        };
        cache.modify({
            id: cache.identify(updatedTask),
            fields: {
                state() {
                    return newState;
                },
            },
        });
    };
    const updateTaskLessonInCache = (cache, taskId, state) => {
        const updatedTask = {
            id: taskId,
            __typename: "TaskEntity",
        };
        const task = client.readFragment({
            id: cache.identify(updatedTask),
            fragment: (0, client_1.gql) `
        fragment MyTaskWithLessonId on TaskEntity {
          id
          lessonId
        }
      `,
        });
        const lessonToUpdate = {
            id: task.lessonId,
            __typename: "LessonEntity",
        };
        cache.modify({
            id: cache.identify(lessonToUpdate),
            fields: {
                completedTasksIds(prevState) {
                    return state === __1.ProgressState.DONE
                        ? [...prevState, taskId]
                        : prevState;
                },
            },
        });
    };
    return {
        updateState,
        updateCourseTaskState,
        error,
        client,
    };
};
exports.useUpdateTaskState = useUpdateTaskState;
