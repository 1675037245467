"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INIT_TASKS_FOR_EXISTING_LESSON = void 0;
const client_1 = require("@apollo/client");
exports.INIT_TASKS_FOR_EXISTING_LESSON = (0, client_1.gql) `
  mutation InitTasksForExistingLesson($lessonId: String!) {
    initNewTasksForLesson(lessonId: $lessonId) {
      id
      state
    }
  }
`;
