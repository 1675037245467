"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindGroupTeams = void 0;
const client_1 = require("@apollo/client");
const findGroupTeams_1 = require("../queries/findGroupTeams");
const useFindGroupTeams = (groupId) => {
    const { data, loading, error } = (0, client_1.useQuery)(findGroupTeams_1.FIND_GROUP_TEAMS, {
        variables: { groupId },
    });
    return { teams: loading ? [] : (data === null || data === void 0 ? void 0 : data.findGroupTeams) || [], loading, error };
};
exports.useFindGroupTeams = useFindGroupTeams;
