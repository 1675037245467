"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTaskSchedule = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const taskScheduleQueryMapper_1 = require("../mappers/taskScheduleQueryMapper");
const findTaskSchedule_1 = require("../queries/findTaskSchedule");
const initialMappedTaskSchedule = (0, taskScheduleQueryMapper_1.mapTaskScheduleEntity)(constants_1.initialTaskSchedule);
const useFindTaskSchedule = (id) => {
    const { data, loading } = (0, client_1.useQuery)(findTaskSchedule_1.FIND_TASK_SCHEDULE, {
        variables: {
            id,
        },
        fetchPolicy: "cache-first",
    });
    return {
        taskSchedule: loading
            ? initialMappedTaskSchedule
            : ((data === null || data === void 0 ? void 0 : data.findTaskSchedule) &&
                (0, taskScheduleQueryMapper_1.mapTaskScheduleEntity)(data.findTaskSchedule)) ||
                initialMappedTaskSchedule,
        loading,
    };
};
exports.useFindTaskSchedule = useFindTaskSchedule;
