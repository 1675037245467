"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_TASK_AI_SOLUTION = void 0;
const client_1 = require("@apollo/client");
exports.FIND_TASK_AI_SOLUTION = (0, client_1.gql) `
  query FindTaskAiSolution($taskId: String!) {
    findTaskAiSolution(taskId: $taskId) {
      id
      url
      file {
        id
        name
        url
      }
    }
  }
`;
