"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMySubscriptionItem = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findMySubscriptionItem_1 = require("../queries/findMySubscriptionItem");
const useFindMySubscriptionItem = () => {
    const { data, loading } = (0, client_1.useQuery)(findMySubscriptionItem_1.FIND_MY_SUBSCRIPTION_ITEM, { fetchPolicy: "cache-first" });
    return {
        item: loading
            ? constants_1.initialSubscriptionItem
            : (data === null || data === void 0 ? void 0 : data.findMySubscriptionItem) || constants_1.initialSubscriptionItem,
        loading,
    };
};
exports.useFindMySubscriptionItem = useFindMySubscriptionItem;
