"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateProjectLessonsTheme = exports.updateProjectTemplateLessonThemes = void 0;
const findProjectLessonTemplates_1 = require("../lesson-template/queries/findProjectLessonTemplates");
const updateProjectTemplateLessonThemes = (cache) => (projectTemplateId, theme) => {
    const { findOwnedProjectLessonTemplates } = cache.readQuery({
        query: findProjectLessonTemplates_1.FIND_OWNED_PROJECT_LESSON_TEMPLATES,
        variables: {
            projectTemplateId,
        },
    });
    const updatedLessons = findOwnedProjectLessonTemplates.map((lessonTemplate) => (Object.assign(Object.assign({}, lessonTemplate), { theme })));
    cache.writeQuery({
        query: findProjectLessonTemplates_1.FIND_OWNED_PROJECT_LESSON_TEMPLATES,
        data: {
            findOwnedProjectLessonTemplates: updatedLessons,
        },
        variables: {
            projectTemplateId,
        },
    });
};
exports.updateProjectTemplateLessonThemes = updateProjectTemplateLessonThemes;
const updateProjectLessonsTheme = (cache) => (projectTemplateId, theme) => {
    const projectTemplateToUpdate = {
        id: projectTemplateId,
        __typename: "ProjectTemplateEntity",
    };
    cache.modify({
        id: cache.identify(projectTemplateToUpdate),
        fields: {
            lessonTemplates(prev) {
                const mappedLessons = prev.map((lessonTemplate) => (Object.assign(Object.assign({}, lessonTemplate), { theme })));
                return mappedLessons;
            },
        },
    });
};
exports.updateProjectLessonsTheme = updateProjectLessonsTheme;
