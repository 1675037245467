"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESET_CODE_SOLUTION = void 0;
const client_1 = require("@apollo/client");
exports.RESET_CODE_SOLUTION = (0, client_1.gql) `
  mutation ResetCodeSolution($taskId: String!) {
    resetCodeSolution(taskId: $taskId) {
      id
      code
    }
  }
`;
