"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PROJECT_SCORE = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_PROJECT_SCORE = (0, core_1.gql) `
  query FindProjectScore($projectId: String!) {
    findProjectScore(projectId: $projectId) {
      id
      totalScore
      executionScore
      executionSummary
      executionSubcategories

      qualityScore
      qualitySummary
      qualitySubcategories

      creativityScore
      creativitySummary
      creativitySubcategories
    }
  }
`;
