"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateTaskComment = void 0;
const client_1 = require("@apollo/client");
const createTaskComment_1 = require("../mutations/createTaskComment");
const useCreateTaskComment = () => {
    const [createComment, { loading, called }] = (0, client_1.useMutation)(createTaskComment_1.CREATE_TASK_COMMENT);
    const createTaskComment = (taskId, comment) => __awaiter(void 0, void 0, void 0, function* () {
        return yield createComment({
            variables: {
                taskId,
                comment,
            },
        });
    });
    return { createTaskComment, loading, called };
};
exports.useCreateTaskComment = useCreateTaskComment;
