"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GITHUB_TASK_DETAILS_UPDATED = void 0;
const client_1 = require("@apollo/client");
exports.GITHUB_TASK_DETAILS_UPDATED = (0, client_1.gql) `
  subscription GithubTaskDetailsUpdated($taskId: String!) {
    githubDetailsUpdated(taskId: $taskId) {
      id
      pullNumber
      taskId
      repositoryName
      repositoryOwner
      merged
    }
  }
`;
