"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapTaskEntityToUserTask = void 0;
const graphql_1 = require("../../types/graphql");
const mapTaskEntityToUserTask = (task) => {
    var _a, _b, _c, _d;
    if (!task.user) {
        throw Error("No user include found");
    }
    const taskAssignee = !!((_a = task === null || task === void 0 ? void 0 : task.taskSchedule) === null || _a === void 0 ? void 0 : _a.assignee)
        ? {
            userEmail: task.taskSchedule.assignee.email,
            avatarUrl: ((_b = task.taskSchedule.assignee) === null || _b === void 0 ? void 0 : _b.avatarUrl) || "",
            username: ((_c = task.taskSchedule.assignee) === null || _c === void 0 ? void 0 : _c.username) || "",
        }
        : {
            userEmail: task.user.email,
            avatarUrl: task.user.avatarUrl || "",
            username: task.user.username || "",
        };
    return Object.assign({ id: task.id, orderIndex: task.orderIndex, reviewState: ((_d = task.taskReview) === null || _d === void 0 ? void 0 : _d.reviewState) || graphql_1.TaskReviewState.NO_REVIEW_REQUESTED, state: task.state }, taskAssignee);
};
exports.mapTaskEntityToUserTask = mapTaskEntityToUserTask;
