"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PULL_REQUEST_CONDITIONS = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_PULL_REQUEST_CONDITIONS = (0, core_1.gql) `
  query FindPullRequestConditions($taskTemplateId: String!) {
    findPullRequestConditions(taskTemplateId: $taskTemplateId) {
      id
      isOn
      isMergeRequired
      isRequired
      codeReviewPrompt
    }
  }
`;
