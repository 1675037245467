"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetProjectScheduleMaxCompletionHours = void 0;
const client_1 = require("@apollo/client");
const setMaxCompletionTimeHours_1 = require("../mutations/setMaxCompletionTimeHours");
const useSetProjectScheduleMaxCompletionHours = () => {
    const [setState, { loading, called }] = (0, client_1.useMutation)(setMaxCompletionTimeHours_1.SET_MAX_COMPLETION_TIME);
    const setProjectScheduleMaxCompletionHours = (id, maxCompletionTimeHours) => __awaiter(void 0, void 0, void 0, function* () {
        return yield setState({
            variables: {
                id,
                maxCompletionTimeHours,
            },
            update: (cache, { data }) => {
                var _a;
                if (data === null || data === void 0 ? void 0 : data.setMaxCompletionTimeHours) {
                    updateScheduleInCache(cache, data.setMaxCompletionTimeHours.id, ((_a = data.setMaxCompletionTimeHours) === null || _a === void 0 ? void 0 : _a.maxCompletionTimeHours) || null);
                }
            },
        });
    });
    const updateScheduleInCache = (cache, id, maxCompletionTimeHours) => {
        const scheduleToUpdate = {
            id,
            __typename: "ProjectScheduleEntity",
        };
        cache.modify({
            id: cache.identify(scheduleToUpdate),
            fields: {
                maxCompletionTimeHours(prev) {
                    return maxCompletionTimeHours || prev;
                },
            },
        });
    };
    return { setProjectScheduleMaxCompletionHours, loading, called };
};
exports.useSetProjectScheduleMaxCompletionHours = useSetProjectScheduleMaxCompletionHours;
