"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapLessonEntityToLesson = void 0;
const graphql_1 = require("../../types/graphql");
const mapLessonEntityToLesson = ({ lesson, progress, }) => {
    var _a;
    return {
        id: lesson.id,
        state: lesson.state,
        name: lesson.lessonTemplate.name,
        theme: lesson.lessonTemplate.theme,
        projectId: lesson.projectId,
        isVisibleOnBoard: lesson.isVisibleOnBoard,
        orderIndex: lesson.lessonTemplate.orderIndex || 0,
        lessonTemplateId: lesson.lessonTemplate.id,
        lessonScheduleId: lesson.lessonScheduleId || "",
        scheduleState: ((_a = lesson.lessonSchedule) === null || _a === void 0 ? void 0 : _a.state) || graphql_1.SchedulingState.HIDDEN,
        completedTasks: progress.completedTasksAmount,
        scheduledTasks: progress.scheduledTasksAmount,
    };
};
exports.mapLessonEntityToLesson = mapLessonEntityToLesson;
