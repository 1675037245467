"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGenerateTeamApiKey = void 0;
const client_1 = require("@apollo/client");
const generateTeamApiKey_1 = require("../mutations/generateTeamApiKey");
const useGenerateTeamApiKey = () => {
    const [generateTeamApiKey, { data, loading, error }] = (0, client_1.useMutation)(generateTeamApiKey_1.GENERATE_TEAM_API_KEY, {
        update: (cache, { data }) => {
            if (data === null || data === void 0 ? void 0 : data.generateTeamApiKey) {
                cache.modify({
                    id: cache.identify(data.generateTeamApiKey),
                    fields: {
                        apiKey: () => data.generateTeamApiKey.apiKey,
                    },
                });
            }
        },
    });
    return { generateTeamApiKey, data, loading, error };
};
exports.useGenerateTeamApiKey = useGenerateTeamApiKey;
