"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_GROUP_PAGE = void 0;
const client_1 = require("@apollo/client");
exports.FIND_GROUP_PAGE = (0, client_1.gql) `
  query FindGroupPage($groupId: String!) {
    findGroupPage(groupId: $groupId) {
      id
      groupId
      state
    }
  }
`;
