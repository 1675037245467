"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUEST_INSTANT_CODE_REVIEW = void 0;
const client_1 = require("@apollo/client");
exports.REQUEST_INSTANT_CODE_REVIEW = (0, client_1.gql) `
  mutation RequestInstantCodeReview($taskId: String!) {
    requestInstantCodeReview(taskId: $taskId) {
      id
      reviewState
      reviewRequests
    }
  }
`;
