"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateTag = void 0;
const client_1 = require("@apollo/client");
const createTag_1 = require("../mutations/createTag");
const useCreateTag = () => {
    const [createNewTag, { loading, called }] = (0, client_1.useMutation)(createTag_1.CREATE_TAG);
    const createTag = (input) => __awaiter(void 0, void 0, void 0, function* () {
        return yield createNewTag({
            variables: {
                input,
            },
            update: (cache, { data }) => {
                if (data) {
                    cache.modify({
                        fields: {
                            findGroupTags(existingTags = []) {
                                const newTagRef = cache.writeFragment({
                                    data: data.createTag,
                                    fragment: (0, client_1.gql) `
                    fragment NewTag on TagEntity {
                      id
                      name
                      value
                    }
                  `,
                                });
                                return [...existingTags, newTagRef];
                            },
                        },
                    });
                }
            },
        });
    });
    return { createTag, loading, called };
};
exports.useCreateTag = useCreateTag;
