"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_TASK_PULLS = void 0;
const client_1 = require("@apollo/client");
exports.GET_TASK_PULLS = (0, client_1.gql) `
  query GetTaskPulls($projectId: String!) {
    pulls(projectId: $projectId) {
      title
      number
      state
      head {
        ref
      }
    }
  }
`;
