"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateNewSubscription = void 0;
const client_1 = require("@apollo/client");
const createNewSubscriptionForOrg_1 = require("../mutations/createNewSubscriptionForOrg");
const useCreateNewSubscription = () => {
    const [tryCreateSub, { error, loading }] = (0, client_1.useMutation)(createNewSubscriptionForOrg_1.CREATE_NEW_SUBSCRIPTION_FOR_ORG);
    const createSubscriptionForOrg = (orgId, { invoiceDetails, paymentDetails }) => __awaiter(void 0, void 0, void 0, function* () {
        const { data, errors } = yield tryCreateSub({
            variables: {
                orgId,
                invoiceDetails,
                paymentDetails,
            },
        });
        return {
            data,
            errors,
        };
    });
    return {
        createSubscriptionForOrg,
        loading,
        error,
    };
};
exports.useCreateNewSubscription = useCreateNewSubscription;
