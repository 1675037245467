"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_PROJECT_VISIBILITY_IN_PORTFOLIO = void 0;
const core_1 = require("@apollo/client/core");
exports.UPDATE_PROJECT_VISIBILITY_IN_PORTFOLIO = (0, core_1.gql) `
  mutation UpdateProjectVisibilityInPortfolio(
    $projectId: String!
    $showInPortfolio: Boolean!
  ) {
    updateProjectVisibilityInPortfolio(
      projectId: $projectId
      showInPortfolio: $showInPortfolio
    ) {
      id
      showInPortfolio
    }
  }
`;
