"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TASK_REVIEW_REQUESTED_IN_GROUP = void 0;
const client_1 = require("@apollo/client");
exports.TASK_REVIEW_REQUESTED_IN_GROUP = (0, client_1.gql) `
  subscription TaskReviewRequestedInGroup($groupId: String!) {
    reviewRequestedInGroup(groupId: $groupId) {
      id
      reviewState
      reviewRequestedAt
      taskId
      task {
        id
        user {
          avatarUrl
          email
        }
        taskSchedule {
          assignee {
            avatarUrl
            email
          }
        }
        taskTemplate {
          title
          lessonTemplate {
            name
            theme
          }
        }
      }
    }
  }
`;
