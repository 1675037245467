"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_APP_REPOS = void 0;
const client_1 = require("@apollo/client");
exports.GET_APP_REPOS = (0, client_1.gql) `
  query GetAppRepos {
    githubAppRepositories {
      name
      is_template
    }
  }
`;
