"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDocumentSubscription = void 0;
const client_1 = require("@apollo/client");
const react_1 = require("react");
const documentUpdated_1 = require("../subscriptions/documentUpdated");
const useDocumentSubscription = (documentId, onDocumentUpdated) => {
    const { data: documentData } = (0, client_1.useSubscription)(documentUpdated_1.DOCUMENT_UPDATED, {
        variables: { documentId },
    });
    (0, react_1.useEffect)(() => {
        if (documentData === null || documentData === void 0 ? void 0 : documentData.documentUpdated) {
            onDocumentUpdated(documentData.documentUpdated);
        }
    }, [documentData]);
};
exports.useDocumentSubscription = useDocumentSubscription;
exports.default = exports.useDocumentSubscription;
