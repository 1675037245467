"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_GROUP_PAGE = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_GROUP_PAGE = (0, client_1.gql) `
  mutation InitGroupPage($groupId: String!) {
    initGroupPage(groupId: $groupId) {
      id
      slug
    }
  }
`;
