"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_ORG_GROUPS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_ORG_GROUPS = (0, client_1.gql) `
  query FindMyOrgGroups {
    findMyOrgGroups {
      id
      name
      state
      mentor {
        id
        username
        avatarUrl
      }
      accesses {
        id
      }
      settings {
        id
        isPrimaryGroup
      }
    }
  }
`;
