"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRevokeTeamApiKey = void 0;
const client_1 = require("@apollo/client");
const revokeTeamApiKey_1 = require("../mutations/revokeTeamApiKey");
const useRevokeTeamApiKey = () => {
    const [revokeTeamApiKey, { data, loading, error }] = (0, client_1.useMutation)(revokeTeamApiKey_1.REVOKE_TEAM_API_KEY, {
        update: (cache, { data }) => {
            if (data === null || data === void 0 ? void 0 : data.revokeTeamApiKey) {
                cache.modify({
                    id: cache.identify(data.revokeTeamApiKey),
                    fields: {
                        apiKey: () => null,
                    },
                });
            }
        },
    });
    return { revokeTeamApiKey, data, loading, error };
};
exports.useRevokeTeamApiKey = useRevokeTeamApiKey;
