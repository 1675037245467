"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyPrograms = void 0;
const client_1 = require("@apollo/client");
const findMyPrograms_1 = require("../queries/findMyPrograms");
const useFindMyPrograms = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findMyPrograms_1.FIND_MY_PROGRAMS, {
        fetchPolicy: "network-only",
    });
    return {
        programsWithProgress: loading ? [] : (data === null || data === void 0 ? void 0 : data.findMyPrograms) || [],
        error,
        loading,
        refetch,
    };
};
exports.useFindMyPrograms = useFindMyPrograms;
