"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_ACTIVE_BOARD_PROGRAM = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_ACTIVE_BOARD_PROGRAM = (0, client_1.gql) `
  query FindMyBoardActiveProgram {
    findMyActiveBoardProgram {
      id
      projectSchedules {
        id
        state
        collaborationType
        projects {
          id
        }
        projectTemplate {
          id
          name
          orderIndex
          subtitle
          theme
          type
          briefId
          tags {
            id
            name
          }
        }
        lessonSchedules {
          id
          lessonTemplate {
            id
            orderIndex
            name
            briefId
          }
        }
      }
    }
  }
`;
