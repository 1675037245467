"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NOTIFICATION_CREATED = void 0;
const client_1 = require("@apollo/client");
exports.NOTIFICATION_CREATED = (0, client_1.gql) `
  subscription OnNotificationSubscription($userId: String!) {
    notificationCreated(userId: $userId) {
      id
      message
      isRead
      variables
    }
  }
`;
