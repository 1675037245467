"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_GROUP_AS_PRIMARY = void 0;
const client_1 = require("@apollo/client");
exports.SET_GROUP_AS_PRIMARY = (0, client_1.gql) `
  mutation SetGroupAsPrimary($groupId: String!, $primary: Boolean!) {
    setGroupAsPrimary(groupId: $groupId, primary: $primary) {
      id
      isPrimaryGroup
    }
  }
`;
