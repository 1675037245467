"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_PROGRAMS = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_MY_PROGRAMS = (0, core_1.gql) `
  query FindMyPrograms {
    findMyPrograms {
      program {
        id
        groupId
        group {
          id
          name
        }
      }
      progress {
        programProgressInPercent
      }
    }
  }
`;
