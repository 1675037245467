"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PUBLIC_PROJECTS_TEMPLATES = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_PUBLIC_PROJECTS_TEMPLATES = (0, core_1.gql) `
  query FindPublicProjectsTemplates {
    findPublicProjectTemplates {
      id
      name
      type
      state
      theme
      isPublished
      organisation {
        name
        avatarSrc
      }
      lessonTemplates {
        name
        theme
        isPublished
        id
        _count {
          taskTemplates
        }
      }
    }
  }
`;
