"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyOrgGroups = void 0;
const client_1 = require("@apollo/client");
const findMyOrgGroups_1 = require("../queries/findMyOrgGroups");
const useFindMyOrgGroups = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findMyOrgGroups_1.FIND_MY_ORG_GROUPS, {
        fetchPolicy: "cache-and-network",
    });
    return {
        groups: loading ? [] : (data === null || data === void 0 ? void 0 : data.findMyOrgGroups) || [],
        error,
        loading,
        refetch,
    };
};
exports.useFindMyOrgGroups = useFindMyOrgGroups;
