"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateNewTask = void 0;
const client_1 = require("@apollo/client");
const createNewTask_1 = require("../mutations/createNewTask");
const useCreateNewTask = () => {
    const [create, { error, loading }] = (0, client_1.useMutation)(createNewTask_1.CREATE_NEW_TASK);
    const createNewTask = (lessonId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const response = yield create({
            variables: {
                lessonId,
            },
        });
        const createdTeamId = (_a = response.data) === null || _a === void 0 ? void 0 : _a.createNewTask.id;
        return createdTeamId || null;
    });
    return {
        createNewTask,
        loading,
        error,
    };
};
exports.useCreateNewTask = useCreateNewTask;
