"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_MAX_COMPLETION_TIME = void 0;
const client_1 = require("@apollo/client");
exports.SET_MAX_COMPLETION_TIME = (0, client_1.gql) `
  mutation SetMaxCompletionTimeHours(
    $id: String!
    $maxCompletionTimeHours: Int
  ) {
    setMaxCompletionTimeHours(
      id: $id
      maxCompletionTimeHours: $maxCompletionTimeHours
    ) {
      id
      isPublic
      maxCompletionTimeHours
    }
  }
`;
