"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyUpcomingInvoice = void 0;
const client_1 = require("@apollo/client");
const mapInvoiceEntity_1 = require("../mappers/mapInvoiceEntity");
const findMyUpcomingInvoice_1 = require("../queries/findMyUpcomingInvoice");
const initialInvoiceValues = (0, mapInvoiceEntity_1.mapInvoiceEntity)();
const useFindMyUpcomingInvoice = () => {
    const { data, loading } = (0, client_1.useQuery)(findMyUpcomingInvoice_1.FIND_MY_UPCOMING_INVOICE, { fetchPolicy: "network-only" });
    return {
        invoice: loading
            ? initialInvoiceValues
            : ((data === null || data === void 0 ? void 0 : data.findMyUpcomingInvoice) &&
                (0, mapInvoiceEntity_1.mapInvoiceEntity)(data.findMyUpcomingInvoice)) ||
                initialInvoiceValues,
        loading,
    };
};
exports.useFindMyUpcomingInvoice = useFindMyUpcomingInvoice;
