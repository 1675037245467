"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetProjectSchedulePublic = void 0;
const client_1 = require("@apollo/client");
const setProjectSchedulePublic_1 = require("../mutations/setProjectSchedulePublic");
const useSetProjectSchedulePublic = () => {
    const [setState, { loading, called }] = (0, client_1.useMutation)(setProjectSchedulePublic_1.SET_PROJECT_SCHEDULE_PUBLIC);
    const setProjectSchedulePublic = (id, isPublic) => __awaiter(void 0, void 0, void 0, function* () {
        return yield setState({
            variables: {
                id,
                isPublic,
            },
            update: (cache, { data }) => {
                if (data === null || data === void 0 ? void 0 : data.setProjectSchedulePublic) {
                    updateScheduleInCache(cache, data.setProjectSchedulePublic.id, data.setProjectSchedulePublic.isPublic);
                }
            },
        });
    });
    const updateScheduleInCache = (cache, id, isPublic) => {
        const scheduleToUpdate = {
            id,
            __typename: "ProjectScheduleEntity",
        };
        cache.modify({
            id: cache.identify(scheduleToUpdate),
            fields: {
                isPublic(prev) {
                    return isPublic || prev;
                },
            },
        });
    };
    return { setProjectSchedulePublic, loading, called };
};
exports.useSetProjectSchedulePublic = useSetProjectSchedulePublic;
