"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyGroup = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findMyGroup_1 = require("../queries/findMyGroup");
const useFindMyGroup = (id) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findMyGroup_1.FIND_MY_GROUP, {
        variables: { id },
        fetchPolicy: "cache-first",
    });
    return {
        group: loading ? constants_1.initialGroup : (data === null || data === void 0 ? void 0 : data.findMyGroup) || constants_1.initialGroup,
        error,
        loading,
        refetch,
    };
};
exports.useFindMyGroup = useFindMyGroup;
