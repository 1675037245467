"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNPUBLISH_PAGE = void 0;
const client_1 = require("@apollo/client");
exports.UNPUBLISH_PAGE = (0, client_1.gql) `
  mutation UnpublishPage($pageId: String!) {
    unpublishPage(pageId: $pageId) {
      id
      slug
      shortDsc
      state
      group {
        id
        name
        currency
        pricePerSeat
        maxSeats
        state
      }
    }
  }
`;
