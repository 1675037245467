"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_TASK_SCHEDULE_WITH_TASKS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_TASK_SCHEDULE_WITH_TASKS = (0, client_1.gql) `
  query FindTaskScheduleWithTasks($id: String!) {
    findTaskScheduleWithTasks(id: $id) {
      id
      taskTemplateId
      taskTemplate {
        orderIndex
        briefId
        title
        type
        tags {
          name
        }
        reviewCondition {
          isOn
          isRequired
        }
        lessonTemplate {
          name
          theme
        }
      }
      tasks {
        id
        state
        orderIndex
        user {
          avatarUrl
          username
          email
        }
        taskReview {
          reviewState
        }
      }
    }
  }
`;
