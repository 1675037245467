"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindGroupCandidates = void 0;
const client_1 = require("@apollo/client");
const findGroupCandidates_1 = require("../query/findGroupCandidates");
const useFindGroupCandidates = (groupId) => {
    const { data, loading } = (0, client_1.useQuery)(findGroupCandidates_1.FIND_GROUP_CANDIDATES, {
        variables: {
            groupId,
        },
        nextFetchPolicy: "cache-first",
    });
    return {
        candidates: loading ? [] : (data === null || data === void 0 ? void 0 : data.findGroupCandidates) || [],
        loading,
    };
};
exports.useFindGroupCandidates = useFindGroupCandidates;
