"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTaskTags = exports.useFindProjectTags = exports.useFindGroupTags = exports.useDeleteTag = exports.useCreateTag = void 0;
var useCreateTag_1 = require("./useCreateTag");
Object.defineProperty(exports, "useCreateTag", { enumerable: true, get: function () { return useCreateTag_1.useCreateTag; } });
var useDeleteTag_1 = require("./useDeleteTag");
Object.defineProperty(exports, "useDeleteTag", { enumerable: true, get: function () { return useDeleteTag_1.useDeleteTag; } });
var useFindGroupTags_1 = require("./useFindGroupTags");
Object.defineProperty(exports, "useFindGroupTags", { enumerable: true, get: function () { return useFindGroupTags_1.useFindGroupTags; } });
var useFindProjectTags_1 = require("./useFindProjectTags");
Object.defineProperty(exports, "useFindProjectTags", { enumerable: true, get: function () { return useFindProjectTags_1.useFindProjectTags; } });
var useFindTaskTags_1 = require("./useFindTaskTags");
Object.defineProperty(exports, "useFindTaskTags", { enumerable: true, get: function () { return useFindTaskTags_1.useFindTaskTags; } });
