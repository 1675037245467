"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindProjectsTemplates = void 0;
const client_1 = require("@apollo/client");
const projectTemplateQueryMappers_1 = require("../mappers/projectTemplateQueryMappers");
const findProjectTemplates_1 = require("../queries/findProjectTemplates");
const useFindProjectsTemplates = () => {
    const { data, loading, refetch } = (0, client_1.useQuery)(findProjectTemplates_1.FIND_MY_PROJECTS_TEMPLATES, {
        fetchPolicy: "cache-first",
    });
    return {
        projectsTemplates: loading
            ? []
            : ((data === null || data === void 0 ? void 0 : data.findProjectTemplates) &&
                data.findProjectTemplates.map(projectTemplateQueryMappers_1.mapProjectTemplateToOwned)) ||
                [],
        loading,
        refetch,
    };
};
exports.useFindProjectsTemplates = useFindProjectsTemplates;
