"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_GROUP_PROGRAM = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_GROUP_PROGRAM = (0, core_1.gql) `
  query FindGroupProgram($groupId: String!) {
    findGroupProgram(groupId: $groupId) {
      id
      projectSchedules {
        id
        state
        isPublic
        maxCompletionTimeHours
        collaborationType
        projectTemplateId
        collaborators {
          id
          avatarUrl
          username
        }
        projects {
          id
          repositoryName
          teamId
        }
        projectTemplate {
          id
          name
          type
        }
        lessonSchedules {
          id
          state
          lessons {
            id
            state
            projectId
            isVisibleOnBoard
          }
          lessonTemplate {
            id
            name
          }
          taskSchedules {
            id
            state
            taskTemplate {
              id
              title
            }
          }
        }
      }
    }
  }
`;
