"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_PROJECT_SCHEDULE_PUBLIC = void 0;
const client_1 = require("@apollo/client");
exports.SET_PROJECT_SCHEDULE_PUBLIC = (0, client_1.gql) `
  mutation SetProjectSchedulePublic($id: String!, $isPublic: Boolean!) {
    setProjectSchedulePublic(id: $id, isPublic: $isPublic) {
      id
      isPublic
      maxCompletionTimeHours
    }
  }
`;
