"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_TASK_CODE_SOLUTION = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_TASK_CODE_SOLUTION = (0, client_1.gql) `
  mutation UpdateTaskCodeSolution($taskId: String!, $code: String!) {
    updateTaskCodeSolution(taskId: $taskId, code: $code) {
      id
      code
    }
  }
`;
