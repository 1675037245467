"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MONITOR_CONTENT_AMOUNT = void 0;
const client_1 = require("@apollo/client");
exports.MONITOR_CONTENT_AMOUNT = (0, client_1.gql) `
  query MonitorContentAmount {
    monitorContentAmount {
      projects
      groups
      lessons
      tasks
    }
  }
`;
