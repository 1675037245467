"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUEST_CODE_REVIEW = void 0;
const client_1 = require("@apollo/client");
exports.REQUEST_CODE_REVIEW = (0, client_1.gql) `
  mutation RequestCodeReview($taskId: String!) {
    requestCodeReview(taskId: $taskId) {
      id
      reviewState
    }
  }
`;
