"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPGRADE_MY_SUBSCRIPTION = void 0;
const client_1 = require("@apollo/client");
exports.UPGRADE_MY_SUBSCRIPTION = (0, client_1.gql) `
  mutation UpgradeMySubscription($seats: Int!) {
    upgradeMySubscription(seats: $seats) {
      id
      plan
      seats
      isOnExpirationPeriod
    }
  }
`;
