"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PUBLIC_GROUP_PROGRAM = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_PUBLIC_GROUP_PROGRAM = (0, core_1.gql) `
  query FindPublicProgram($groupId: String!) {
    findPublicProgram(groupId: $groupId) {
      id
      projectSchedules {
        id
        isPublic
        state
        lessonSchedules {
          id
        }
        projectTemplate {
          id
          name
          type
          eta
          subtitle
          livePreviewUrl
          difficultyLevel
          evaluations {
            id
            rating
            comment
          }
          tags {
            name
          }
        }
      }
    }
  }
`;
