"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindProjectTemplatesLibrary = void 0;
const client_1 = require("@apollo/client");
const findProjectTemplatesLibrary_1 = require("../queries/findProjectTemplatesLibrary");
const useFindProjectTemplatesLibrary = () => {
    const { data, loading, refetch } = (0, client_1.useQuery)(findProjectTemplatesLibrary_1.FIND_PROJECT_TEMPLATES_LIBRARY, {
        fetchPolicy: "cache-and-network", // Used for first execution
    });
    return {
        projectTemplates: loading ? [] : (data === null || data === void 0 ? void 0 : data.findProjectTemplatesLibrary) || [],
        loading,
        refetch,
    };
};
exports.useFindProjectTemplatesLibrary = useFindProjectTemplatesLibrary;
