"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteComment = void 0;
const client_1 = require("@apollo/client");
const deleteComment_1 = require("../mutations/deleteComment");
const useDeleteComment = () => {
    const [deleteOne, { loading, called }] = (0, client_1.useMutation)(deleteComment_1.DELETE_COMMENT);
    const deleteComment = (commentId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield deleteOne({
            variables: {
                commentId,
            },
            update: (cache, { data }) => {
                if (!data)
                    return;
                const { deleteComment } = data;
                cache.modify({
                    fields: {
                        findTaskComments(existingComments = [], { readField }) {
                            return existingComments.filter((commentRef) => deleteComment.id !== readField("id", commentRef));
                        },
                    },
                });
            },
        });
    });
    return { deleteComment, loading, called };
};
exports.useDeleteComment = useDeleteComment;
