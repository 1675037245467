"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PUSH_MOCK_CANDIDATE_TO_WEBHOOK = void 0;
const core_1 = require("@apollo/client/core");
exports.PUSH_MOCK_CANDIDATE_TO_WEBHOOK = (0, core_1.gql) `
  mutation PushMockCandidateToWebhook($webhookUrl: String!) {
    pushMockCandidateToWebhook(webhookUrl: $webhookUrl) {
      id
      fullName
      email
      program
      githubUrl
      linkedInUrl
      phone
      languages
      technologies
      createdAt
    }
  }
`;
