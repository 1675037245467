"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpgradeSubscription = void 0;
const client_1 = require("@apollo/client");
const upgradeMySubscription_1 = require("../mutations/upgradeMySubscription");
const useUpgradeSubscription = () => {
    const [upgrade, { error, loading, client: { cache }, },] = (0, client_1.useMutation)(upgradeMySubscription_1.UPGRADE_MY_SUBSCRIPTION);
    const upgradeSubscription = (seats) => __awaiter(void 0, void 0, void 0, function* () {
        const { data, errors } = yield upgrade({
            variables: { seats },
            update: (_cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.upgradeMySubscription))
                    return;
                updateSubscriptionInCache(data.upgradeMySubscription.id, data.upgradeMySubscription.seats);
            },
        });
        return {
            data,
            errors,
        };
    });
    const updateSubscriptionInCache = (subscriptionId, seats) => {
        const subscriptionToUpdate = {
            id: subscriptionId,
            __typename: "SubscriptionEntity",
        };
        cache.modify({
            id: cache.identify(subscriptionToUpdate),
            fields: {
                seats(prev) {
                    return seats || prev;
                },
            },
        });
    };
    return {
        upgradeSubscription,
        loading,
        error,
    };
};
exports.useUpgradeSubscription = useUpgradeSubscription;
