"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAKE_PUBLIC_PROJECT_TEMPLATE = void 0;
const client_1 = require("@apollo/client");
exports.MAKE_PUBLIC_PROJECT_TEMPLATE = (0, client_1.gql) `
  mutation MakePublicProjectTemplate(
    $projectTemplateId: String!
    $viewOnMarketplace: Boolean!
  ) {
    makePublicProjectTemplate(
      projectTemplateId: $projectTemplateId
      viewOnMarketplace: $viewOnMarketplace
    ) {
      id
      viewOnMarketplace
    }
  }
`;
