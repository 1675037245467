"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_TEAM_MEMBER = void 0;
const client_1 = require("@apollo/client");
exports.ADD_TEAM_MEMBER = (0, client_1.gql) `
  mutation AddTeamMember($teamId: String!, $userId: String!) {
    addTeamMember(teamId: $teamId, userId: $userId) {
      id
      userId
    }
  }
`;
