"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESET_GITHUB_TASK_DETAILS = void 0;
const client_1 = require("@apollo/client");
exports.RESET_GITHUB_TASK_DETAILS = (0, client_1.gql) `
  mutation ResetGithubTaskDetails($taskId: String!) {
    resetGithubTaskDetails(taskId: $taskId) {
      id
    }
  }
`;
