"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_GROUP = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_GROUP = (0, client_1.gql) `
  query FindMyGroup($id: String!) {
    findMyGroup(id: $id) {
      id
      name
      state
      paymentMode
      pricePerSeat
      currency
      page {
        id
        slug
        shortDsc
      }
      program {
        id
      }
      mentor {
        id
        email
        username
        avatarUrl
      }
      settings {
        id
        viewInMarketplace
      }
    }
  }
`;
