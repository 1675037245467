"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPublicPrimaryGroup = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findPublicPrimaryGroup_1 = require("../queries/findPublicPrimaryGroup");
const useFindPublicPrimaryGroup = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findPublicPrimaryGroup_1.FIND_PUBLIC_PRIMARY_GROUP);
    return {
        group: loading
            ? constants_1.initialGroup
            : (data === null || data === void 0 ? void 0 : data.findPublicPrimaryGroup) || constants_1.initialGroup,
        error,
        loading,
        refetch,
    };
};
exports.useFindPublicPrimaryGroup = useFindPublicPrimaryGroup;
