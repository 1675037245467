"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REMOVE_PROJECT_TEMPLATES_FROM_PROGRAM = void 0;
const client_1 = require("@apollo/client");
exports.REMOVE_PROJECT_TEMPLATES_FROM_PROGRAM = (0, client_1.gql) `
  mutation RemoveProjectSchedulesFromProgram(
    $programId: String!
    $projectTemplateIds: [String!]!
  ) {
    removeProjectSchedulesFromProgram(
      programId: $programId
      projectTemplateIds: $projectTemplateIds
    ) {
      id
      projectSchedules {
        id
        state
        projectTemplate {
          id
          name
          type
        }
        lessonSchedules {
          id
          state
          lessonTemplate {
            id
            name
          }
          taskSchedules {
            id
            state
            taskTemplate {
              id
              title
            }
          }
        }
      }
    }
  }
`;
