"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyOrgTeamMembers = void 0;
const client_1 = require("@apollo/client");
const findMyOrgTeamMembers_1 = require("../queries/findMyOrgTeamMembers");
const useFindMyOrgTeamMembers = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findMyOrgTeamMembers_1.FIND_MY_ORGANISATION_TEAM_MEMBERS);
    return {
        members: loading ? [] : (data === null || data === void 0 ? void 0 : data.findMyOrgTeamMembers.members) || [],
        error,
        loading,
        refetch,
    };
};
exports.useFindMyOrgTeamMembers = useFindMyOrgTeamMembers;
