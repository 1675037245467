"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_AI_CONDITIONS = void 0;
const core_1 = require("@apollo/client/core");
exports.UPDATE_AI_CONDITIONS = (0, core_1.gql) `
  mutation UpdateAiConditions($id: String!, $conditions: JSONObject!) {
    updateAiConditions(id: $id, conditions: $conditions) {
      id
      goal
      context
      rules
      maxReviewRetries
      model
      taskTemplateId
    }
  }
`;
