"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESTART_MY_TASK = void 0;
const client_1 = require("@apollo/client");
exports.RESTART_MY_TASK = (0, client_1.gql) `
  mutation RestartMyTask($taskId: String!) {
    restartMyTask(taskId: $taskId) {
      id
      state
      orderIndex
    }
  }
`;
