"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_INDIVIDUAL_PROJECT_SCHEDULES = void 0;
const client_1 = require("@apollo/client");
exports.FIND_INDIVIDUAL_PROJECT_SCHEDULES = (0, client_1.gql) `
  query FindAvailableProjectsForUser {
    findAvailableProjectsForUser {
      id
      state
      isPublic
      maxCompletionTimeHours
      program {
        id
        groupId
        group {
          id
          name
          state
        }
      }
      projectTemplate {
        id
        name
        eta
        subtitle
        difficultyLevel
        orderIndex
        theme
        type
        briefId
        tags {
          name
        }
      }
    }
  }
`;
