"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PROJECT_TEMPLATE = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_PROJECT_TEMPLATE = (0, core_1.gql) `
  query FindOwnedProjectTemplate($projectTemplateId: String!) {
    findOwnedProjectTemplate(id: $projectTemplateId) {
      id
      name
      type
      eta
      subtitle
      difficultyLevel
      githubRepoTemplateName
      githubRepoDistribution
      livePreviewUrl
      isPublished
      orderIndex
      theme
      isExpGainOn
      viewOnMarketplace
      briefId
      lessonTemplates {
        id
      }
    }
  }
`;
