"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_GITHUB_TASK_DETAILS = void 0;
const core_1 = require("@apollo/client/core");
exports.UPDATE_GITHUB_TASK_DETAILS = (0, core_1.gql) `
  mutation UpdateTaskPullNumber($taskId: String!, $data: JSONObject!) {
    updateGithubTaskDetails(
      input: { where: { taskId: $taskId }, data: $data }
    ) {
      id
    }
  }
`;
