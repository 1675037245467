"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateTeamName = void 0;
const client_1 = require("@apollo/client");
const updateTeamName_1 = require("../mutations/updateTeamName");
const useUpdateTeamName = () => {
    const [updateTeamName, { data, loading, error }] = (0, client_1.useMutation)(updateTeamName_1.UPDATE_TEAM_NAME);
    const updateName = (teamId, name) => __awaiter(void 0, void 0, void 0, function* () {
        yield updateTeamName({
            variables: {
                teamId,
                name,
            },
            update(cache, { data }) {
                cache.modify({
                    id: cache.identify({
                        _type: "TeamEntity",
                        _id: teamId,
                    }),
                    fields: {
                        name(prevName) {
                            return (data === null || data === void 0 ? void 0 : data.updateTeamName.name) || prevName;
                        },
                    },
                });
            },
        });
    });
    return { updateName, data, loading, error };
};
exports.useUpdateTeamName = useUpdateTeamName;
