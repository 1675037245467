"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTaskComments = void 0;
const client_1 = require("@apollo/client");
const react_1 = require("react");
const findTaskComments_1 = require("../queries/findTaskComments");
const taskCommentAdded_1 = require("../subscriptions/taskCommentAdded");
const useFindTaskComments = (taskId) => {
    const { data, error, loading, subscribeToMore, fetchMore } = (0, client_1.useQuery)(findTaskComments_1.FIND_TASK_COMMENTS, { variables: { taskId } });
    const [newCommentReceived, setNewCommentReceived] = (0, react_1.useState)(false);
    const subscribeToNotifications = () => {
        subscribeToMore({
            document: taskCommentAdded_1.TASK_COMMENT_ADDED,
            variables: { taskId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data)
                    return prev;
                const { taskCommentAdded } = subscriptionData.data;
                process.env.NEXT_PUBLIC_NERDY_USER_ID === taskCommentAdded.authorId &&
                    setNewCommentReceived(true);
                return {
                    findTaskComments: [
                        ...prev.findTaskComments,
                        Object.assign(Object.assign({}, taskCommentAdded), { createdAt: 
                            // TODO: I don't know but it's not returning createdAt somehow - will investigate that later
                            (taskCommentAdded === null || taskCommentAdded === void 0 ? void 0 : taskCommentAdded.createdAt) || new Date().toISOString() }),
                    ],
                };
            },
        });
    };
    const handleToggleCommentReceived = () => {
        setNewCommentReceived(false);
    };
    const comments = loading ? [] : (data === null || data === void 0 ? void 0 : data.findTaskComments) || [];
    return {
        comments,
        error,
        loading,
        subscribeToNotifications,
        handleToggleCommentReceived,
        newCommentReceived,
        fetchMore,
    };
};
exports.useFindTaskComments = useFindTaskComments;
