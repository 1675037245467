"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitNewTasksForLesson = void 0;
const client_1 = require("@apollo/client");
const initNewTasksForLesson_1 = require("../mutations/initNewTasksForLesson");
const useInitNewTasksForLesson = () => {
    const [init, { loading, called }] = (0, client_1.useMutation)(initNewTasksForLesson_1.INIT_TASKS_FOR_EXISTING_LESSON);
    const initTasks = (lessonId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield init({
            variables: {
                lessonId,
            },
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.initNewTasksForLesson))
                    return;
                updateLessonInCache(cache, data.initNewTasksForLesson.id, data.initNewTasksForLesson.state);
            },
        });
    });
    const updateLessonInCache = (cache, lessonId, state) => {
        const lessonToUpdate = {
            id: lessonId,
            __typename: "LessonEntity",
        };
        cache.modify({
            id: cache.identify(lessonToUpdate),
            fields: {
                state(prevState) {
                    return state || prevState;
                },
            },
        });
    };
    return { initTasks, loading, called };
};
exports.useInitNewTasksForLesson = useInitNewTasksForLesson;
