"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOIN_HACKATHON_TEAM = void 0;
const client_1 = require("@apollo/client");
exports.JOIN_HACKATHON_TEAM = (0, client_1.gql) `
  mutation JoinHackathonTeam(
    $groupId: String!
    $payload: HackathonTeamFormPayload!
  ) {
    joinHackathonTeam(groupId: $groupId, payload: $payload) {
      id
      email
    }
  }
`;
