"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SYNC_GROUP_ACCESS_PAYMENT_BY_CHECKOUT_ID = void 0;
const client_1 = require("@apollo/client");
exports.SYNC_GROUP_ACCESS_PAYMENT_BY_CHECKOUT_ID = (0, client_1.gql) `
  query SyncGroupAccessPaymentByCheckoutId($checkoutId: String!) {
    syncGroupAccessPaymentByCheckoutId(checkoutId: $checkoutId) {
      id
      status
      email
      userId
    }
  }
`;
