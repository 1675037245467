"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_INVOICES = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_INVOICES = (0, client_1.gql) `
  query FindMyInvoices {
    findMyInvoices {
      id
      amount_paid
      currency
      period_start
      paid
      hosted_invoice_url
      lines {
        data {
          quantity
        }
      }
      subscription {
        id
      }
    }
  }
`;
