"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_MY_ORG_NAME = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_MY_ORG_NAME = (0, client_1.gql) `
  mutation ($name: String!) {
    updateMyOrgName(name: $name) {
      id
      name
    }
  }
`;
