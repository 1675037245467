"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeGroupAccessEntity = exports.updateGroupAccessStatusInCache = exports.updateGroupAccessPlanInCache = void 0;
const findGroupAccessRequests_1 = require("./queries/findGroupAccessRequests");
const updateGroupAccessPlanInCache = (cache, groupAccessId, plan) => {
    const groupToUpdate = {
        id: groupAccessId,
        __typename: "GroupAccessRequest",
    };
    cache.modify({
        id: cache.identify(groupToUpdate),
        fields: {
            plan(prev) {
                return plan || prev;
            },
        },
    });
};
exports.updateGroupAccessPlanInCache = updateGroupAccessPlanInCache;
const updateGroupAccessStatusInCache = (cache, groupAccessId, status) => {
    const groupToUpdate = {
        id: groupAccessId,
        __typename: "GroupAccessRequest",
    };
    cache.modify({
        id: cache.identify(groupToUpdate),
        fields: {
            status(prev) {
                return status || prev;
            },
        },
    });
};
exports.updateGroupAccessStatusInCache = updateGroupAccessStatusInCache;
const removeGroupAccessEntity = (cache, groupId, accessId) => {
    const data = cache.readQuery({
        query: findGroupAccessRequests_1.FIND_GROUPS_ACCESS_REQUESTS,
        variables: {
            groupId,
        },
    });
    const updatedGroupAccesses = data.findGroupAccessRequests.filter((groupAccess) => groupAccess.id !== accessId);
    cache.writeQuery({
        query: findGroupAccessRequests_1.FIND_GROUPS_ACCESS_REQUESTS,
        variables: {
            groupId,
        },
        data: {
            findGroupAccessRequests: updatedGroupAccesses,
        },
    });
};
exports.removeGroupAccessEntity = removeGroupAccessEntity;
