"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCountMyOrgContent = void 0;
const client_1 = require("@apollo/client");
const countMyOrgContent_1 = require("../queries/countMyOrgContent");
const initialValues = {
    groups: 0,
    lessons: 0,
    projects: 0,
    tasks: 0,
};
const useCountMyOrgContent = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(countMyOrgContent_1.MONITOR_CONTENT_AMOUNT);
    return {
        content: loading
            ? initialValues
            : (data === null || data === void 0 ? void 0 : data.monitorContentAmount) || initialValues,
        error,
        loading,
        refetch,
    };
};
exports.useCountMyOrgContent = useCountMyOrgContent;
