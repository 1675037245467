"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetUserSkills = void 0;
const client_1 = require("@apollo/client");
const __1 = require("../..");
const getUserSkills_1 = require("../queries/getUserSkills");
/**
 * Hook to get user skills.
 * This hook uses the Apollo Client's useQuery hook to fetch user skills from the GraphQL server.
 *
 * @returns {Object} An object containing the following properties:
 * - userSkills: The user's skills. This is null if the data is still loading.
 * - loading: A boolean indicating whether the data is still loading.
 * - error: Any error that occurred while fetching the data.
 * - refetch: A function to refetch the data.
 */
const useGetUserSkills = () => {
    // Use Apollo Client's useQuery hook to fetch user skills from the GraphQL server.
    const { data, loading, error, refetch } = (0, client_1.useQuery)(getUserSkills_1.GET_USER_SKILLS);
    return {
        // If the data is still loading, userSkills is null.
        // Otherwise, userSkills is the fetched data, mapped to include public user data.
        userSkills: loading
            ? null
            : ((data === null || data === void 0 ? void 0 : data.getUserSkills) &&
                (0, __1.mapUserSkillsToUserSkillsWithPublicUserData)(data.getUserSkills)) ||
                null,
        // Whether the data is still loading.
        loading,
        // Any error that occurred while fetching the data.
        error,
        // A function to refetch the data.
        refetch,
    };
};
exports.useGetUserSkills = useGetUserSkills;
