"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyInvoices = void 0;
const client_1 = require("@apollo/client");
const mapInvoiceEntity_1 = require("../mappers/mapInvoiceEntity");
const findMyInvoices_1 = require("../queries/findMyInvoices");
const useFindMyInvoices = () => {
    const { data, loading } = (0, client_1.useQuery)(findMyInvoices_1.FIND_MY_INVOICES, {
        fetchPolicy: "network-only",
    });
    return {
        invoices: loading ? [] : (data === null || data === void 0 ? void 0 : data.findMyInvoices.map(mapInvoiceEntity_1.mapInvoiceEntity)) || [],
        loading,
    };
};
exports.useFindMyInvoices = useFindMyInvoices;
