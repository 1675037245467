"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGenerateLoginLink = void 0;
const client_1 = require("@apollo/client");
const generateLoginLink_1 = require("../mutations/generateLoginLink");
const useGenerateLoginLink = () => {
    const [generateLink, { loading }] = (0, client_1.useMutation)(generateLoginLink_1.GENERATE_LOGIN_LINK);
    const generateLoginLink = (email) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield generateLink({
            variables: {
                email,
            },
        });
        if (!(data === null || data === void 0 ? void 0 : data.generateLoginLink)) {
            throw new Error("Oops");
        }
        return data.generateLoginLink;
    });
    return { generateLoginLink, loading };
};
exports.useGenerateLoginLink = useGenerateLoginLink;
