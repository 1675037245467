"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_TASKS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_TASKS = (0, client_1.gql) `
  query FindMyTasks {
    findMyTasks {
      id
      state
      orderIndex
      lessonId
      taskScheduleId
      lesson {
        id
        tasks {
          id
        }
      }
      taskReview {
        id
        reviewState
      }
      user {
        id
        username
        avatarUrl
        email
      }
      taskTemplate {
        id
        title
        orderIndex
        briefId
        type
        lessonTemplate {
          id
          name
          theme
        }
      }
    }
  }
`;
