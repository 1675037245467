"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PROJECT_TEMPLATES_LIBRARY = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_PROJECT_TEMPLATES_LIBRARY = (0, core_1.gql) `
  query FindProjectTemplatesLibrary {
    findProjectTemplatesLibrary {
      id
      name
      type
      theme
      state
      isPublished
      lessonTemplates {
        id
        name
        isPublished
        taskTemplates {
          id
          title
          isPublished
        }
      }
    }
  }
`;
