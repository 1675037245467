"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialBoardValues = void 0;
const constants_1 = require("../program/constants");
exports.initialBoardValues = {
    userId: "",
    activeProgramId: "",
    activeProgram: constants_1.initialProgram,
    activeProjectId: null,
    activeProject: null,
    id: "",
};
