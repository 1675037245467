"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyOrganisation = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findMyOrganisation_1 = require("../queries/findMyOrganisation");
const useFindMyOrganisation = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findMyOrganisation_1.FIND_MY_ORGANISATION);
    return {
        organisation: loading ? constants_1.initialOrg : (data === null || data === void 0 ? void 0 : data.findMyOrganisation) || constants_1.initialOrg,
        error,
        loading,
        refetch,
    };
};
exports.useFindMyOrganisation = useFindMyOrganisation;
