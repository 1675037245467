"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPublicLessonTemplate = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findPublicLessonTemplate_1 = require("../queries/findPublicLessonTemplate");
const useFindPublicLessonTemplate = (lessonTemplateId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findPublicLessonTemplate_1.FIND_PUBLIC_LESSON_TEMPLATE, {
        variables: { id: lessonTemplateId },
        fetchPolicy: "cache-first",
    });
    return {
        lessonTemplate: loading
            ? constants_1.initialLessonTemplateValues
            : (data === null || data === void 0 ? void 0 : data.findPublicLessonTemplate) || constants_1.initialLessonTemplateValues,
        loading,
        refetch,
        error,
    };
};
exports.useFindPublicLessonTemplate = useFindPublicLessonTemplate;
