"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindGroupTaskReviews = void 0;
const client_1 = require("@apollo/client");
const findGroupTaskReviews_1 = require("../queries/findGroupTaskReviews");
const reviewRequestedInGroup_1 = require("../subscriptions/reviewRequestedInGroup");
const useFindGroupTaskReviews = (groupId, state) => {
    const { data, loading, subscribeToMore } = (0, client_1.useQuery)(findGroupTaskReviews_1.FIND_GROUP_TASK_REVIEWS, {
        fetchPolicy: "network-only",
        variables: { groupId, state },
    });
    const subscribeToNewReviews = (groupId) => {
        subscribeToMore({
            document: reviewRequestedInGroup_1.TASK_REVIEW_REQUESTED_IN_GROUP,
            variables: { groupId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data)
                    return prev;
                const { reviewRequestedInGroup } = subscriptionData.data;
                return {
                    findGroupTaskReviews: [
                        ...prev.findGroupTaskReviews,
                        reviewRequestedInGroup,
                    ],
                };
            },
        });
    };
    return {
        reviews: loading ? [] : (data === null || data === void 0 ? void 0 : data.findGroupTaskReviews) || [],
        loading,
        subscribeToNewReviews,
    };
};
exports.useFindGroupTaskReviews = useFindGroupTaskReviews;
