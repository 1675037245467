"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialProjectTemplate = void 0;
const constants_1 = require("../document/constants");
const graphql_1 = require("../types/graphql");
exports.initialProjectTemplate = {
    githubRepoDistribution: graphql_1.GithubRepoDistribution.TEMPLATE,
    type: "",
    brief: constants_1.initialDocumentValues,
    briefId: "",
    tags: [],
    authorId: "",
    isExpGainOn: false,
    githubRepoTemplateName: "",
    organisationId: "",
    isPublished: false,
    evaluations: [],
    livePreviewUrl: "",
    viewOnMarketplace: false,
    state: "",
    difficultyLevel: "",
    eta: 0,
    createdAt: "",
    orderIndex: 0,
    lessonTemplates: [],
    name: "",
    theme: "",
    id: "",
};
