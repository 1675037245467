"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_GROUP_UPSELLS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_GROUP_UPSELLS = (0, client_1.gql) `
  query FindGroupUpsells($groupId: String!) {
    findGroupUpsells(groupId: $groupId) {
      id
      highlighted
      groupId
      group {
        name
        pricePerSeat
        currency
        paymentMode
        tags {
          name
        }
        page {
          slug
          shortDsc
          coverImage {
            url
          }
        }
        settings {
          availabilityEndDate
        }
      }
    }
  }
`;
