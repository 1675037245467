"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialLessonSchedule = void 0;
const constants_1 = require("../lesson-template/constants");
const graphql_1 = require("../types/graphql");
exports.initialLessonSchedule = {
    id: "",
    lessonTemplate: constants_1.initialLessonTemplateValues,
    lessonTemplateId: "",
    projectScheduleId: "",
    state: graphql_1.SchedulingState.HIDDEN,
    taskSchedules: [],
    lessons: [],
};
