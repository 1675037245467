"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEACTIVATE_GROUP = void 0;
const client_1 = require("@apollo/client");
exports.DEACTIVATE_GROUP = (0, client_1.gql) `
  mutation DeactivateGroup($id: String!) {
    deactivateGroup(id: $id) {
      id
      state
    }
  }
`;
