"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_MAILING_SUBSCRIBER = void 0;
const core_1 = require("@apollo/client/core");
exports.CREATE_MAILING_SUBSCRIBER = (0, core_1.gql) `
  mutation CreateMailingSubscriber($email: String!, $termsAgreements: String!) {
    createSubscriber(
      input: { data: { email: $email, termsAgreements: $termsAgreements } }
    ) {
      id
    }
  }
`;
