"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_HACKAPOINTS_FOR_TEAM = void 0;
const core_1 = require("@apollo/client/core");
exports.ADD_HACKAPOINTS_FOR_TEAM = (0, core_1.gql) `
  mutation AddHackapointsForTeam($teamId: String!, $hackapoints: Int!) {
    addHackapointsForTeam(teamId: $teamId, hackapoints: $hackapoints) {
      id
      hackapoints
    }
  }
`;
