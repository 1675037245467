"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PUBLIC_GROUPS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_PUBLIC_GROUPS = (0, client_1.gql) `
  query FindPublicGroups {
    findPublicGroups {
      id
      name
      state
      currency
      pricePerSeat
      paymentMode
      settings {
        id
        availabilityEndDate
        startAt
        endAt
      }
      tags {
        id
        name
      }
      page {
        coverImage {
          url
        }
        showPrice
        shortDsc
        slug
      }
    }
  }
`;
