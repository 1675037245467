"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindOwnedLessonTaskTemplates = void 0;
const client_1 = require("@apollo/client");
const findOwnedLessonTaskTemplates_1 = require("../queries/findOwnedLessonTaskTemplates");
const useFindOwnedLessonTaskTemplates = (lessonTemplateId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findOwnedLessonTaskTemplates_1.FIND_OWNED_LESSON_TASK_TEMPLATES, {
        variables: {
            lessonTemplateId,
        },
    });
    return {
        taskTemplates: loading ? [] : (data === null || data === void 0 ? void 0 : data.findOwnedLessonTaskTemplates) || [],
        loading,
        refetch,
        error,
    };
};
exports.useFindOwnedLessonTaskTemplates = useFindOwnedLessonTaskTemplates;
