"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialTextSolution = exports.initialCodeSolution = exports.initialTaskEntity = exports.initialTaskReview = void 0;
const constants_1 = require("../lesson/constants");
const constants_2 = require("../task-schedule/constants");
const constants_3 = require("../task-template/constants");
const constants_4 = require("../user/constants");
exports.initialTaskReview = {
    createdAt: new Date(),
    reviewCompletedAt: new Date(),
    reviewRequestedAt: new Date(),
    reviewRequests: 0,
    taskId: "",
    groupId: "",
    updatedAt: new Date(),
    reviewState: "",
    task: {},
    id: "",
};
exports.initialTaskEntity = {
    lessonId: "",
    taskReview: exports.initialTaskReview,
    taskScheduleId: "",
    taskSchedule: constants_2.initialTaskSchedule,
    state: "",
    user: constants_4.initialUserValues,
    id: "",
    createdAt: "",
    lesson: constants_1.initialLessonValues,
    orderIndex: 0,
    startedAt: "",
    taskTemplateId: "",
    userId: "",
    taskTemplate: constants_3.defaultTaskTemplate,
};
exports.initialCodeSolution = {
    code: "",
    id: "",
    language: "",
    taskId: "",
};
exports.initialTextSolution = {
    solution: {
        content: {},
        createdAt: "",
        id: "",
        updatedAt: "",
    },
    id: "",
    taskId: "",
    solutionId: "",
};
