"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateOwnedLessonTemplate = void 0;
const client_1 = require("@apollo/client");
const updateOwnedLessonTemplate_1 = require("../mutations/updateOwnedLessonTemplate");
const useUpdateOwnedLessonTemplate = () => {
    const [updateExistingLessonTemplate, { loading, called }] = (0, client_1.useMutation)(updateOwnedLessonTemplate_1.UPDATE_OWNED_LESSON_TEMPLATE);
    const updateLessonTemplate = (id, data) => __awaiter(void 0, void 0, void 0, function* () {
        return yield updateExistingLessonTemplate({
            variables: {
                lessonTemplateId: id,
                data,
            },
            optimisticResponse: {
                updateLessonTemplate: {
                    id,
                    //@ts-ignore
                    __typename: "LessonTemplateEntity",
                    name: data === null || data === void 0 ? void 0 : data.name,
                },
            },
            update: (cache) => {
                updateLessonTemplateInCache(cache, id, data);
            },
        });
    });
    const updateLessonTemplateInCache = (cache, lessonTemplateId, data) => {
        const lessonTemplateToUpdate = {
            id: lessonTemplateId,
            __typename: "LessonTemplateEntity",
        };
        cache.modify({
            id: cache.identify(lessonTemplateToUpdate),
            fields: {
                name(prevName) {
                    return (data === null || data === void 0 ? void 0 : data.name) || prevName;
                },
            },
        });
    };
    return { updateLessonTemplate, loading, called };
};
exports.useUpdateOwnedLessonTemplate = useUpdateOwnedLessonTemplate;
