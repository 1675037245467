"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_ACTIVE_GROUP_ACCESS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_ACTIVE_GROUP_ACCESS = (0, client_1.gql) `
  query FindMyActiveGroupAccess {
    findMyActiveGroupAccess {
      id
      status
      plan
      stripePayment {
        checkoutId
      }
      group {
        id
        name
        state
      }
    }
  }
`;
