"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGenerateSignupLink = exports.useGenerateLoginLink = exports.useAuthorizeAndEnterGroup = exports.useGithubCredentials = void 0;
var useGithubCredentials_1 = require("../../github/hooks/useGithubCredentials");
Object.defineProperty(exports, "useGithubCredentials", { enumerable: true, get: function () { return useGithubCredentials_1.useGithubCredentials; } });
var useAuthorizeAndEnterGroup_1 = require("./useAuthorizeAndEnterGroup");
Object.defineProperty(exports, "useAuthorizeAndEnterGroup", { enumerable: true, get: function () { return useAuthorizeAndEnterGroup_1.useAuthorizeAndEnterGroup; } });
var useGenerateLoginLink_1 = require("./useGenerateLoginLink");
Object.defineProperty(exports, "useGenerateLoginLink", { enumerable: true, get: function () { return useGenerateLoginLink_1.useGenerateLoginLink; } });
var useGenerateSignupLink_1 = require("./useGenerateSignupLink");
Object.defineProperty(exports, "useGenerateSignupLink", { enumerable: true, get: function () { return useGenerateSignupLink_1.useGenerateSignupLink; } });
