"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindDocument = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const getDocument_1 = require("../queries/getDocument");
const useFindDocument = (id) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(getDocument_1.GET_DOCUMENT, {
        variables: { id },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
    });
    return {
        document: loading
            ? constants_1.initialDocumentValues
            : (data === null || data === void 0 ? void 0 : data.findDocument) || constants_1.initialDocumentValues,
        error,
        loading,
        refetch,
    };
};
exports.useFindDocument = useFindDocument;
