"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_REVIEW_CONDITIONS = void 0;
const core_1 = require("@apollo/client/core");
exports.UPDATE_REVIEW_CONDITIONS = (0, core_1.gql) `
  mutation UpdateReviewConditions($id: String!, $conditions: JSONObject!) {
    updateReviewConditions(id: $id, conditions: $conditions) {
      id
      isOn
      isRequired
      enableAuto
      enableManual
      isDeploymentRequired
    }
  }
`;
