"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_CONTACTS_LIST = void 0;
const client_1 = require("@apollo/client");
exports.FIND_CONTACTS_LIST = (0, client_1.gql) `
  query FindEmailAutomationContactLists {
    findEmailAutomationContactLists {
      id
      name
    }
  }
`;
