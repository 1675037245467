"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_MY_FEATURES = void 0;
const client_1 = require("@apollo/client");
exports.GET_MY_FEATURES = (0, client_1.gql) `
  query GetMyFeatures {
    getMyFeatures {
      team {
        view
        create
        update
      }
      billings {
        view
        create
        update
      }
      settings {
        view
        create
        update
      }
      groups {
        view
        create
        update
      }
    }
  }
`;
