"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_MY_LESSON_TEMPLATES = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_MY_LESSON_TEMPLATES = (0, client_1.gql) `
  mutation UpdateMyLessonTemplates($data: [JSONObject!]!) {
    updateMyLessonTemplates(input: { data: $data }) {
      id
      orderIndex
    }
  }
`;
