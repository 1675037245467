"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_OWNED_LESSON_TEMPLATE = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_OWNED_LESSON_TEMPLATE = (0, client_1.gql) `
  mutation UpdateOwnedLessonTemplate(
    $lessonTemplateId: String!
    $data: JSONObject!
  ) {
    updateOwnedLessonTemplate(
      data: $data
      lessonTemplateId: $lessonTemplateId
    ) {
      id
    }
  }
`;
