"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_TEAMS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_TEAMS = (0, client_1.gql) `
  query FindMyTeams {
    findMyTeams {
      id
      name
      discordTextChannelId
      discordVoiceChannelId
    }
  }
`;
