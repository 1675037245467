"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultTaskTemplate = exports.initialTextSolutionTemplate = exports.initialCodeSolutionTemplate = exports.initialReviewCondition = exports.initialPullCondition = void 0;
const constants_1 = require("../document/constants");
const constants_2 = require("../lesson-template/constants");
exports.initialPullCondition = {
    id: "",
    isRequired: false,
    isMergeRequired: false,
    isOn: false,
    taskTemplateId: "",
    codeReviewPrompt: "",
};
exports.initialReviewCondition = {
    price: 0,
    id: "",
    isRequired: false,
    enableAuto: false,
    enableManual: false,
    isOn: false,
    isDeploymentRequired: false,
    taskTemplateId: "",
};
exports.initialCodeSolutionTemplate = {
    code: "",
    id: "",
    language: " ",
    taskTemplateId: "",
};
exports.initialTextSolutionTemplate = {
    id: "",
    templateId: "",
    taskTemplateId: "",
};
exports.defaultTaskTemplate = {
    id: "",
    title: "",
    brief: constants_1.initialDocumentValues,
    tags: [],
    briefId: "",
    lessonTemplateId: "",
    lessonTemplate: constants_2.initialLessonTemplateValues,
    type: "",
    isPublished: false,
    orderIndex: 0,
    pullRequestCondition: exports.initialPullCondition,
    reviewCondition: exports.initialReviewCondition,
    createdAt: "",
};
