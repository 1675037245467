"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_TEAM_PROJECTS = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_TEAM_PROJECTS = (0, core_1.gql) `
  query FindTeamProjects($teamId: String!) {
    findTeamProjects(teamId: $teamId) {
      progress {
        completedTasksAmount
        scheduledTasksAmount
      }
      project {
        id
        state
        deploymentUrl
        repositoryName
        repositoryOwner
        projectTemplate {
          id
          name
        }
      }
    }
  }
`;
