"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACTIVATE_PROJECT = void 0;
const client_1 = require("@apollo/client");
exports.ACTIVATE_PROJECT = (0, client_1.gql) `
  mutation ActivateProject($projectId: String!) {
    activateProject(projectId: $projectId) {
      progress {
        completedTasksAmount
        scheduledTasksAmount
      }
      project {
        id
        projectScheduleId
        projectSchedule {
          collaborationType
          lessonSchedules {
            id
            lessonTemplate {
              id
              orderIndex
              name
              briefId
            }
          }
        }
        projectTemplate {
          id
          name
          orderIndex
          theme
          type
          tags {
            name
          }
        }
      }
    }
  }
`;
