"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapTaskScheduleTasksToTasks = void 0;
const graphql_1 = require("../../types/graphql");
const mapTaskScheduleTasksToTasks = (taskSchedule) => {
    var _a;
    return (_a = taskSchedule.tasks) === null || _a === void 0 ? void 0 : _a.map((taskQuery) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        return {
            id: taskQuery.id,
            title: taskSchedule.taskTemplate.title,
            type: taskSchedule.taskTemplate.type,
            briefId: taskSchedule.taskTemplate.briefId,
            isEditable: !taskQuery.taskScheduleId,
            lessonId: taskQuery.lessonId || "",
            taskScheduleId: taskSchedule.id,
            taskTemplateId: taskSchedule.taskTemplate.id || "",
            user: {
                id: ((_a = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.user) === null || _a === void 0 ? void 0 : _a.id) || "",
                username: ((_b = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.user) === null || _b === void 0 ? void 0 : _b.username) || "",
                email: ((_c = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.user) === null || _c === void 0 ? void 0 : _c.email) || "",
                avatarUrl: ((_d = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.user) === null || _d === void 0 ? void 0 : _d.avatarUrl) || "",
            },
            assignee: (taskSchedule === null || taskSchedule === void 0 ? void 0 : taskSchedule.assignee)
                ? {
                    id: taskSchedule.assignee.id,
                    username: taskSchedule.assignee.username || "",
                    email: taskSchedule.assignee.email,
                    avatarUrl: taskSchedule.assignee.avatarUrl || "",
                }
                : null,
            tags: ((_f = (_e = taskQuery.taskTemplate) === null || _e === void 0 ? void 0 : _e.tags) === null || _f === void 0 ? void 0 : _f.map((tag) => tag.name)) || [],
            projectId: ((_g = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.lesson) === null || _g === void 0 ? void 0 : _g.projectId) || "",
            lessonTemplateName: ((_j = (_h = taskSchedule.taskTemplate) === null || _h === void 0 ? void 0 : _h.lessonTemplate) === null || _j === void 0 ? void 0 : _j.name) || "",
            tasksAmountInLesson: ((_m = (_l = (_k = taskQuery.taskTemplate) === null || _k === void 0 ? void 0 : _k.lessonTemplate) === null || _l === void 0 ? void 0 : _l.taskTemplates) === null || _m === void 0 ? void 0 : _m.length) || 0,
            state: taskQuery.state,
            theme: ((_p = (_o = taskSchedule === null || taskSchedule === void 0 ? void 0 : taskSchedule.taskTemplate) === null || _o === void 0 ? void 0 : _o.lessonTemplate) === null || _p === void 0 ? void 0 : _p.theme) || graphql_1.Theme.DEFAULT,
            taskTemplateOrderIndex: taskSchedule.taskTemplate.orderIndex + 1,
            reviewState: ((_q = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.taskReview) === null || _q === void 0 ? void 0 : _q.reviewState) ||
                graphql_1.TaskReviewState.NO_REVIEW_REQUESTED,
            orderIndex: taskQuery.orderIndex,
            reviewCondition: {
                price: ((_r = taskSchedule.taskTemplate.reviewCondition) === null || _r === void 0 ? void 0 : _r.price) || 0,
                isOn: !!((_s = taskSchedule.taskTemplate.reviewCondition) === null || _s === void 0 ? void 0 : _s.isOn),
                isRequired: !!((_t = taskSchedule.taskTemplate.reviewCondition) === null || _t === void 0 ? void 0 : _t.isRequired),
            },
            pullRequestCondition: {
                isOn: !!((_u = taskSchedule.taskTemplate.pullRequestCondition) === null || _u === void 0 ? void 0 : _u.isOn),
                isMergeRequired: !!((_v = taskSchedule.taskTemplate.pullRequestCondition) === null || _v === void 0 ? void 0 : _v.isMergeRequired),
            },
        };
    });
};
exports.mapTaskScheduleTasksToTasks = mapTaskScheduleTasksToTasks;
