"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_TASK_COMMENT = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_TASK_COMMENT = (0, client_1.gql) `
  mutation CreateTaskComment($taskId: String!, $comment: String!) {
    createTaskComment(taskId: $taskId, comment: $comment) {
      id
      comment
      authorId
      author {
        id
        username
        avatarUrl
      }
    }
  }
`;
