"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindLiveTaskSchedules = void 0;
const client_1 = require("@apollo/client");
const taskScheduleQueryMapper_1 = require("../mappers/taskScheduleQueryMapper");
const findActiveTaskSchedules_1 = require("../queries/findActiveTaskSchedules");
const useFindLiveTaskSchedules = (lessonScheduleId) => {
    const { data, loading } = (0, client_1.useQuery)(findActiveTaskSchedules_1.FIND_ACTIVE_TASK_SCHEDULES, {
        variables: {
            lessonScheduleId,
        },
        fetchPolicy: "cache-first",
    });
    return {
        taskSchedules: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findActiveTaskSchedules.map(taskScheduleQueryMapper_1.mapTaskScheduleEntity)) || [],
        loading,
    };
};
exports.useFindLiveTaskSchedules = useFindLiveTaskSchedules;
