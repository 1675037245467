"use strict";
/*
 * -------------------------------------------------------
 * THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 * -------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserSpecialization = exports.TransactionType = exports.Theme = exports.TaskType = exports.TaskReviewState = exports.SubscriptionStatus = exports.SubscriptionPlan = exports.SchedulingState = exports.Role = exports.Rank = exports.PublishState = exports.ProjectType = exports.ProgressState = exports.PaymentMode = exports.MediaType = exports.GroupState = exports.GroupPageButtonType = exports.GithubRepoDistribution = exports.ExperienceLevel = exports.EmploymentType = exports.DifficultyLevel = exports.Currency = exports.ContentState = exports.CollaborationType = exports.CoinTransactionChannel = exports.CodeLanguage = exports.AiInputDataType = exports.AccessRequestStatus = exports.AccessPlan = void 0;
/* tslint:disable */
/* eslint-disable */
var AccessPlan;
(function (AccessPlan) {
    AccessPlan["FREE"] = "FREE";
    AccessPlan["NERDBORDER"] = "NERDBORDER";
    AccessPlan["NERDBORDER_PRO"] = "NERDBORDER_PRO";
})(AccessPlan = exports.AccessPlan || (exports.AccessPlan = {}));
var AccessRequestStatus;
(function (AccessRequestStatus) {
    AccessRequestStatus["APPROVED"] = "APPROVED";
    AccessRequestStatus["EXPIRED"] = "EXPIRED";
    AccessRequestStatus["PENDING"] = "PENDING";
    AccessRequestStatus["REVOKED"] = "REVOKED";
})(AccessRequestStatus = exports.AccessRequestStatus || (exports.AccessRequestStatus = {}));
var AiInputDataType;
(function (AiInputDataType) {
    AiInputDataType["FILE"] = "FILE";
    AiInputDataType["IMAGE"] = "IMAGE";
    AiInputDataType["TEXT"] = "TEXT";
    AiInputDataType["URL"] = "URL";
})(AiInputDataType = exports.AiInputDataType || (exports.AiInputDataType = {}));
var CodeLanguage;
(function (CodeLanguage) {
    CodeLanguage["CPP"] = "CPP";
    CodeLanguage["JAVA"] = "JAVA";
    CodeLanguage["JAVASCRIPT"] = "JAVASCRIPT";
    CodeLanguage["MYSQL"] = "MYSQL";
    CodeLanguage["PHP"] = "PHP";
    CodeLanguage["PYTHON"] = "PYTHON";
    CodeLanguage["RUBY"] = "RUBY";
    CodeLanguage["RUST"] = "RUST";
    CodeLanguage["TYPESCRIPT"] = "TYPESCRIPT";
})(CodeLanguage = exports.CodeLanguage || (exports.CodeLanguage = {}));
var CoinTransactionChannel;
(function (CoinTransactionChannel) {
    CoinTransactionChannel["ADMIN"] = "ADMIN";
    CoinTransactionChannel["BONUS_POINTS"] = "BONUS_POINTS";
    CoinTransactionChannel["LEVEL_UP"] = "LEVEL_UP";
    CoinTransactionChannel["REFERRAL"] = "REFERRAL";
    CoinTransactionChannel["REVIEW_REQUEST"] = "REVIEW_REQUEST";
    CoinTransactionChannel["SHOP"] = "SHOP";
    CoinTransactionChannel["SIGN_UP"] = "SIGN_UP";
})(CoinTransactionChannel = exports.CoinTransactionChannel || (exports.CoinTransactionChannel = {}));
var CollaborationType;
(function (CollaborationType) {
    CollaborationType["COLLABORATION"] = "COLLABORATION";
    CollaborationType["INDIVIDUAL"] = "INDIVIDUAL";
})(CollaborationType = exports.CollaborationType || (exports.CollaborationType = {}));
var ContentState;
(function (ContentState) {
    ContentState["ACTIVE"] = "ACTIVE";
    ContentState["COMPLETED"] = "COMPLETED";
    ContentState["FREEZED"] = "FREEZED";
    ContentState["INACTIVE"] = "INACTIVE";
    ContentState["LOCKED"] = "LOCKED";
    ContentState["UPDATE_AVAILABLE"] = "UPDATE_AVAILABLE";
})(ContentState = exports.ContentState || (exports.ContentState = {}));
var Currency;
(function (Currency) {
    Currency["EUR"] = "EUR";
    Currency["PLN"] = "PLN";
    Currency["USD"] = "USD";
})(Currency = exports.Currency || (exports.Currency = {}));
var DifficultyLevel;
(function (DifficultyLevel) {
    DifficultyLevel["ELITE"] = "ELITE";
    DifficultyLevel["INTRO"] = "INTRO";
    DifficultyLevel["PRO"] = "PRO";
})(DifficultyLevel = exports.DifficultyLevel || (exports.DifficultyLevel = {}));
var EmploymentType;
(function (EmploymentType) {
    EmploymentType["B2B"] = "B2B";
    EmploymentType["CONTRACT_OF_EMPLOYMENT"] = "CONTRACT_OF_EMPLOYMENT";
    EmploymentType["LOOKING_FOR_JOB"] = "LOOKING_FOR_JOB";
    EmploymentType["STUDENT"] = "STUDENT";
})(EmploymentType = exports.EmploymentType || (exports.EmploymentType = {}));
var ExperienceLevel;
(function (ExperienceLevel) {
    ExperienceLevel["INTERN"] = "INTERN";
    ExperienceLevel["JUNIOR"] = "JUNIOR";
    ExperienceLevel["MID"] = "MID";
    ExperienceLevel["SENIOR_PLUS"] = "SENIOR_PLUS";
})(ExperienceLevel = exports.ExperienceLevel || (exports.ExperienceLevel = {}));
var GithubRepoDistribution;
(function (GithubRepoDistribution) {
    GithubRepoDistribution["FORK"] = "FORK";
    GithubRepoDistribution["TEMPLATE"] = "TEMPLATE";
})(GithubRepoDistribution = exports.GithubRepoDistribution || (exports.GithubRepoDistribution = {}));
var GroupPageButtonType;
(function (GroupPageButtonType) {
    GroupPageButtonType["BOOK_CALL"] = "BOOK_CALL";
    GroupPageButtonType["JOIN_HACKATHON"] = "JOIN_HACKATHON";
    GroupPageButtonType["JOIN_WITH_EMAIL"] = "JOIN_WITH_EMAIL";
    GroupPageButtonType["JOIN_WITH_GITHUB"] = "JOIN_WITH_GITHUB";
    GroupPageButtonType["SUBMIT_REQUEST"] = "SUBMIT_REQUEST";
})(GroupPageButtonType = exports.GroupPageButtonType || (exports.GroupPageButtonType = {}));
var GroupState;
(function (GroupState) {
    GroupState["active"] = "active";
    GroupState["archived"] = "archived";
    GroupState["freezed"] = "freezed";
    GroupState["inactive"] = "inactive";
})(GroupState = exports.GroupState || (exports.GroupState = {}));
var MediaType;
(function (MediaType) {
    MediaType["EMBED"] = "EMBED";
    MediaType["IMAGE"] = "IMAGE";
    MediaType["VIDEO"] = "VIDEO";
})(MediaType = exports.MediaType || (exports.MediaType = {}));
var PaymentMode;
(function (PaymentMode) {
    PaymentMode["PAYMENT"] = "PAYMENT";
    PaymentMode["SUBSCRIPTION"] = "SUBSCRIPTION";
})(PaymentMode = exports.PaymentMode || (exports.PaymentMode = {}));
var ProgressState;
(function (ProgressState) {
    ProgressState["BACKLOG"] = "BACKLOG";
    ProgressState["DONE"] = "DONE";
    ProgressState["IN_PROGRESS"] = "IN_PROGRESS";
    ProgressState["IN_REVIEW"] = "IN_REVIEW";
})(ProgressState = exports.ProgressState || (exports.ProgressState = {}));
var ProjectType;
(function (ProjectType) {
    ProjectType["GIT_FLOW"] = "GIT_FLOW";
    ProjectType["SANDBOX"] = "SANDBOX";
})(ProjectType = exports.ProjectType || (exports.ProjectType = {}));
var PublishState;
(function (PublishState) {
    PublishState["AWAITING_UPDATE"] = "AWAITING_UPDATE";
    PublishState["DRAFT"] = "DRAFT";
    PublishState["PUBLISHED"] = "PUBLISHED";
})(PublishState = exports.PublishState || (exports.PublishState = {}));
var Rank;
(function (Rank) {
    Rank["BIT_LORD"] = "BIT_LORD";
    Rank["CURIOUS_EXPLORER"] = "CURIOUS_EXPLORER";
    Rank["CYBERPUNK"] = "CYBERPUNK";
    Rank["ITERATOR_3000"] = "ITERATOR_3000";
    Rank["NOVICE_ADVENTURER"] = "NOVICE_ADVENTURER";
    Rank["PROBLEM_INVESTIGATOR"] = "PROBLEM_INVESTIGATOR";
    Rank["PROGRAM_CONQUEROR"] = "PROGRAM_CONQUEROR";
    Rank["SKILLED_APPRENTICE"] = "SKILLED_APPRENTICE";
    Rank["TASKMASTER"] = "TASKMASTER";
    Rank["TECH_ELDER"] = "TECH_ELDER";
    Rank["WEB_CRAWLER"] = "WEB_CRAWLER";
})(Rank = exports.Rank || (exports.Rank = {}));
var Role;
(function (Role) {
    Role["ADMIN"] = "ADMIN";
    Role["APP"] = "APP";
    Role["MENTOR"] = "MENTOR";
    Role["ORG_MEMBER"] = "ORG_MEMBER";
    Role["ORG_OWNER"] = "ORG_OWNER";
    Role["RECRUITER"] = "RECRUITER";
    Role["STUDENT"] = "STUDENT";
    Role["USER"] = "USER";
})(Role = exports.Role || (exports.Role = {}));
var SchedulingState;
(function (SchedulingState) {
    SchedulingState["HIDDEN"] = "HIDDEN";
    SchedulingState["LIVE"] = "LIVE";
    SchedulingState["LOCKED"] = "LOCKED";
    SchedulingState["SCHEDULED"] = "SCHEDULED";
})(SchedulingState = exports.SchedulingState || (exports.SchedulingState = {}));
var SubscriptionPlan;
(function (SubscriptionPlan) {
    SubscriptionPlan["MENTOR"] = "MENTOR";
    SubscriptionPlan["PERSONAL"] = "PERSONAL";
})(SubscriptionPlan = exports.SubscriptionPlan || (exports.SubscriptionPlan = {}));
var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["active"] = "active";
    SubscriptionStatus["canceled"] = "canceled";
    SubscriptionStatus["incomplete"] = "incomplete";
    SubscriptionStatus["incomplete_expired"] = "incomplete_expired";
    SubscriptionStatus["past_due"] = "past_due";
    SubscriptionStatus["trialing"] = "trialing";
    SubscriptionStatus["unpaid"] = "unpaid";
})(SubscriptionStatus = exports.SubscriptionStatus || (exports.SubscriptionStatus = {}));
var TaskReviewState;
(function (TaskReviewState) {
    TaskReviewState["APPROVED"] = "APPROVED";
    TaskReviewState["APPROVED_BY_MENTOR"] = "APPROVED_BY_MENTOR";
    TaskReviewState["NO_REVIEW_REQUESTED"] = "NO_REVIEW_REQUESTED";
    TaskReviewState["REJECTED"] = "REJECTED";
    TaskReviewState["REVIEW_COMPLETED"] = "REVIEW_COMPLETED";
    TaskReviewState["REVIEW_REQUESTED"] = "REVIEW_REQUESTED";
})(TaskReviewState = exports.TaskReviewState || (exports.TaskReviewState = {}));
var TaskType;
(function (TaskType) {
    TaskType["AI"] = "AI";
    TaskType["CODE_EDITOR"] = "CODE_EDITOR";
    TaskType["COLLABORATIVE"] = "COLLABORATIVE";
    TaskType["GIT"] = "GIT";
    TaskType["TEXT_EDITOR"] = "TEXT_EDITOR";
    TaskType["THEORY"] = "THEORY";
})(TaskType = exports.TaskType || (exports.TaskType = {}));
var Theme;
(function (Theme) {
    Theme["AMBER"] = "AMBER";
    Theme["BLUE"] = "BLUE";
    Theme["BLUE_GRAY"] = "BLUE_GRAY";
    Theme["CARDBOARD"] = "CARDBOARD";
    Theme["CONVERTO"] = "CONVERTO";
    Theme["CYAN"] = "CYAN";
    Theme["DEFAULT"] = "DEFAULT";
    Theme["EMERALD"] = "EMERALD";
    Theme["FUCHSIA"] = "FUCHSIA";
    Theme["GREEN"] = "GREEN";
    Theme["INDIGO"] = "INDIGO";
    Theme["LAB"] = "LAB";
    Theme["LIGHT_BLUE"] = "LIGHT_BLUE";
    Theme["LIME"] = "LIME";
    Theme["ORANGE"] = "ORANGE";
    Theme["PINK"] = "PINK";
    Theme["PURPLE"] = "PURPLE";
    Theme["RED"] = "RED";
    Theme["ROSE"] = "ROSE";
    Theme["TEAL"] = "TEAL";
    Theme["VIOLET"] = "VIOLET";
    Theme["YELLOW"] = "YELLOW";
})(Theme = exports.Theme || (exports.Theme = {}));
var TransactionType;
(function (TransactionType) {
    TransactionType["INCOME"] = "INCOME";
    TransactionType["OUTCOME"] = "OUTCOME";
})(TransactionType = exports.TransactionType || (exports.TransactionType = {}));
var UserSpecialization;
(function (UserSpecialization) {
    UserSpecialization["BACKEND"] = "BACKEND";
    UserSpecialization["DESIGNER"] = "DESIGNER";
    UserSpecialization["FRONTEND"] = "FRONTEND";
    UserSpecialization["FULLSTACK"] = "FULLSTACK";
})(UserSpecialization = exports.UserSpecialization || (exports.UserSpecialization = {}));
