"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DELETE_FILE = void 0;
const client_1 = require("@apollo/client");
exports.DELETE_FILE = (0, client_1.gql) `
  mutation DeleteFile($id: String!) {
    deleteFile(id: $id) {
      id
    }
  }
`;
