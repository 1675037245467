"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyProjects = void 0;
const client_1 = require("@apollo/client");
const __1 = require("../..");
const findMyProjectsWithProgress_1 = require("../queries/findMyProjectsWithProgress");
const useFindMyProjects = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findMyProjectsWithProgress_1.FIND_MY_PROJECTS_WITH_PROGRESS, {
        fetchPolicy: "network-only",
    });
    return {
        projects: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findMyProjects.map(__1.mapProjectWithProgressToProject)) || [],
        error,
        loading,
        refetch,
    };
};
exports.useFindMyProjects = useFindMyProjects;
