"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateTasksOrder = void 0;
const client_1 = require("@apollo/client");
const updateTasks_1 = require("../mutations/updateTasks");
const useUpdateTasksOrder = () => {
    const [updateExistingTasksOrder] = (0, client_1.useMutation)(updateTasks_1.UPDATE_TASKS);
    const reorderTasks = (tasks) => __awaiter(void 0, void 0, void 0, function* () {
        const mappedTasksPayload = tasks.map((task, idx) => ({
            id: task.id,
            orderIndex: idx,
        }));
        yield updateExistingTasksOrder({
            variables: {
                data: mappedTasksPayload,
            },
        });
    });
    return {
        reorderTasks,
    };
};
exports.useUpdateTasksOrder = useUpdateTasksOrder;
