"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPublicProjectTemplateDocument = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../../document/constants");
const findPublicProjectTemplateDocument_1 = require("../queries/findPublicProjectTemplateDocument");
const useFindPublicProjectTemplateDocument = (projectTemplateId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findPublicProjectTemplateDocument_1.FIND_PUBLIC_PROJECT_TEMPLATE_DOCUMENT, {
        variables: { id: projectTemplateId },
        fetchPolicy: "network-only",
    });
    return {
        document: loading
            ? constants_1.initialDocumentValues
            : (data === null || data === void 0 ? void 0 : data.findPublicProjectTemplateDocument) || constants_1.initialDocumentValues,
        error,
        loading,
        refetch,
    };
};
exports.useFindPublicProjectTemplateDocument = useFindPublicProjectTemplateDocument;
