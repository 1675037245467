"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PUBLIC_GROUP_PAGE = void 0;
const client_1 = require("@apollo/client");
exports.FIND_PUBLIC_GROUP_PAGE = (0, client_1.gql) `
  query FindPublicGroupPage($slug: String!) {
    findPublicGroupPage(slug: $slug) {
      id
      slug
      gtmId
      document {
        id
        content
        notionPageId
      }
      coverImage {
        url
      }
      shortDsc
      benefits
      state
      ctaButtonType
      showPrice
      coverType
      coverMediaSrc
      groupId
      termsAndConditionsUrl
      privacyPolicyUrl
      group {
        id
        name
        paymentMode
        currency
        pricePerSeat
        state
        accesses {
          id
        }
        program {
          id
        }
        tags {
          id
          name
        }
        settings {
          id
          allowAutoAccess
          allowDisplaySeats
          allowNewEntries
          allowProgramPreview
          allowDisplayCalendar
          allowTryForFree
          mentorCalendarUrl
          previewGroupId
          maxSeats
        }
      }
    }
  }
`;
