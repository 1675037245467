"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateGroup = void 0;
const client_1 = require("@apollo/client");
const countMyOrgContent_1 = require("../../organisation/queries/countMyOrgContent");
const createGroup_1 = require("../mutations/createGroup");
const createRecruitmentGroup_1 = require("../mutations/createRecruitmentGroup");
const findMyOrgGroups_1 = require("../queries/findMyOrgGroups");
const useCreateGroup = () => {
    const [createNewGroup, { loading, called }] = (0, client_1.useMutation)(createGroup_1.CREATE_GROUP);
    const [createRecruitment, createRecruitmentResponse] = (0, client_1.useMutation)(createRecruitmentGroup_1.CREATE_RECRUITMENT_GROUP);
    const createGroup = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const response = yield createNewGroup({
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.createGroup))
                    return;
                incrementOrgGroupsAmount(cache);
                updateGroupsInCache(cache, data.createGroup);
            },
        });
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.createGroup;
    });
    const createRecruitmentGroup = (jobOfferUrl, prompt) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const response = yield createRecruitment({
            variables: { jobOfferUrl, prompt },
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.createRecruitmentGroup))
                    return;
                incrementOrgGroupsAmount(cache);
                updateGroupsInCache(cache, data.createRecruitmentGroup);
            },
        });
        return (_b = response.data) === null || _b === void 0 ? void 0 : _b.createRecruitmentGroup;
    });
    const incrementOrgGroupsAmount = (cache) => {
        const orgContent = cache.readQuery({
            query: countMyOrgContent_1.MONITOR_CONTENT_AMOUNT,
        });
        if (orgContent) {
            cache.writeQuery({
                query: countMyOrgContent_1.MONITOR_CONTENT_AMOUNT,
                data: {
                    monitorContentAmount: Object.assign(Object.assign({}, orgContent.monitorContentAmount), { groups: orgContent.monitorContentAmount.groups + 1 }),
                },
            });
        }
    };
    const updateGroupsInCache = (cache, createdGroup) => {
        try {
            const { findMyOrgGroups } = cache.readQuery({
                query: findMyOrgGroups_1.FIND_MY_ORG_GROUPS,
            });
            cache.writeQuery({
                query: findMyOrgGroups_1.FIND_MY_ORG_GROUPS,
                data: {
                    findMyOrgGroups: [...findMyOrgGroups, createdGroup],
                },
            });
        }
        catch (error) {
            console.error("Error updating groups in cache:", error);
        }
    };
    return {
        createGroup,
        loading,
        called,
        createRecruitmentGroup,
        createRecruitmentResponse,
    };
};
exports.useCreateGroup = useCreateGroup;
