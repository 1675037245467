"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_ORG_INVOICE_DETAILS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_ORG_INVOICE_DETAILS = (0, client_1.gql) `
  query FindMyOrgInvoiceDetails {
    findMyOrganisation {
      id
      invoiceDetails {
        id
        city
        companyName
        country
        address
        taxNumber
        postalCode
      }
    }
  }
`;
