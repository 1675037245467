"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_OWNED_PROJECT_LESSON_TEMPLATES = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_OWNED_PROJECT_LESSON_TEMPLATES = (0, core_1.gql) `
  query FindMyProjectsLessonTemplate($projectTemplateId: String!) {
    findOwnedProjectLessonTemplates(projectTemplateId: $projectTemplateId) {
      id
      name
      theme
      isPublished
      orderIndex
      projectTemplateId
      taskTemplates {
        id
      }
    }
  }
`;
