"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindProjectSchedule = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findProjectSchedule_1 = require("../queries/findProjectSchedule");
const useFindProjectSchedule = (id) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findProjectSchedule_1.FIND_PROJECT_SCHEDULE, {
        variables: { id },
        fetchPolicy: "cache-first",
    });
    return {
        projectSchedule: loading
            ? constants_1.initialProjectSchedule
            : (data === null || data === void 0 ? void 0 : data.findProjectSchedule) || constants_1.initialProjectSchedule,
        loading,
        refetch,
        error,
    };
};
exports.useFindProjectSchedule = useFindProjectSchedule;
