"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTaskTextSolution = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findTaskTextSolution_1 = require("../queries/findTaskTextSolution");
const useFindTaskTextSolution = (taskId) => {
    const { data, loading } = (0, client_1.useQuery)(findTaskTextSolution_1.FIND_TASK_TEXT_SOLUTION, {
        variables: {
            taskId,
        },
    });
    return {
        textSolution: loading
            ? constants_1.initialTextSolution
            : ((data === null || data === void 0 ? void 0 : data.findTaskTextSolution) && data.findTaskTextSolution) ||
                constants_1.initialTextSolution,
        loading,
    };
};
exports.useFindTaskTextSolution = useFindTaskTextSolution;
