"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapTaskScheduleEntity = void 0;
const constants_1 = require("../../task-template/constants");
const graphql_1 = require("../../types/graphql");
const mapTaskScheduleTasksToTasks_1 = require("./mapTaskScheduleTasksToTasks");
const mapTaskScheduleEntity = (taskSchedule) => {
    var _a, _b;
    if (!taskSchedule.taskTemplate) {
        throw Error("TaskTemplate include not found");
    }
    if (!taskSchedule.taskTemplate.lessonTemplate) {
        throw Error("LessonTemplate include not found");
    }
    if (!taskSchedule.taskTemplate.tags) {
        throw Error("Tags include not found");
    }
    return {
        id: taskSchedule.id,
        briefId: taskSchedule.taskTemplate.briefId,
        taskTemplateId: taskSchedule.taskTemplateId,
        orderIndex: taskSchedule.taskTemplate.orderIndex,
        tasks: (0, mapTaskScheduleTasksToTasks_1.mapTaskScheduleTasksToTasks)(taskSchedule) || [],
        reviewConditions: taskSchedule.taskTemplate.reviewCondition || constants_1.initialReviewCondition,
        taskType: taskSchedule.taskTemplate.type,
        lessonScheduleId: taskSchedule.lessonScheduleId,
        tags: taskSchedule.taskTemplate.tags.map((tag) => tag.name),
        assignee: taskSchedule.assignee
            ? {
                id: taskSchedule.assignee.id,
                avatarUrl: taskSchedule.assignee.avatarUrl || "",
                username: taskSchedule.assignee.username || "",
                email: taskSchedule.assignee.email,
            }
            : null,
        lessonName: ((_a = taskSchedule.taskTemplate.lessonTemplate) === null || _a === void 0 ? void 0 : _a.name) ||
            "Lesson name not specified",
        taskName: taskSchedule.taskTemplate.title,
        theme: ((_b = taskSchedule.taskTemplate.lessonTemplate) === null || _b === void 0 ? void 0 : _b.theme) || graphql_1.Theme.DEFAULT,
    };
};
exports.mapTaskScheduleEntity = mapTaskScheduleEntity;
