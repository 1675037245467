"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AUTHED_USER = void 0;
const client_1 = require("@apollo/client");
exports.AUTHED_USER = (0, client_1.gql) `
  query GetMe {
    user {
      id
      email
      username
      avatarUrl
      discordUserId
      isBlocked
      organisationId
      roles
      isOnboardingComplete
    }
  }
`;
