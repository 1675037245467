"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_ACTIVE_TASK_SCHEDULES = void 0;
const client_1 = require("@apollo/client");
exports.FIND_ACTIVE_TASK_SCHEDULES = (0, client_1.gql) `
  query FindActiveTaskSchedules($lessonScheduleId: String!) {
    findActiveTaskSchedules(lessonScheduleId: $lessonScheduleId) {
      id
      assignee {
        username
        avatarUrl
        email
      }
      taskTemplate {
        id
        orderIndex
        title
        tags {
          name
        }
        lessonTemplate {
          id
          name
        }
      }
    }
  }
`;
