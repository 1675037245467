"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_GROUP_TAGS = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_GROUP_TAGS = (0, core_1.gql) `
  query FindGroupTags($groupId: String!) {
    findGroupTags(groupId: $groupId) {
      id
      name
      value
    }
  }
`;
