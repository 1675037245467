"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_GROUP_TASK_REVIEWS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_GROUP_TASK_REVIEWS = (0, client_1.gql) `
  query FindGroupTaskReviews($groupId: String!, $state: TaskReviewState!) {
    findGroupTaskReviews(groupId: $groupId, state: $state) {
      id
      reviewState
      reviewRequestedAt
      taskId
      task {
        id
        user {
          avatarUrl
          email
        }
        taskSchedule {
          assignee {
            avatarUrl
            email
          }
        }
        taskTemplate {
          title
          lessonTemplate {
            name
            theme
          }
        }
      }
    }
  }
`;
