"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_GROUP_TEAMS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_GROUP_TEAMS = (0, client_1.gql) `
  query FindGroupTeams($groupId: String!) {
    findGroupTeams(groupId: $groupId) {
      id
      name
      discordTextChannelId
      discordVoiceChannelId
      hackapoints
    }
  }
`;
