"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateGroupDiscordSettings = void 0;
const client_1 = require("@apollo/client");
const updateGroupDiscordSettings_1 = require("../mutations/updateGroupDiscordSettings");
const useUpdateGroupDiscordSettings = () => {
    const [updateExistingGroup, { loading, called }] = (0, client_1.useMutation)(updateGroupDiscordSettings_1.UPDATE_GROUP_DISCORD_SETTINGS);
    const updateGroupDiscordSettings = (discordSettingsId, data) => __awaiter(void 0, void 0, void 0, function* () {
        return yield updateExistingGroup({
            variables: {
                discordSettingsId,
                data,
            },
            update: (cache, { data }) => {
                if (data === null || data === void 0 ? void 0 : data.updateGroupDiscordChannel) {
                    updateDiscordSettingsInCache(cache, discordSettingsId, data.updateGroupDiscordChannel);
                }
            },
        });
    });
    const updateDiscordSettingsInCache = (cache, discordSettingsId, data) => {
        const settingsToUpdate = {
            id: discordSettingsId,
            __typename: "GroupDiscordSettingsEntity",
        };
        cache.modify({
            id: cache.identify(settingsToUpdate),
            fields: {
                discordChannelUrl(prev) {
                    return (data === null || data === void 0 ? void 0 : data.discordChannelUrl) || prev;
                },
                discordChannelId(prev) {
                    return (data === null || data === void 0 ? void 0 : data.discordChannelId) || prev;
                },
                discordAccessRoleId(prev) {
                    return (data === null || data === void 0 ? void 0 : data.discordAccessRoleId) || prev;
                },
            },
        });
    };
    return { updateGroupDiscordSettings, loading, called };
};
exports.useUpdateGroupDiscordSettings = useUpdateGroupDiscordSettings;
