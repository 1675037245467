"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AUTHORIZE_AND_ENTER_GROUP = void 0;
const client_1 = require("@apollo/client");
exports.AUTHORIZE_AND_ENTER_GROUP = (0, client_1.gql) `
  mutation AuthorizeAndEnterGroup($email: String!, $groupId: String!) {
    authorizeAndEnterGroup(email: $email, groupId: $groupId) {
      signInLink
      state
    }
  }
`;
