"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CLEAR_LEVEL_UP = void 0;
const client_1 = require("@apollo/client");
exports.CLEAR_LEVEL_UP = (0, client_1.gql) `
  mutation ClearLevelUp {
    clearLevelUp {
      id
      isLevelUp
      isRankUp
      experiencePoints
      experienceToNextLevel
      level
      rank
    }
  }
`;
