"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_GROUPS_ACCESS_REQUESTS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_GROUPS_ACCESS_REQUESTS = (0, client_1.gql) `
  query FindGroupAccessRequests($groupId: String!) {
    findGroupAccessRequests(groupId: $groupId) {
      id
      email
      status
      groupId
      createdAt
      plan
      user {
        id
        username
        avatarUrl
        email
        isActive
        rating {
          level
        }
      }
    }
  }
`;
