"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialLessonTemplateValues = void 0;
const constants_1 = require("../document/constants");
exports.initialLessonTemplateValues = {
    isPublished: false,
    name: "",
    brief: constants_1.initialDocumentValues,
    briefId: "",
    orderIndex: 0,
    projectTemplateId: "",
    theme: "",
    id: "",
    taskTemplates: [],
    createdAt: "",
};
