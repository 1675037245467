"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteOwnedGroup = void 0;
const client_1 = require("@apollo/client");
const countMyOrgContent_1 = require("../../organisation/queries/countMyOrgContent");
const deleteOwnedGroup_1 = require("../mutations/deleteOwnedGroup");
const findMyOrgGroups_1 = require("../queries/findMyOrgGroups");
const useDeleteOwnedGroup = () => {
    const [deleteSingleGroup, { error, loading }] = (0, client_1.useMutation)(deleteOwnedGroup_1.DELETE_OWNED_GROUP);
    const deleteOwnedGroup = (id) => __awaiter(void 0, void 0, void 0, function* () {
        return yield deleteSingleGroup({
            variables: {
                id,
            },
            update: (cache) => {
                decrementOrgGroupsAmount(cache);
                updateGroupsInCache(cache, id);
            },
        });
    });
    const decrementOrgGroupsAmount = (cache) => {
        const orgContent = cache.readQuery({
            query: countMyOrgContent_1.MONITOR_CONTENT_AMOUNT,
        });
        if (orgContent) {
            cache.writeQuery({
                query: countMyOrgContent_1.MONITOR_CONTENT_AMOUNT,
                data: {
                    monitorContentAmount: Object.assign(Object.assign({}, orgContent.monitorContentAmount), { groups: orgContent.monitorContentAmount.groups - 1 }),
                },
            });
        }
    };
    const updateGroupsInCache = (cache, id) => {
        try {
            const cachedResults = cache.readQuery({
                query: findMyOrgGroups_1.FIND_MY_ORG_GROUPS,
            });
            if (!(cachedResults === null || cachedResults === void 0 ? void 0 : cachedResults.findMyOrgGroups))
                return;
            const updatedGroups = cachedResults.findMyOrgGroups.filter((group) => group.id !== id);
            cache.writeQuery({
                query: findMyOrgGroups_1.FIND_MY_ORG_GROUPS,
                data: {
                    findMyOrgGroups: updatedGroups,
                },
            });
        }
        catch (err) {
            console.error("Error updating cache:", err);
        }
    };
    return { deleteOwnedGroup, loading, error };
};
exports.useDeleteOwnedGroup = useDeleteOwnedGroup;
