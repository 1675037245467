"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyNotifications = void 0;
const client_1 = require("@apollo/client");
const getMyNotifications_1 = require("../queries/getMyNotifications");
const notificationCreated_1 = require("../subscriptions/notificationCreated");
const useFindMyNotifications = (userId) => {
    const { data, error, loading, subscribeToMore, fetchMore } = (0, client_1.useQuery)(getMyNotifications_1.GET_MY_NOTIFICATIONS);
    const subscribeToNotifications = () => {
        subscribeToMore({
            document: notificationCreated_1.NOTIFICATION_CREATED,
            variables: { userId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data)
                    return prev;
                const { notificationCreated } = subscriptionData.data;
                return {
                    findMyNotifications: [
                        ...prev.findMyNotifications,
                        Object.assign(Object.assign({}, notificationCreated), { createdAt: 
                            // TODO: I don't know but it's not returning createdAt somehow - will investigate that later
                            (notificationCreated === null || notificationCreated === void 0 ? void 0 : notificationCreated.createdAt) || new Date().toISOString() }),
                    ].sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)),
                };
            },
        });
    };
    const notifications = loading
        ? []
        : (data === null || data === void 0 ? void 0 : data.findMyNotifications) || [];
    return {
        notifications,
        error,
        loading,
        subscribeToNotifications,
        fetchMore,
    };
};
exports.useFindMyNotifications = useFindMyNotifications;
