"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToggleLessonVisibility = void 0;
const client_1 = require("@apollo/client");
const toggleLessonVisibility_1 = require("../mutations/toggleLessonVisibility");
const useToggleLessonVisibility = () => {
    const [toggleLesson, { loading, called }] = (0, client_1.useMutation)(toggleLessonVisibility_1.TOGGLE_LESSON_VISIBILITY);
    const toggleLessonVisibility = (lessonId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield toggleLesson({
            variables: {
                lessonId,
            },
            update: (cache) => {
                updateLessonInCache(cache, lessonId);
            },
        });
    });
    const updateLessonInCache = (cache, lessonId) => {
        const lessonToUpdate = {
            id: lessonId,
            __typename: "LessonEntity",
        };
        cache.modify({
            id: cache.identify(lessonToUpdate),
            fields: {
                isVisibleOnBoard(prevState) {
                    return !prevState;
                },
            },
        });
    };
    return { toggleLessonVisibility, loading, called };
};
exports.useToggleLessonVisibility = useToggleLessonVisibility;
