"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SYNC_GROUP_ACCESS_BY_ID = void 0;
const client_1 = require("@apollo/client");
exports.SYNC_GROUP_ACCESS_BY_ID = (0, client_1.gql) `
  query SyncUserGroupAccessById($groupId: String!, $userId: String!) {
    syncUserGroupAccessById(groupId: $groupId, userId: $userId) {
      id
      status
      email
      userId
    }
  }
`;
