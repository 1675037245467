"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindUnresolvedTaskReviews = void 0;
const client_1 = require("@apollo/client");
const findUnresolvedOrgTaskReviews_1 = require("../queries/findUnresolvedOrgTaskReviews");
const reviewRequestedInOrg_1 = require("../subscriptions/reviewRequestedInOrg");
const useFindUnresolvedTaskReviews = () => {
    const { data, loading, subscribeToMore } = (0, client_1.useQuery)(findUnresolvedOrgTaskReviews_1.FIND_UNRESOLVED_ORG_TASK_REVIEWS, {
        fetchPolicy: "network-only",
    });
    const subscribeToNewReviews = (orgId) => {
        subscribeToMore({
            document: reviewRequestedInOrg_1.TASK_REVIEW_REQUESTED_IN_ORG,
            variables: { orgId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data)
                    return prev;
                const { reviewRequestedInOrg } = subscriptionData.data;
                return {
                    findUnresolvedOrgTaskReviews: [
                        ...prev.findUnresolvedOrgTaskReviews,
                        reviewRequestedInOrg,
                    ],
                };
            },
        });
    };
    return {
        reviews: loading ? [] : (data === null || data === void 0 ? void 0 : data.findUnresolvedOrgTaskReviews) || [],
        loading,
        subscribeToNewReviews,
    };
};
exports.useFindUnresolvedTaskReviews = useFindUnresolvedTaskReviews;
