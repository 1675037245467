"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_ORG_STRIPE_TRANSACTIONS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_ORG_STRIPE_TRANSACTIONS = (0, client_1.gql) `
  query FindMyOrgStripeTransactions {
    findMyOrgStripeTransactions {
      paymentAmount
      currency
    }
  }
`;
