"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_ONBOARDING_COMPLETE = void 0;
const client_1 = require("@apollo/client");
exports.SET_ONBOARDING_COMPLETE = (0, client_1.gql) `
  mutation SetOnboardingComplete($isOnboardingComplete: Boolean!) {
    setOnboardingComplete(isOnboardingComplete: $isOnboardingComplete) {
      id
      email
      username
      avatarUrl
      isBlocked
      organisationId
      roles
      isOnboardingComplete
    }
  }
`;
