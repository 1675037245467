"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REMOVE_GROUP_USER = void 0;
const client_1 = require("@apollo/client");
exports.REMOVE_GROUP_USER = (0, client_1.gql) `
  mutation RemoveGroupUser($accessId: String!) {
    removeGroupUser(accessId: $accessId) {
      id
    }
  }
`;
