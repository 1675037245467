"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindGroupProgram = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findGroupProgram_1 = require("../queries/findGroupProgram");
const useFindGroupProgram = (groupId, isAwaitingData = false) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findGroupProgram_1.FIND_GROUP_PROGRAM, {
        variables: { groupId },
        fetchPolicy: "cache-first",
        pollInterval: isAwaitingData ? 2000 : 0,
    });
    return {
        program: loading
            ? constants_1.initialProgram
            : (data === null || data === void 0 ? void 0 : data.findGroupProgram) || constants_1.initialProgram,
        isAwaitingData,
        error,
        loading,
        refetch,
    };
};
exports.useFindGroupProgram = useFindGroupProgram;
