"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROJECT_UPDATED = void 0;
const client_1 = require("@apollo/client");
exports.PROJECT_UPDATED = (0, client_1.gql) `
  subscription ProjectUpdated($projectId: String!) {
    projectUpdated(id: $projectId) {
      progress {
        completedTasksAmount
        scheduledTasksAmount
      }
      project {
        id
        state
        teamId
      }
    }
  }
`;
