"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyTask = void 0;
const client_1 = require("@apollo/client");
const mappers_1 = require("../mappers");
const constants_1 = require("../constants");
const findMyTask_1 = require("../queries/findMyTask");
const useFindMyTask = (taskId) => {
    const { data, loading, subscribeToMore } = (0, client_1.useQuery)(findMyTask_1.FIND_MY_TASK, {
        variables: {
            taskId,
        },
        fetchPolicy: "cache-first",
    });
    const initial = (0, mappers_1.mapTaskEntityToTask)(constants_1.initialTaskEntity);
    return {
        task: loading
            ? initial
            : ((data === null || data === void 0 ? void 0 : data.findMyTask) && (0, mappers_1.mapTaskEntityToTask)(data.findMyTask)) || initial,
        loading,
    };
};
exports.useFindMyTask = useFindMyTask;
