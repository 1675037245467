"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useViewGroupInMarketplace = void 0;
const client_1 = require("@apollo/client");
const viewGroupInMarketplace_1 = require("../mutations/viewGroupInMarketplace");
const useViewGroupInMarketplace = () => {
    const [view, { loading }] = (0, client_1.useMutation)(viewGroupInMarketplace_1.VIEW_GROUP_IN_MARKETPLACE);
    const viewGroupInMarketplace = (groupId, viewInMarketplace) => __awaiter(void 0, void 0, void 0, function* () {
        return yield view({
            variables: {
                groupId,
                data: {
                    viewInMarketplace,
                },
            },
            update: (cache, { data }) => {
                if (data === null || data === void 0 ? void 0 : data.viewGroupInMarketplace) {
                    updateGroupInCache(cache, data.viewGroupInMarketplace.id, data.viewGroupInMarketplace.viewInMarketplace);
                }
            },
        });
    });
    const updateGroupInCache = (cache, id, viewInMarketplace) => {
        const groupToUpdate = {
            id: id,
            __typename: "GroupSettingsEntity",
        };
        cache.modify({
            id: cache.identify(groupToUpdate),
            fields: {
                viewInMarketplace() {
                    return viewInMarketplace;
                },
            },
        });
    };
    return { viewGroupInMarketplace, loading };
};
exports.useViewGroupInMarketplace = useViewGroupInMarketplace;
