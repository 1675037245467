"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateTeamName = exports.useSetupDiscordChannelsForTeam = exports.useRevokeTeamApiKey = exports.useRemoveTeamMember = exports.useRemoveTeamHackapoints = exports.useJoinTeamDiscordChannel = exports.useGenerateTeamApiKey = exports.useFindTeamMembers = exports.useFindTeam = exports.useFindPublicGroupTeams = exports.useFindMyTeams = exports.useFindGroupTeams = exports.useDistributeTeamHackapoints = exports.useDiscardTeamDiscordChannels = exports.useDeleteTeam = exports.useCreateNewTeam = exports.useConvertToTeam = exports.useAddTeamMember = exports.useAddTeamHackapoints = void 0;
var useAddTeamHackapoints_1 = require("./useAddTeamHackapoints");
Object.defineProperty(exports, "useAddTeamHackapoints", { enumerable: true, get: function () { return useAddTeamHackapoints_1.useAddTeamHackapoints; } });
var useAddTeamMember_1 = require("./useAddTeamMember");
Object.defineProperty(exports, "useAddTeamMember", { enumerable: true, get: function () { return useAddTeamMember_1.useAddTeamMember; } });
var useConvertToTeam_1 = require("./useConvertToTeam");
Object.defineProperty(exports, "useConvertToTeam", { enumerable: true, get: function () { return useConvertToTeam_1.useConvertToTeam; } });
var useCreateNewTeam_1 = require("./useCreateNewTeam");
Object.defineProperty(exports, "useCreateNewTeam", { enumerable: true, get: function () { return useCreateNewTeam_1.useCreateNewTeam; } });
var useDeleteTeam_1 = require("./useDeleteTeam");
Object.defineProperty(exports, "useDeleteTeam", { enumerable: true, get: function () { return useDeleteTeam_1.useDeleteTeam; } });
var useDiscardTeamDiscordChannels_1 = require("./useDiscardTeamDiscordChannels");
Object.defineProperty(exports, "useDiscardTeamDiscordChannels", { enumerable: true, get: function () { return useDiscardTeamDiscordChannels_1.useDiscardTeamDiscordChannels; } });
var useDistributeTeamHackapoints_1 = require("./useDistributeTeamHackapoints");
Object.defineProperty(exports, "useDistributeTeamHackapoints", { enumerable: true, get: function () { return useDistributeTeamHackapoints_1.useDistributeTeamHackapoints; } });
var useFindGroupTeams_1 = require("./useFindGroupTeams");
Object.defineProperty(exports, "useFindGroupTeams", { enumerable: true, get: function () { return useFindGroupTeams_1.useFindGroupTeams; } });
var useFindMyTeams_1 = require("./useFindMyTeams");
Object.defineProperty(exports, "useFindMyTeams", { enumerable: true, get: function () { return useFindMyTeams_1.useFindMyTeams; } });
var useFindPublicGroupTeams_1 = require("./useFindPublicGroupTeams");
Object.defineProperty(exports, "useFindPublicGroupTeams", { enumerable: true, get: function () { return useFindPublicGroupTeams_1.useFindPublicGroupTeams; } });
var useFindTeam_1 = require("./useFindTeam");
Object.defineProperty(exports, "useFindTeam", { enumerable: true, get: function () { return useFindTeam_1.useFindTeam; } });
var useFindTeamMembers_1 = require("./useFindTeamMembers");
Object.defineProperty(exports, "useFindTeamMembers", { enumerable: true, get: function () { return useFindTeamMembers_1.useFindTeamMembers; } });
var useGenerateTeamApiKey_1 = require("./useGenerateTeamApiKey");
Object.defineProperty(exports, "useGenerateTeamApiKey", { enumerable: true, get: function () { return useGenerateTeamApiKey_1.useGenerateTeamApiKey; } });
var useJoinTeamDiscordChannel_1 = require("./useJoinTeamDiscordChannel");
Object.defineProperty(exports, "useJoinTeamDiscordChannel", { enumerable: true, get: function () { return useJoinTeamDiscordChannel_1.useJoinTeamDiscordChannel; } });
var useRemoveTeamHackapoints_1 = require("./useRemoveTeamHackapoints");
Object.defineProperty(exports, "useRemoveTeamHackapoints", { enumerable: true, get: function () { return useRemoveTeamHackapoints_1.useRemoveTeamHackapoints; } });
var useRemoveTeamMember_1 = require("./useRemoveTeamMember");
Object.defineProperty(exports, "useRemoveTeamMember", { enumerable: true, get: function () { return useRemoveTeamMember_1.useRemoveTeamMember; } });
var useRevokeTeamApiKey_1 = require("./useRevokeTeamApiKey");
Object.defineProperty(exports, "useRevokeTeamApiKey", { enumerable: true, get: function () { return useRevokeTeamApiKey_1.useRevokeTeamApiKey; } });
var useSetupDiscordChannelsForTeam_1 = require("./useSetupDiscordChannelsForTeam");
Object.defineProperty(exports, "useSetupDiscordChannelsForTeam", { enumerable: true, get: function () { return useSetupDiscordChannelsForTeam_1.useSetupDiscordChannelsForTeam; } });
var useUpdateTeamName_1 = require("./useUpdateTeamName");
Object.defineProperty(exports, "useUpdateTeamName", { enumerable: true, get: function () { return useUpdateTeamName_1.useUpdateTeamName; } });
