"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_TASK_TEXT_SOLUTION = void 0;
const client_1 = require("@apollo/client");
exports.FIND_TASK_TEXT_SOLUTION = (0, client_1.gql) `
  query FindTaskTextSolution($taskId: String!) {
    findTaskTextSolution(taskId: $taskId) {
      id
      solutionId
    }
  }
`;
