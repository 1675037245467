"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_PROJECT_SCHEDULE_STATE = void 0;
const client_1 = require("@apollo/client");
exports.SET_PROJECT_SCHEDULE_STATE = (0, client_1.gql) `
  mutation SetProjectScheduleState($id: String!, $state: SchedulingState!) {
    setProjectScheduleState(id: $id, state: $state) {
      id
      state
      maxCompletionTimeHours
    }
  }
`;
