"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TASK_REVIEW_UPDATED = void 0;
const client_1 = require("@apollo/client");
exports.TASK_REVIEW_UPDATED = (0, client_1.gql) `
  subscription TaskReviewUpdated($taskId: String!) {
    taskReviewUpdated(taskId: $taskId) {
      id
      reviewState
      reviewRequests
    }
  }
`;
