"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_TASK_COMMENTS = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_TASK_COMMENTS = (0, core_1.gql) `
  query FindTaskComments($taskId: String!) {
    findTaskComments(taskId: $taskId) {
      id
      comment
      createdAt
      authorId
      author {
        id
        username
        avatarUrl
      }
    }
  }
`;
