"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetGithubTasksDetails = void 0;
const getTasksGithubDetails_1 = require("../queries/getTasksGithubDetails");
const client_1 = require("@apollo/client");
const useGetGithubTasksDetails = (where = {}) => {
    const { data, loading } = (0, client_1.useQuery)(getTasksGithubDetails_1.GET_TASKS_GITHUB_DETAILS, { variables: { where } });
    return {
        githubTasksDetails: loading ? [] : (data === null || data === void 0 ? void 0 : data.findGithubTasksDetails) || [],
        loading,
    };
};
exports.useGetGithubTasksDetails = useGetGithubTasksDetails;
