"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyRating = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findMyRating_1 = require("../queries/findMyRating");
const ratingUpdated_1 = require("../subscriptions/ratingUpdated");
const useFindMyRating = () => {
    const { data, loading, subscribeToMore } = (0, client_1.useQuery)(findMyRating_1.FIND_MY_RATING);
    const subscribeToRating = (userId) => {
        subscribeToMore({
            document: ratingUpdated_1.RATING_UPDATED,
            variables: { userId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data)
                    return prev;
                const { ratingUpdated } = subscriptionData.data;
                return {
                    findMyRating: Object.assign(Object.assign({}, prev.findMyRating), ratingUpdated),
                };
            },
        });
    };
    return {
        subscribeToRating,
        rating: loading ? constants_1.initialRating : (data === null || data === void 0 ? void 0 : data.findMyRating) || constants_1.initialRating,
    };
};
exports.useFindMyRating = useFindMyRating;
