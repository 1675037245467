"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTaskScheduleWithTasks = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const taskScheduleQueryMapper_1 = require("../mappers/taskScheduleQueryMapper");
const findTaskScheduleWithTasks_1 = require("../queries/findTaskScheduleWithTasks");
const initialMappedTaskSchedule = (0, taskScheduleQueryMapper_1.mapTaskScheduleEntity)(constants_1.initialTaskSchedule);
const useFindTaskScheduleWithTasks = (id) => {
    const { data, loading } = (0, client_1.useQuery)(findTaskScheduleWithTasks_1.FIND_TASK_SCHEDULE_WITH_TASKS, {
        variables: {
            id,
        },
        fetchPolicy: "cache-first",
    });
    return {
        taskSchedule: loading
            ? initialMappedTaskSchedule
            : ((data === null || data === void 0 ? void 0 : data.findTaskScheduleWithTasks) &&
                (0, taskScheduleQueryMapper_1.mapTaskScheduleEntity)(data.findTaskScheduleWithTasks)) ||
                initialMappedTaskSchedule,
        loading,
    };
};
exports.useFindTaskScheduleWithTasks = useFindTaskScheduleWithTasks;
