"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDistributeTeamHackapoints = void 0;
const client_1 = require("@apollo/client");
const distributeTeamHackapoints_1 = require("../mutations/distributeTeamHackapoints");
const useDistributeTeamHackapoints = () => {
    const [distributeTeamHackapoints, { data, loading, error }] = (0, client_1.useMutation)(distributeTeamHackapoints_1.DISTRIBUTE_TEAM_HACKAPOINTS);
    return { distributeTeamHackapoints, data, loading, error };
};
exports.useDistributeTeamHackapoints = useDistributeTeamHackapoints;
