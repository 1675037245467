"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetMyLabFeatures = void 0;
const client_1 = require("@apollo/client");
const getMyFeatures_1 = require("../queries/getMyFeatures");
const initialFeature = {
    view: false,
    update: false,
    create: false,
};
const initialFeatures = {
    settings: initialFeature,
    groups: initialFeature,
    billings: initialFeature,
    team: initialFeature,
};
const useGetMyLabFeatures = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(getMyFeatures_1.GET_MY_FEATURES);
    return {
        features: loading
            ? initialFeatures
            : (data === null || data === void 0 ? void 0 : data.getMyFeatures) || initialFeatures,
        error,
        loading,
        refetch,
    };
};
exports.useGetMyLabFeatures = useGetMyLabFeatures;
