"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTeamMembers = void 0;
const client_1 = require("@apollo/client");
const findTeamMembers_1 = require("../queries/findTeamMembers");
const useFindTeamMembers = (teamId) => {
    const { data, loading, error } = (0, client_1.useQuery)(findTeamMembers_1.FIND_TEAM_MEMBERS, {
        variables: { teamId },
    });
    return {
        members: loading ? [] : (data === null || data === void 0 ? void 0 : data.findTeamMembers) || [],
        loading,
        error,
    };
};
exports.useFindTeamMembers = useFindTeamMembers;
