"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindUserRating = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findUserRating_1 = require("../queries/findUserRating");
const useFindUserRating = (userId) => {
    const { data, loading } = (0, client_1.useQuery)(findUserRating_1.FIND_USER_RATING, {
        variables: { userId },
    });
    return {
        rating: loading ? constants_1.initialRating : (data === null || data === void 0 ? void 0 : data.findUserRating) || constants_1.initialRating,
    };
};
exports.useFindUserRating = useFindUserRating;
