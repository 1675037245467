"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_TASK_TEMPLATE = void 0;
const core_1 = require("@apollo/client/core");
exports.CREATE_TASK_TEMPLATE = (0, core_1.gql) `
  mutation CreateTaskTemplate($lessonTemplateId: String!, $type: TaskType!) {
    createTaskTemplate(lessonTemplateId: $lessonTemplateId, type: $type) {
      id
      title
      orderIndex
      isPublished
      lessonTemplate {
        id
        name
        theme
        projectTemplateId
      }
    }
  }
`;
