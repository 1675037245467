"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPublicGroupTeams = void 0;
const client_1 = require("@apollo/client");
const findPublicGroupTeams_1 = require("../queries/findPublicGroupTeams");
const useFindPublicGroupTeams = (groupId) => {
    const { data, loading, error } = (0, client_1.useQuery)(findPublicGroupTeams_1.FIND_PUBLIC_GROUP_TEAMS, {
        variables: { groupId },
    });
    return {
        teams: loading ? [] : (data === null || data === void 0 ? void 0 : data.findPublicGroupTeams) || [],
        loading,
        error,
    };
};
exports.useFindPublicGroupTeams = useFindPublicGroupTeams;
