"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindGroupAvailability = void 0;
const client_1 = require("@apollo/client");
const findGroupAvailability_1 = require("../queries/findGroupAvailability");
const useFindGroupAvailability = (groupId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findGroupAvailability_1.FIND_GROUP_AVAILABILITY, {
        fetchPolicy: "cache-first",
        pollInterval: 30000,
        variables: { groupId },
    });
    return {
        availability: loading ? null : (data === null || data === void 0 ? void 0 : data.findGroupAvailability) || null,
        error,
        loading,
        refetch,
    };
};
exports.useFindGroupAvailability = useFindGroupAvailability;
