"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyPaymentCards = void 0;
const client_1 = require("@apollo/client");
const findMyPaymentCards_1 = require("../queries/findMyPaymentCards");
const useFindMyPaymentCards = () => {
    const { data, loading } = (0, client_1.useQuery)(findMyPaymentCards_1.FIND_MY_PAYMENT_CARDS, {
        fetchPolicy: "cache-first",
    });
    return {
        cards: loading ? [] : (data === null || data === void 0 ? void 0 : data.findMyPaymentCards) || [],
        loading,
    };
};
exports.useFindMyPaymentCards = useFindMyPaymentCards;
