"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateMyOrgInvoiceDetails = void 0;
const client_1 = require("@apollo/client");
const updateMyOrganisationInvoiceDetails_1 = require("../mutations/updateMyOrganisationInvoiceDetails");
const useUpdateMyOrgInvoiceDetails = () => {
    const [updateExistingOrganisation, { loading, called }] = (0, client_1.useMutation)(updateMyOrganisationInvoiceDetails_1.UPDATE_MY_ORGANISATION_INVOICE_DETAILS);
    const updateInvoiceDetails = (data) => __awaiter(void 0, void 0, void 0, function* () {
        return yield updateExistingOrganisation({
            variables: {
                data,
            },
            update: (cache, { data }) => {
                var _a;
                if ((_a = data === null || data === void 0 ? void 0 : data.updateMyOrganisationInvoiceDetails) === null || _a === void 0 ? void 0 : _a.invoiceDetails) {
                    updateInvoiceDetailsCache(cache, data.updateMyOrganisationInvoiceDetails.invoiceDetails.id, data.updateMyOrganisationInvoiceDetails.invoiceDetails);
                }
            },
        });
    });
    const updateInvoiceDetailsCache = (cache, id, data) => {
        const detailsToUpdate = {
            id,
            __typename: "InvoiceDetailsEntity",
        };
        cache.modify({
            id: cache.identify(detailsToUpdate),
            fields: {
                companyName() {
                    return data.companyName;
                },
                address() {
                    return data.address;
                },
                city() {
                    return data.city;
                },
                country() {
                    return data.country;
                },
                taxNumber() {
                    return data.taxNumber;
                },
            },
        });
    };
    return { updateInvoiceDetails, loading, called };
};
exports.useUpdateMyOrgInvoiceDetails = useUpdateMyOrgInvoiceDetails;
