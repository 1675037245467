"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UN_PUBLISH_TASK_TEMPLATE = void 0;
const client_1 = require("@apollo/client");
exports.UN_PUBLISH_TASK_TEMPLATE = (0, client_1.gql) `
  mutation UnPublishTaskTemplate($id: String!) {
    unPublishTaskTemplate(id: $id) {
      id
    }
  }
`;
