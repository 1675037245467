"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONVERT_PROJECT_SCHEDULE_TO_TEAM = void 0;
const client_1 = require("@apollo/client");
exports.CONVERT_PROJECT_SCHEDULE_TO_TEAM = (0, client_1.gql) `
  mutation ConvertProjectScheduleToTeam($projectScheduleId: String!) {
    convertProjectScheduleToTeam(projectScheduleId: $projectScheduleId) {
      id
    }
  }
`;
