"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_GROUP_AVAILABILITY = void 0;
const client_1 = require("@apollo/client");
exports.FIND_GROUP_AVAILABILITY = (0, client_1.gql) `
  query FindGroupAvailability($groupId: String!) {
    findGroupAvailability(groupId: $groupId) {
      approvedMembers
      maxSeats
    }
  }
`;
