"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_SUBSCRIPTION_ITEM = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_SUBSCRIPTION_ITEM = (0, client_1.gql) `
  query FindMySubscriptionItem {
    findMySubscriptionItem {
      id
      quantity
      price {
        currency
        unit_amount
        recurring {
          interval
        }
      }
    }
  }
`;
