"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PUBLIC_USER = void 0;
const client_1 = require("@apollo/client");
exports.FIND_PUBLIC_USER = (0, client_1.gql) `
  query FindPublicUserByUsername($username: String!) {
    findPublicUserByUsername(username: $username) {
      id
      bio
      avatarUrl
      username
      githubUsername
    }
  }
`;
