"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyBoard = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findMyBoard_1 = require("../queries/findMyBoard");
const useFindMyBoard = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findMyBoard_1.FIND_MY_BOARD);
    return {
        board: loading
            ? constants_1.initialBoardValues
            : (data === null || data === void 0 ? void 0 : data.findMyBoard) || constants_1.initialBoardValues,
        error,
        loading,
        refetch,
    };
};
exports.useFindMyBoard = useFindMyBoard;
