"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublishProjectTemplate = void 0;
const client_1 = require("@apollo/client");
exports.PublishProjectTemplate = (0, client_1.gql) `
  mutation PublishLessonTemplate($projectTemplateId: String!) {
    publishProjectTemplate(projectTemplateId: $projectTemplateId) {
      id
      state
    }
  }
`;
