"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPublicProjectTemplates = void 0;
const client_1 = require("@apollo/client");
const projectTemplateQueryMappers_1 = require("../mappers/projectTemplateQueryMappers");
const findPublicProjectTemplates_1 = require("../queries/findPublicProjectTemplates");
const useFindPublicProjectTemplates = () => {
    const { data, loading, refetch } = (0, client_1.useQuery)(findPublicProjectTemplates_1.FIND_PUBLIC_PROJECTS_TEMPLATES, {
        fetchPolicy: "cache-first", // Used for first execution
    });
    return {
        projectsTemplates: loading
            ? []
            : ((data === null || data === void 0 ? void 0 : data.findPublicProjectTemplates) &&
                data.findPublicProjectTemplates.map(projectTemplateQueryMappers_1.mapProjectTemplateToPublic)) ||
                [],
        loading,
        refetch,
    };
};
exports.useFindPublicProjectTemplates = useFindPublicProjectTemplates;
