"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyTasks = void 0;
const client_1 = require("@apollo/client");
const mappers_1 = require("../mappers");
const findMyTasks_1 = require("../queries/findMyTasks");
const useFindMyTasks = () => {
    const { data, loading, refetch } = (0, client_1.useQuery)(findMyTasks_1.FIND_MY_TASKS, {
        fetchPolicy: "network-only",
    });
    const refetchTasks = () => __awaiter(void 0, void 0, void 0, function* () {
        const { data: { findMyTasks }, } = yield refetch();
        return findMyTasks.map(mappers_1.mapTaskEntityToTask);
    });
    return {
        tasks: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findMyTasks.map((taskDto) => (0, mappers_1.mapTaskEntityToTask)(taskDto))) || [],
        loading,
        refetchTasks,
    };
};
exports.useFindMyTasks = useFindMyTasks;
