"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateTaskTemplateType = void 0;
const client_1 = require("@apollo/client");
const updateTaskTemplateType_1 = require("../mutations/updateTaskTemplateType");
const useUpdateTaskTemplateType = () => {
    const [update, { error, loading }] = (0, client_1.useMutation)(updateTaskTemplateType_1.UPDATE_TASK_TEMPLATE_TYPE);
    const updateTaskTemplateType = (id, type) => __awaiter(void 0, void 0, void 0, function* () {
        return yield update({
            variables: {
                id,
                type,
            },
            optimisticResponse: {
                updateTaskTemplateType: {
                    id,
                    //@ts-ignore
                    __typename: "CodeSolutionConditionEntity",
                    type,
                },
            },
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.updateTaskTemplateType))
                    return;
                updateTaskTemplateCache(cache, id, data.updateTaskTemplateType.type);
            },
        });
    });
    const updateTaskTemplateCache = (cache, taskTemplateId, type) => {
        const taskToUpdate = {
            id: taskTemplateId,
            __typename: "TaskTemplateEntity",
        };
        cache.modify({
            id: cache.identify(taskToUpdate),
            fields: {
                type: (prev) => {
                    return type || prev;
                },
            },
        });
    };
    return {
        updateTaskTemplateType,
        loading,
        error,
    };
};
exports.useUpdateTaskTemplateType = useUpdateTaskTemplateType;
