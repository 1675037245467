"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateDocument = void 0;
const client_1 = require("@apollo/client");
const updateDocument_1 = require("../mutations/updateDocument");
const getDocument_1 = require("../queries/getDocument");
const useUpdateDocument = () => {
    const [updateExistingDocument, { loading, called, client: { cache }, },] = (0, client_1.useMutation)(updateDocument_1.UPDATE_DOCUMENT);
    const updateDocument = (id, content, notionPageId) => __awaiter(void 0, void 0, void 0, function* () {
        yield updateExistingDocument({
            variables: {
                id,
                content,
                notionPageId,
            },
            update: (cache) => {
                const prevDocumentData = cache.readQuery({
                    query: getDocument_1.GET_DOCUMENT,
                    variables: { id },
                });
                if (!prevDocumentData)
                    return;
                updateDocInCache(id, content);
            },
        });
    });
    const updateDocInCache = (id, content) => {
        const prevDocumentData = cache.readQuery({
            query: getDocument_1.GET_DOCUMENT,
            variables: { id },
        });
        if (!prevDocumentData)
            return;
        cache.writeQuery({
            query: getDocument_1.GET_DOCUMENT,
            variables: { id },
            data: {
                findDocument: Object.assign(Object.assign({}, prevDocumentData.findDocument), { content }),
            },
        });
    };
    return { updateDocument, loading, called, updateDocInCache };
};
exports.useUpdateDocument = useUpdateDocument;
