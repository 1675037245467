"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRemoveTeamHackapoints = void 0;
const client_1 = require("@apollo/client");
const removeHackapointsForTeam_1 = require("../mutations/removeHackapointsForTeam");
const useRemoveTeamHackapoints = () => {
    const [remove, { data, loading, error }] = (0, client_1.useMutation)(removeHackapointsForTeam_1.REMOVE_HACKAPOINTS_FOR_TEAM);
    const removeTeamHackapoints = (teamId, hackapoints, reason) => __awaiter(void 0, void 0, void 0, function* () {
        yield remove({
            variables: {
                teamId,
                hackapoints,
                reason,
            },
            update(cache, { data }) {
                cache.modify({
                    id: cache.identify({
                        _type: "TeamEntity",
                        _id: teamId,
                    }),
                    fields: {
                        hackapoints(prev) {
                            return (data === null || data === void 0 ? void 0 : data.addHackapointsForTeam.hackapoints) || prev;
                        },
                    },
                });
            },
        });
    });
    return { removeTeamHackapoints, data, loading, error };
};
exports.useRemoveTeamHackapoints = useRemoveTeamHackapoints;
