"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_USER_TO_GROUP = void 0;
const core_1 = require("@apollo/client/core");
exports.ADD_USER_TO_GROUP = (0, core_1.gql) `
  mutation AddUserToGroup($groupId: String!) {
    addUserToGroup(groupId: $groupId) {
      id
      email
    }
  }
`;
