"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PUBLIC_USER_COMPLETED_PROJECTS_WITH_PROGRESS = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_PUBLIC_USER_COMPLETED_PROJECTS_WITH_PROGRESS = (0, core_1.gql) `
  query FindPublicUserCompletedProjects($userId: String!) {
    findPublicUserCompletedProjects(userId: $userId) {
      progress {
        completedTasksAmount
        scheduledTasksAmount
      }
      project {
        id
        state
        projectScheduleId
        projectTemplateId
        deploymentUrl
        repositoryName
        showInPortfolio
        repositoryOwner
        projectSchedule {
          isPublic
          collaborationType
          lessonSchedules {
            id
            lessonTemplate {
              id
              orderIndex
              name
              briefId
            }
          }
        }
        projectTemplate {
          id
          name
          orderIndex
          theme
          type
          eta
          subtitle
          briefId
          coverImageId
          difficultyLevel
          tags {
            name
          }
        }
      }
    }
  }
`;
