"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateProjectVisibility = void 0;
const client_1 = require("@apollo/client");
const updateProjectVisibilityInPortfolio_1 = require("../mutations/updateProjectVisibilityInPortfolio");
const useUpdateProjectVisibility = () => {
    // Define the useMutation hook with result and variable types
    const [updateProjectVisibility, { loading, called }] = (0, client_1.useMutation)(updateProjectVisibilityInPortfolio_1.UPDATE_PROJECT_VISIBILITY_IN_PORTFOLIO, {
        // Type the update function's parameters
        update: (cache, { data }) => {
            // Make sure to handle the case where data might be undefined
            if (!data)
                return;
            const { updateProjectVisibilityInPortfolio } = data;
            // Type your cache update logic here
            cache.modify({
                fields: {
                    //@ts-ignore
                    projects(existingProjectRefs = [], { readField }) {
                        const newProjectRef = cache.writeFragment({
                            data: updateProjectVisibilityInPortfolio,
                            fragment: (0, client_1.gql) `
                fragment NewProject on ProjectEntity {
                  id
                  showInPortfolio
                }
              `,
                        });
                        return existingProjectRefs.map((ref) => readField("id", ref) === updateProjectVisibilityInPortfolio.id
                            ? newProjectRef
                            : ref);
                    },
                },
            });
        },
    });
    // Type the function that will be returned from your hook
    const updateVisibility = (projectId, showInPortfolio) => __awaiter(void 0, void 0, void 0, function* () {
        // Replace 'any' with the correct mutation result type if needed
        return updateProjectVisibility({
            variables: {
                projectId,
                showInPortfolio,
            },
        });
    });
    return { updateVisibility, loading, called };
};
exports.useUpdateProjectVisibility = useUpdateProjectVisibility;
