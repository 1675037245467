"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_GROUP_MENTOR = void 0;
const client_1 = require("@apollo/client");
exports.SET_GROUP_MENTOR = (0, client_1.gql) `
  mutation SetGroupMentor($groupId: String!, $mentorEmail: String!) {
    setGroupMentor(groupId: $groupId, mentorEmail: $mentorEmail) {
      id
    }
  }
`;
