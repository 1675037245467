"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAssignUserToTask = void 0;
const client_1 = require("@apollo/client");
const assignUserToTask_1 = require("../mutations/assignUserToTask");
const useAssignUserToTask = () => {
    const [assignTo, { loading, called }] = (0, client_1.useMutation)(assignUserToTask_1.ASSIGN_USER_TO_TASK);
    const assignUserToTask = (id, userId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield assignTo({
            variables: {
                id,
                userId,
            },
            update: (cache, { data }) => {
                if (data === null || data === void 0 ? void 0 : data.assignUserToTask) {
                    updateTaskInCache(cache, data.assignUserToTask.id);
                }
            },
        });
    });
    const updateTaskInCache = (cache, id) => {
        const scheduleToUpdate = {
            id,
            __typename: "TaskEntity",
        };
        cache.modify({
            id: cache.identify(scheduleToUpdate),
            fields: {
                user(prev) {
                    return prev;
                },
            },
        });
    };
    return { assignUserToTask, loading, called };
};
exports.useAssignUserToTask = useAssignUserToTask;
