"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCalculateUserProgramProgress = void 0;
const client_1 = require("@apollo/client");
const calculateUserProgramProgress_1 = require("../queries/calculateUserProgramProgress");
const initialProgress = 0;
const useCalculateUserProgramProgress = (userId, programId) => {
    const { data, loading } = (0, client_1.useQuery)(calculateUserProgramProgress_1.CALCULATE_USER_PROGRAM_PROGRESS, { variables: { userId, programId } });
    return {
        programProgressInPercent: loading
            ? initialProgress
            : (data === null || data === void 0 ? void 0 : data.calculateUserProgramProgress.programProgressInPercent) ||
                initialProgress,
    };
};
exports.useCalculateUserProgramProgress = useCalculateUserProgramProgress;
