"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyActiveGroup = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findMyActiveGroup_1 = require("../queries/findMyActiveGroup");
const useFindMyActiveGroup = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findMyActiveGroup_1.FIND_MY_ACTIVE_GROUP, {
        fetchPolicy: "cache-and-network",
    });
    return {
        group: loading ? constants_1.initialGroup : (data === null || data === void 0 ? void 0 : data.findMyActiveGroup) || constants_1.initialGroup,
        error,
        loading,
        refetch,
    };
};
exports.useFindMyActiveGroup = useFindMyActiveGroup;
