"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useActivateProject = void 0;
const client_1 = require("@apollo/client");
const findMyBoard_1 = require("../../board/queries/findMyBoard");
const activateProject_1 = require("../mutations/activateProject");
const findMyActiveProject_1 = require("../queries/findMyActiveProject");
const useActivateProject = () => {
    const [activate, { loading, called }] = (0, client_1.useMutation)(activateProject_1.ACTIVATE_PROJECT);
    const activateProject = (projectId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield activate({
            refetchQueries: [
                { query: findMyBoard_1.FIND_MY_BOARD },
                { query: findMyActiveProject_1.FIND_MY_ACTIVE_PROJECT_WITH_PROGRESS },
                // ...(programId
                //   ? [
                //       {
                //         query: FIND_MY_PROJECTS_WITH_PROGRESS,
                //         variables: {
                //           programId,
                //         },
                //       },
                //     ]
                //   : []),
            ],
            variables: {
                projectId,
            },
        });
    });
    return { activateProject, loading, called };
};
exports.useActivateProject = useActivateProject;
