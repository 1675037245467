"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INIT_PROJECT = void 0;
const client_1 = require("@apollo/client");
exports.INIT_PROJECT = (0, client_1.gql) `
  mutation InitProject($projectScheduleId: String!, $teamId: String) {
    initProject(projectScheduleId: $projectScheduleId, teamId: $teamId) {
      progress {
        completedTasksAmount
        scheduledTasksAmount
      }
      project {
        id
        projectScheduleId
        repositoryName
        repositoryOwner
        lessons {
          id
          lessonScheduleId
        }
        projectTemplate {
          id
          name
          orderIndex
          githubRepoTemplateName
          theme
          type
        }
      }
    }
  }
`;
