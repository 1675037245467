"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGenerateProjectRepo = void 0;
const client_1 = require("@apollo/client");
const react_1 = require("react");
const findOwnedProjectTemplate_1 = require("../queries/findOwnedProjectTemplate");
const generateProjectRepository_1 = require("../queries/generateProjectRepository");
const useGenerateProjectRepo = (projectTemplateId) => {
    const [isGenerating, setIsGenerating] = (0, react_1.useState)(false);
    const [genProjectRepo, { loading: mutationLoading }] = (0, client_1.useMutation)(generateProjectRepository_1.GENERATE_PROJECT_TEMPLATE_REPO);
    const { data: projectData, startPolling, stopPolling, } = (0, client_1.useQuery)(findOwnedProjectTemplate_1.FIND_PROJECT_TEMPLATE, {
        variables: { projectTemplateId },
    });
    (0, react_1.useEffect)(() => {
        var _a;
        if (isGenerating &&
            ((_a = projectData === null || projectData === void 0 ? void 0 : projectData.findOwnedProjectTemplate) === null || _a === void 0 ? void 0 : _a.githubRepoTemplateName)) {
            setIsGenerating(false);
            stopPolling();
        }
    }, [projectData, isGenerating, stopPolling]);
    const generateProjectRepo = (projectTemplateId) => __awaiter(void 0, void 0, void 0, function* () {
        setIsGenerating(true);
        startPolling(2000); // Start polling every 2 seconds
        const { data } = yield genProjectRepo({
            variables: { projectTemplateId },
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.generateProjectRepository))
                    return;
                const projectTemplateToUpdate = {
                    id: projectTemplateId,
                    __typename: "ProjectTemplateEntity",
                };
                cache.modify({
                    id: cache.identify(projectTemplateToUpdate),
                    fields: {
                        githubRepoTemplateName() {
                            return null; // Set to null to indicate pending state
                        },
                    },
                });
            },
        });
        return (data === null || data === void 0 ? void 0 : data.generateProjectRepository) || null;
    });
    return {
        generateProjectRepo,
        loading: mutationLoading || isGenerating,
    };
};
exports.useGenerateProjectRepo = useGenerateProjectRepo;
