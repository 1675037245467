"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserSkills = void 0;
const mappers_1 = require("../mappers");
const queries_1 = require("../queries");
const getUserSkills = (apolloClient) => () => __awaiter(void 0, void 0, void 0, function* () {
    const { data } = yield apolloClient.query({
        query: queries_1.GET_USER_SKILLS,
    });
    return data.getUserSkills
        ? (0, mappers_1.mapUserSkillsToUserSkillsWithPublicUserData)(data.getUserSkills)
        : null;
});
exports.getUserSkills = getUserSkills;
