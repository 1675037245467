"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_GROUP_ACCESSES = void 0;
const client_1 = require("@apollo/client");
// Dix 2
exports.FIND_MY_GROUP_ACCESSES = (0, client_1.gql) `
  query FindMyGroupAccesses {
    findMyGroupAccesses {
      id
      status
      groupId
      group {
        id
        name
        state
        paymentMode
        organisation {
          id
          name
        }
      }
      stripePayment {
        paymentAmount
        checkoutId
        currency
      }
    }
  }
`;
