"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindGroupSettings = void 0;
const client_1 = require("@apollo/client");
const findGroupSettings_1 = require("../queries/findGroupSettings");
const useFindGroupSettings = (groupId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findGroupSettings_1.FIND_GROUP_SETTINGS, {
        variables: { groupId },
        fetchPolicy: "cache-first",
    });
    return {
        settings: loading ? null : (data === null || data === void 0 ? void 0 : data.findGroupSettings) || null,
        error,
        loading,
        refetch,
    };
};
exports.useFindGroupSettings = useFindGroupSettings;
