"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_TASK_TEMPLATE_TYPE = void 0;
const core_1 = require("@apollo/client/core");
exports.UPDATE_TASK_TEMPLATE_TYPE = (0, core_1.gql) `
  mutation updateTaskTemplateType($id: String!, $type: TaskType!) {
    updateTaskTemplateType(id: $id, type: $type) {
      id
      type
    }
  }
`;
