"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_EVALUATION = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_EVALUATION = (0, client_1.gql) `
  mutation CreateEvaluation($data: EvaluationCreateInputData!) {
    createEvaluation(data: $data) {
      id
      rating
      comment
    }
  }
`;
