"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISTRIBUTE_TEAM_HACKAPOINTS = void 0;
const core_1 = require("@apollo/client/core");
exports.DISTRIBUTE_TEAM_HACKAPOINTS = (0, core_1.gql) `
  mutation DistributeTeamHackapoints($teamId: String!) {
    distributeTeamHackapoints(teamId: $teamId) {
      id
    }
  }
`;
