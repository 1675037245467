"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyStudentTask = void 0;
const client_1 = require("@apollo/client");
const mappers_1 = require("../mappers");
const constants_1 = require("../constants");
const findMyStudentTask_1 = require("../queries/findMyStudentTask");
const useFindMyStudentTask = (taskId) => {
    const { data, loading } = (0, client_1.useQuery)(findMyStudentTask_1.FIND_MY_STUDENT_TASK, {
        variables: {
            taskId,
        },
    });
    const initial = (0, mappers_1.mapTaskEntityToTask)(constants_1.initialTaskEntity);
    return {
        task: loading
            ? initial
            : ((data === null || data === void 0 ? void 0 : data.findMyStudentTask) &&
                (0, mappers_1.mapTaskEntityToTask)(data.findMyStudentTask)) ||
                initial,
        loading,
    };
};
exports.useFindMyStudentTask = useFindMyStudentTask;
