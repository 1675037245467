"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_LESSON_TEMPLATE = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_LESSON_TEMPLATE = (0, core_1.gql) `
  query FindLessonTemplate($id: String!) {
    findLessonTemplate(id: $id) {
      id
      name
      theme
      orderIndex
      isPublished
      projectTemplateId
      briefId
      taskTemplates {
        title
        id
      }
    }
  }
`;
