"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GITHUB_CREDENTIALS_UPDATED = void 0;
const client_1 = require("@apollo/client");
exports.GITHUB_CREDENTIALS_UPDATED = (0, client_1.gql) `
  subscription GithubCredentialsUpdated($userId: String!) {
    githubUserCredentialsUpdated(userId: $userId) {
      isOrgMember
    }
  }
`;
