"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_GROUP = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_GROUP = (0, client_1.gql) `
  mutation CreateGroup {
    createGroup {
      id
      name
      state
    }
  }
`;
