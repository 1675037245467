"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_CODE_REVIEW_CONDITIONS = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_CODE_REVIEW_CONDITIONS = (0, core_1.gql) `
  query FindCodeReviewConditions($taskTemplateId: String!) {
    findReviewConditions(taskTemplateId: $taskTemplateId) {
      id
      isOn
      isRequired
      enableAuto
      enableManual
      isDeploymentRequired
    }
  }
`;
