"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResetCodeSolution = void 0;
const client_1 = require("@apollo/client");
const resetCodeSolution_1 = require("../mutations/resetCodeSolution");
const useResetCodeSolution = () => {
    const [reset, { error, loading }] = (0, client_1.useMutation)(resetCodeSolution_1.RESET_CODE_SOLUTION);
    const resetCodeSolution = (taskId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield reset({
            variables: {
                taskId,
            },
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.resetCodeSolution))
                    return;
                const codeSolutionToUpdate = {
                    id: data.resetCodeSolution.id,
                    __typename: "codeTemplateEntity",
                };
                cache.modify({
                    id: cache.identify(codeSolutionToUpdate),
                    fields: {
                        code(prev) {
                            return data.resetCodeSolution.code || prev;
                        },
                    },
                });
            },
        });
    });
    return {
        resetCodeSolution,
        loading,
        error,
    };
};
exports.useResetCodeSolution = useResetCodeSolution;
