"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUEST_GROUP_ACCESS = void 0;
const client_1 = require("@apollo/client");
exports.REQUEST_GROUP_ACCESS = (0, client_1.gql) `
  mutation RequestGroupAccess($groupId: String!, $email: String!) {
    requestGroupAccess(groupId: $groupId, email: $email) {
      id
      email
      groupId
      createdAt
    }
  }
`;
