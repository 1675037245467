"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_TASK_STATE = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_TASK_STATE = (0, client_1.gql) `
  mutation UpdateTaskState($id: String!, $state: ProgressState!) {
    updateTaskState(taskId: $id, state: $state) {
      id
    }
  }
`;
