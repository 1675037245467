"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_HACKATHON_TEAM = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_HACKATHON_TEAM = (0, client_1.gql) `
  mutation CreateHackathonTeam(
    $groupId: String!
    $payload: HackathonTeamFormPayload!
  ) {
    createHackathonTeam(groupId: $groupId, payload: $payload) {
      id
      email
    }
  }
`;
