"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindGroupPageDocument = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../../document/constants");
const findPublicGroupPageDocument_1 = require("../queries/findPublicGroupPageDocument");
const useFindGroupPageDocument = (pageId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findPublicGroupPageDocument_1.GET_PUBLIC_GROUP_PAGE_DOCUMENT, {
        variables: { pageId },
        fetchPolicy: "network-only",
    });
    return {
        document: loading
            ? constants_1.initialDocumentValues
            : (data === null || data === void 0 ? void 0 : data.findPublicGroupPageDocument) || constants_1.initialDocumentValues,
        error,
        loading,
        refetch,
    };
};
exports.useFindGroupPageDocument = useFindGroupPageDocument;
