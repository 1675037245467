"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitProject = void 0;
const client_1 = require("@apollo/client");
const findMyBoard_1 = require("../../board/queries/findMyBoard");
const initProject_1 = require("../mutations/initProject");
const findMyActiveProject_1 = require("../queries/findMyActiveProject");
const useInitProject = () => {
    const [init, { loading, called }] = (0, client_1.useMutation)(initProject_1.INIT_PROJECT);
    const initProject = (projectScheduleId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield init({
            refetchQueries: [
                { query: findMyBoard_1.FIND_MY_BOARD },
                { query: findMyActiveProject_1.FIND_MY_ACTIVE_PROJECT_WITH_PROGRESS },
                // { query: FIND_MY_PROJECTS_WITH_PROGRESS, variables: { programId } },
                // { query: FIND_GROUP_PROJECT_SCHEDULES, variables: { programId } },
                // { query: FIND_INDIVIDUAL_PROJECT_SCHEDULES, variables: { programId } },
            ],
            variables: {
                projectScheduleId,
            },
        });
    });
    return { initProject, loading, called };
};
exports.useInitProject = useInitProject;
