"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublishLessonTemplate = void 0;
const client_1 = require("@apollo/client");
exports.PublishLessonTemplate = (0, client_1.gql) `
  mutation PublishLessonTemplate($lessonTemplateId: String!) {
    publishLessonTemplate(lessonTemplateId: $lessonTemplateId) {
      id
    }
  }
`;
