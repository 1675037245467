"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_NEW_SUBSCRIPTION_FOR_ORG = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_NEW_SUBSCRIPTION_FOR_ORG = (0, client_1.gql) `
  mutation CreateNewSubscriptionForOrg(
    $orgId: String!
    $invoiceDetails: JSONObject!
    $paymentDetails: JSONObject!
  ) {
    createNewSubscriptionForOrg(
      orgId: $orgId
      invoiceDetails: $invoiceDetails
      paymentDetails: $paymentDetails
    ) {
      id
      status
      product {
        id
      }
      latest_invoice {
        id
        payment_intent {
          status
          client_secret
        }
      }
    }
  }
`;
