"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyActiveBoardProgram = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findMyActiveBoardProgram_1 = require("../queries/findMyActiveBoardProgram");
const useFindMyActiveBoardProgram = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findMyActiveBoardProgram_1.FIND_MY_ACTIVE_BOARD_PROGRAM);
    return {
        program: loading
            ? constants_1.initialProgram
            : (data === null || data === void 0 ? void 0 : data.findMyActiveBoardProgram) || constants_1.initialProgram,
        error,
        loading,
        refetch,
    };
};
exports.useFindMyActiveBoardProgram = useFindMyActiveBoardProgram;
