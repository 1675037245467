"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConvertToTeam = void 0;
const client_1 = require("@apollo/client");
const convertProjectScheduleToTeam_1 = require("../mutations/convertProjectScheduleToTeam");
const useConvertToTeam = () => {
    const [convertProjectScheduleToTeam, { data, loading, error }] = (0, client_1.useMutation)(convertProjectScheduleToTeam_1.CONVERT_PROJECT_SCHEDULE_TO_TEAM);
    return { convertProjectScheduleToTeam, data, loading, error };
};
exports.useConvertToTeam = useConvertToTeam;
