"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyTeams = void 0;
const client_1 = require("@apollo/client");
const findMyTeams_1 = require("../queries/findMyTeams");
const useFindMyTeams = () => {
    const { data, loading, error, refetch } = (0, client_1.useQuery)(findMyTeams_1.FIND_MY_TEAMS);
    return {
        teams: loading ? [] : (data === null || data === void 0 ? void 0 : data.findMyTeams) || [],
        loading,
        error,
        refetch,
    };
};
exports.useFindMyTeams = useFindMyTeams;
