"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyActiveGroupAccess = void 0;
const client_1 = require("@apollo/client");
const findMyActiveGroupAccess_1 = require("../queries/findMyActiveGroupAccess");
const useFindMyActiveGroupAccess = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findMyActiveGroupAccess_1.FIND_MY_ACTIVE_GROUP_ACCESS, {
        fetchPolicy: "network-only",
    });
    return {
        groupAccess: loading ? null : (data === null || data === void 0 ? void 0 : data.findMyActiveGroupAccess) || null,
        error,
        loading,
        refetch,
    };
};
exports.useFindMyActiveGroupAccess = useFindMyActiveGroupAccess;
