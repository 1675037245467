"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFinalizeStripeSubscription = void 0;
const client_1 = require("@apollo/client");
const finalizeStripeSubscription_1 = require("../mutations/finalizeStripeSubscription");
const useFinalizeStripeSubscription = () => {
    const [finalizeSubscription, { error, loading, client: { cache }, },] = (0, client_1.useMutation)(finalizeStripeSubscription_1.FINALIZE_STRIPE_SUBSCRIPTION);
    const finalizeSubscriptionPayment = () => __awaiter(void 0, void 0, void 0, function* () {
        const { data, errors } = yield finalizeSubscription({
            update: (_cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.finalizeStripeSubscription))
                    return;
                updateSubscriptionInCache(data.finalizeStripeSubscription.id, data.finalizeStripeSubscription.plan);
            },
        });
        return {
            data,
            errors,
        };
    });
    const updateSubscriptionInCache = (subscriptionId, plan) => {
        const subscriptionToUpdate = {
            id: subscriptionId,
            __typename: "SubscriptionEntity",
        };
        cache.modify({
            id: cache.identify(subscriptionToUpdate),
            fields: {
                plan(prev) {
                    return plan || prev;
                },
            },
        });
    };
    return {
        finalizeSubscriptionPayment,
        loading,
        error,
    };
};
exports.useFinalizeStripeSubscription = useFinalizeStripeSubscription;
