"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialProjectSchedule = void 0;
const constants_1 = require("../project-template/constants");
exports.initialProjectSchedule = {
    id: "",
    lessonSchedules: [],
    programId: "",
    isPublic: false,
    collaborators: [],
    projectTemplate: constants_1.initialProjectTemplate,
    projectTemplateId: "",
    collaborationType: "",
    projects: [],
    state: "",
};
