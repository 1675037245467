"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pairLessonScheduleToLessonScheduleWithLesson = void 0;
const pairLessonScheduleToLessonScheduleWithLesson = (lessonSchedules, lessons) => {
    return lessonSchedules.map((schedule) => {
        const matchedLesson = lessons.filter((lesson) => lesson.lessonScheduleId === schedule.id);
        return {
            lesson: !!matchedLesson.length ? matchedLesson[0] : null,
            schedule,
        };
    });
};
exports.pairLessonScheduleToLessonScheduleWithLesson = pairLessonScheduleToLessonScheduleWithLesson;
