"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.taskAssigneeMapper = void 0;
const taskAssigneeMapper = (taskQuery) => {
    var _a, _b, _c, _d;
    return {
        id: ((_a = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.user) === null || _a === void 0 ? void 0 : _a.id) || "",
        username: ((_b = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.user) === null || _b === void 0 ? void 0 : _b.username) || "",
        email: ((_c = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.user) === null || _c === void 0 ? void 0 : _c.email) || "",
        avatarUrl: ((_d = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.user) === null || _d === void 0 ? void 0 : _d.avatarUrl) || "",
    };
};
exports.taskAssigneeMapper = taskAssigneeMapper;
