"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_MEMBERS_TO_GROUP = void 0;
const client_1 = require("@apollo/client");
exports.ADD_MEMBERS_TO_GROUP = (0, client_1.gql) `
  mutation AddMembersToGroup($groupId: String!, $emails: [String!]!) {
    addMembersToGroup(groupId: $groupId, emails: $emails) {
      id
      email
      status
      groupId
    }
  }
`;
