"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_GITHUB_ORG_CREDENTIALS = void 0;
const client_1 = require("@apollo/client");
exports.GET_GITHUB_ORG_CREDENTIALS = (0, client_1.gql) `
  query FindMyOrganisationGithubCredentials {
    findMyOrganisationGithubCredentials {
      id
      installationId
      orgName
    }
  }
`;
