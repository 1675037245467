"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindProjectScore = void 0;
const client_1 = require("@apollo/client");
const findProjectScore_1 = require("../queries/findProjectScore");
const useFindProjectScore = (projectId) => {
    var _a;
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findProjectScore_1.FIND_PROJECT_SCORE, {
        variables: { projectId },
    });
    return {
        score: loading ? null : (_a = data === null || data === void 0 ? void 0 : data.findProjectScore) !== null && _a !== void 0 ? _a : null,
        error,
        loading,
        refetch,
    };
};
exports.useFindProjectScore = useFindProjectScore;
