"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_LESSON_SCHEDULES_BY_PROJECT_SCHEDULE_ID = void 0;
const client_1 = require("@apollo/client");
exports.FIND_LESSON_SCHEDULES_BY_PROJECT_SCHEDULE_ID = (0, client_1.gql) `
  query FindProjectScheduleLessonSchedules($projectScheduleId: String!) {
    findProjectScheduleLessonSchedules(projectScheduleId: $projectScheduleId) {
      id
      taskSchedules {
        id
      }
      lessonTemplate {
        id
        name
        theme
        orderIndex
        isPublished
        projectTemplateId
        briefId
      }
    }
  }
`;
