"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTaskCodeSolution = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findTaskCodeSolution_1 = require("../queries/findTaskCodeSolution");
const taskCodeSolutionUpdated_1 = require("../subscriptions/taskCodeSolutionUpdated");
const useFindTaskCodeSolution = (taskId) => {
    const { data, loading, subscribeToMore } = (0, client_1.useQuery)(findTaskCodeSolution_1.FIND_TASK_CODE_SOLUTION, {
        fetchPolicy: "network-only",
        variables: {
            taskId,
        },
    });
    const subscribeToCodeSolutionUpdate = (taskId) => {
        subscribeToMore({
            document: taskCodeSolutionUpdated_1.TASK_CODE_SOLUTION_UPDATED,
            variables: { taskId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data)
                    return prev;
                const { codeSolutionUpdated } = subscriptionData.data;
                return {
                    findTaskCodeSolution: Object.assign(Object.assign({}, prev.findTaskCodeSolution), { code: codeSolutionUpdated.code }),
                };
            },
        });
    };
    return {
        codeSolution: loading
            ? constants_1.initialCodeSolution
            : ((data === null || data === void 0 ? void 0 : data.findTaskCodeSolution) && data.findTaskCodeSolution) ||
                constants_1.initialCodeSolution,
        loading,
        subscribeToCodeSolutionUpdate,
    };
};
exports.useFindTaskCodeSolution = useFindTaskCodeSolution;
