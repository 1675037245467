"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyOrgStripeAccount = void 0;
const client_1 = require("@apollo/client");
const findMyOrgStripeAccount_1 = require("../queries/findMyOrgStripeAccount");
const useFindMyOrgStripeAccount = () => {
    var _a;
    const { data, error, loading } = (0, client_1.useQuery)(findMyOrgStripeAccount_1.FIND_MY_ORG_STRIPE_ACCOUNT, {
        pollInterval: 30000,
    });
    return {
        account: loading ? null : (data === null || data === void 0 ? void 0 : data.findMyOrgStripeAccount) || null,
        isStripeConnected: (_a = data === null || data === void 0 ? void 0 : data.findMyOrgStripeAccount) === null || _a === void 0 ? void 0 : _a.detailsSubmitted,
        error,
        loading,
    };
};
exports.useFindMyOrgStripeAccount = useFindMyOrgStripeAccount;
