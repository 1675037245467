"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_MY_NOTIFICATIONS = void 0;
const client_1 = require("@apollo/client");
exports.GET_MY_NOTIFICATIONS = (0, client_1.gql) `
  query FindMyNotifications($offset: Int) {
    findMyNotifications(limit: 10, offset: $offset) {
      id
      message
      createdAt
      isRead
      variables
    }
  }
`;
