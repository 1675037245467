"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateProjectTemplatesOrder = void 0;
const client_1 = require("@apollo/client");
const updateProjectTemplates_1 = require("../mutations/updateProjectTemplates");
const findProjectTemplates_1 = require("../queries/findProjectTemplates");
const useUpdateProjectTemplatesOrder = () => {
    const [updateExistingProjectsOrder] = (0, client_1.useMutation)(updateProjectTemplates_1.UPDATE_PROJECT_TEMPLATES);
    const reorderProjectTemplates = (projects) => {
        const mappedProjectsToUpdate = projects.map((element) => ({
            id: element.id,
            orderIndex: element.orderIndex,
        }));
        updateExistingProjectsOrder({
            variables: { data: mappedProjectsToUpdate },
            optimisticResponse: {
                __typename: "Mutation",
                updateProjectTemplates: mappedProjectsToUpdate,
            },
            update: (cache) => {
                updateProjectTemplatesOrderInCache(cache, projects);
            },
        });
    };
    const updateProjectTemplatesOrderInCache = (cache, projectTemplates) => {
        cache.writeQuery({
            query: findProjectTemplates_1.FIND_MY_PROJECTS_TEMPLATES,
            data: {
                findProjectTemplates: projectTemplates,
            },
        });
    };
    return {
        reorderProjectTemplates,
    };
};
exports.useUpdateProjectTemplatesOrder = useUpdateProjectTemplatesOrder;
