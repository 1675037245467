"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyActiveProject = void 0;
const client_1 = require("@apollo/client");
const __1 = require("../..");
const constants_1 = require("../constants");
const findMyActiveProject_1 = require("../queries/findMyActiveProject");
const projectUpdated_1 = require("../subscriptions/projectUpdated");
const useFindMyActiveProject = () => {
    const { data, error, loading, refetch, subscribeToMore, client } = (0, client_1.useQuery)(findMyActiveProject_1.FIND_MY_ACTIVE_PROJECT_WITH_PROGRESS);
    const subscribeToProjectUpdate = (projectId) => {
        subscribeToMore({
            document: projectUpdated_1.PROJECT_UPDATED,
            variables: { projectId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data)
                    return prev;
                const { projectUpdated } = subscriptionData.data;
                const findMyActiveProjectQuery = client.cache.readQuery({
                    query: findMyActiveProject_1.FIND_MY_ACTIVE_PROJECT_WITH_PROGRESS,
                });
                if (!(findMyActiveProjectQuery === null || findMyActiveProjectQuery === void 0 ? void 0 : findMyActiveProjectQuery.findMyActiveProject))
                    return prev;
                const { findMyActiveProject } = findMyActiveProjectQuery;
                return {
                    findMyActiveProject: Object.assign(Object.assign({}, findMyActiveProject), { project: Object.assign(Object.assign({}, findMyActiveProject.project), { state: projectUpdated.project.state }) }),
                };
            },
        });
    };
    return {
        project: loading
            ? constants_1.initialProjectValue
            : ((data === null || data === void 0 ? void 0 : data.findMyActiveProject) &&
                (0, __1.mapProjectWithProgressToProject)(data.findMyActiveProject)) ||
                constants_1.initialProjectValue,
        subscribeToProjectUpdate,
        error,
        loading,
        refetch,
    };
};
exports.useFindMyActiveProject = useFindMyActiveProject;
