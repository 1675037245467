"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialRating = void 0;
exports.initialRating = {
    id: "",
    level: 0,
    experienceToNextLevel: 0,
    experiencePoints: 0,
    hackapoints: 0,
    hackathonsWon: 0,
    isLevelUp: false,
    isRankUp: false,
    rank: "",
    createdAt: "",
    updatedAt: "",
    userId: "",
};
