"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_AI_SOLUTION = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_AI_SOLUTION = (0, client_1.gql) `
  mutation UpdateTaskAiSolution($taskId: String!, $url: String!) {
    updateTaskAiSolution(taskId: $taskId, url: $url) {
      id
      url
      fileId
    }
  }
`;
