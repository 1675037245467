"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_USER_RATING = void 0;
const client_1 = require("@apollo/client");
exports.FIND_USER_RATING = (0, client_1.gql) `
  query FindUserRating($userId: String!) {
    findUserRating(userId: $userId) {
      id
      isLevelUp
      isRankUp
      experiencePoints
      experienceToNextLevel
      level
      rank
    }
  }
`;
