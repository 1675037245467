"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPublicProjectTemplate = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findPublicProjectTemplate_1 = require("../queries/findPublicProjectTemplate");
const useFindPublicProjectTemplate = (projectTemplateId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findPublicProjectTemplate_1.FIND_PUBLIC_PROJECT_TEMPLATE, {
        variables: { projectTemplateId },
        fetchPolicy: "cache-first",
    });
    return {
        projectTemplate: loading
            ? constants_1.initialProjectTemplate
            : (data === null || data === void 0 ? void 0 : data.findPublicProjectTemplate) || constants_1.initialProjectTemplate,
        loading,
        refetch,
        error,
    };
};
exports.useFindPublicProjectTemplate = useFindPublicProjectTemplate;
