"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateUpsell = void 0;
const client_1 = require("@apollo/client");
const createUpsell_1 = require("../mutations/createUpsell");
const findGroupUpsells_1 = require("../mutations/findGroupUpsells");
const useCreateUpsell = (groupId) => {
    const [createNewUpsell, { loading, called, client: { cache }, },] = (0, client_1.useMutation)(createUpsell_1.CREATE_UPSELL);
    const createUpsell = (upsoldGroupId, groupSettingsId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const response = yield createNewUpsell({
            variables: { groupId: upsoldGroupId, groupSettingsId },
            update: (_cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.createUpsell))
                    return;
                updateUpsellsInCache(cache, groupId, data.createUpsell);
            },
        });
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.createUpsell;
    });
    const updateUpsellsInCache = (cache, groupId, createdUpsell) => {
        const { findGroupUpsells } = cache.readQuery({
            query: findGroupUpsells_1.FIND_GROUP_UPSELLS,
            variables: { groupId },
        });
        cache.writeQuery({
            query: findGroupUpsells_1.FIND_GROUP_UPSELLS,
            variables: { groupId },
            data: {
                findGroupUpsells: [...findGroupUpsells, createdUpsell],
            },
        });
    };
    return { createUpsell, loading, called };
};
exports.useCreateUpsell = useCreateUpsell;
