"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_EDITABLE_GROUP_PROGRAM_EVALUATIONS = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_EDITABLE_GROUP_PROGRAM_EVALUATIONS = (0, core_1.gql) `
  query FindPublicProgramEvaluations($groupId: String!) {
    findEditableProgramEvaluations(groupId: $groupId) {
      id
      author
      authorAvatarSrc
      authorGithubUsername
      rating
      projectName
      comment
      isPublic
    }
  }
`;
