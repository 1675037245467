"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_ORG_STRIPE_ACCOUNT = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_ORG_STRIPE_ACCOUNT = (0, client_1.gql) `
  query FindMyOrgStripeAccount {
    findMyOrgStripeAccount {
      id
      detailsSubmitted
    }
  }
`;
