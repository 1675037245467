"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SPEND_COINS = void 0;
const client_1 = require("@apollo/client");
exports.SPEND_COINS = (0, client_1.gql) `
  mutation SpendCoins(
    $userId: String!
    $coinsAmount: Int!
    $channel: CoinTransactionChannel!
  ) {
    useCoins(
      input: { userId: $userId, coinsAmount: $coinsAmount, channel: $channel }
    ) {
      amount
      channel
      createdAt
    }
  }
`;
