"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PUBLIC_GROUP_TEAMS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_PUBLIC_GROUP_TEAMS = (0, client_1.gql) `
  query FindPublicGroupTeams($groupId: String!) {
    findPublicGroupTeams(groupId: $groupId) {
      id
      name
      isSecured
    }
  }
`;
