"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindGroupUpsells = void 0;
const client_1 = require("@apollo/client");
const findGroupUpsells_1 = require("../mutations/findGroupUpsells");
const useFindGroupUpsells = (groupId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findGroupUpsells_1.FIND_GROUP_UPSELLS, {
        variables: { groupId },
        fetchPolicy: "cache-first",
    });
    return {
        upsells: loading ? [] : (data === null || data === void 0 ? void 0 : data.findGroupUpsells) || [],
        error,
        loading,
        refetch,
    };
};
exports.useFindGroupUpsells = useFindGroupUpsells;
