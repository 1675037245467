"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteTag = void 0;
const client_1 = require("@apollo/client");
const deleteTag_1 = require("../mutations/deleteTag");
const useDeleteTag = () => {
    const [deleteOneTag, { loading, called }] = (0, client_1.useMutation)(deleteTag_1.DELETE_TAG);
    const deleteTag = (id) => __awaiter(void 0, void 0, void 0, function* () {
        return yield deleteOneTag({
            variables: {
                id,
            },
            update: (cache, { data }) => {
                if (data) {
                    cache.modify({
                        fields: {
                            findGroupTags(existingTags = [], { readField }) {
                                return existingTags.filter((tagRef) => id !== readField("id", tagRef));
                            },
                        },
                    });
                }
            },
        });
    });
    return { deleteTag, loading, called };
};
exports.useDeleteTag = useDeleteTag;
