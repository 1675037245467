"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyLessons = void 0;
const client_1 = require("@apollo/client");
const __1 = require("../..");
const findMyLessonsWithProgress_1 = require("../queries/findMyLessonsWithProgress");
const useFindMyLessons = (projectId) => {
    const { data, error, loading } = (0, client_1.useQuery)(findMyLessonsWithProgress_1.FIND_MY_LESSONS_WITH_PROGRESS, {
        variables: { projectId },
        fetchPolicy: "network-only",
    });
    return {
        lessons: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findMyLessons.map(__1.mapLessonEntityToLesson)) || [],
        error,
        loading,
    };
};
exports.useFindMyLessons = useFindMyLessons;
