"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRemoveGithubOrgInstallation = void 0;
const client_1 = require("@apollo/client");
const getGithubOrgCredentials_1 = require("../../github/queries/getGithubOrgCredentials");
const removeGithubOrgInstallation_1 = require("../mutations/removeGithubOrgInstallation");
const useRemoveGithubOrgInstallation = () => {
    const [remove, { loading, called }] = (0, client_1.useMutation)(removeGithubOrgInstallation_1.REMOVE_ORG_GITHUB_INSTALLATION);
    const removeAppInstallation = () => __awaiter(void 0, void 0, void 0, function* () {
        return yield remove({
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.removeGithubOrgInstallation))
                    return;
                const { findMyOrganisationGithubCredentials } = cache.readQuery({
                    query: getGithubOrgCredentials_1.GET_GITHUB_ORG_CREDENTIALS,
                });
                cache.writeQuery({
                    query: getGithubOrgCredentials_1.GET_GITHUB_ORG_CREDENTIALS,
                    data: {
                        findMyOrganisationGithubCredentials: Object.assign(Object.assign({}, findMyOrganisationGithubCredentials), { installationId: "" }),
                    },
                });
            },
        });
    });
    return { removeAppInstallation, loading, called };
};
exports.useRemoveGithubOrgInstallation = useRemoveGithubOrgInstallation;
