"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_USER_PROJECT_TEMPLATE_EVALUATION = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_USER_PROJECT_TEMPLATE_EVALUATION = (0, core_1.gql) `
  query FindUserProjectTemplateEvaluation($projectTemplateId: String!) {
    findUserProjectTemplateEvaluation(projectTemplateId: $projectTemplateId) {
      id
      rating
      comment
    }
  }
`;
