"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INIT_ORG_STRIPE_CONNECT = void 0;
const client_1 = require("@apollo/client");
exports.INIT_ORG_STRIPE_CONNECT = (0, client_1.gql) `
  mutation InitOrgStripeConnect {
    initOrgStripeConnect {
      url
    }
  }
`;
