"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTaskProject = void 0;
const client_1 = require("@apollo/client");
const __1 = require("../..");
const constants_1 = require("../constants");
const findTaskProject_1 = require("../queries/findTaskProject");
const useFindTaskProject = (taskId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findTaskProject_1.FIND_TASK_PROJECT, {
        variables: { taskId },
        fetchPolicy: "cache-first",
    });
    return {
        project: loading
            ? constants_1.initialProjectValue
            : ((data === null || data === void 0 ? void 0 : data.findTaskProject) &&
                (0, __1.mapProjectWithProgressToProject)(data.findTaskProject)) ||
                constants_1.initialProjectValue,
        error,
        loading,
        refetch,
    };
};
exports.useFindTaskProject = useFindTaskProject;
