"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapProjectTemplateToOwned = exports.mapProjectTemplateToPublic = void 0;
const mapProjectTemplateToPublic = (project) => {
    if (!project.organisation) {
        throw Error("Organisation not included");
    }
    if (!project.lessonTemplates) {
        throw Error("Lesson templates not included");
    }
    return {
        id: project.id,
        author: project.organisation.name || project.organisation.email,
        authorSrc: project.organisation.avatarSrc || "",
        lessonsAmount: project.lessonTemplates.length,
        lessonTemplates: project.lessonTemplates,
        tasksAmount: project.lessonTemplates.reduce((sum, nextTemplate) => {
            if (!nextTemplate._count) {
                throw Error("Lesson template count not included");
            }
            return sum + nextTemplate._count.taskTemplates || 0;
        }, 0),
        name: project.name,
        theme: project.theme,
        tags: project.tags,
        isPublished: project.isPublished,
        type: project.type,
    };
};
exports.mapProjectTemplateToPublic = mapProjectTemplateToPublic;
const mapProjectTemplateToOwned = (project) => {
    if (!project.author) {
        throw Error("Author not included");
    }
    if (!project.lessonTemplates) {
        throw Error("Lesson templates not included");
    }
    if (!project._count) {
        throw Error("Project count not included");
    }
    return {
        id: project.id,
        author: project.author.username || project.author.email,
        authorSrc: project.author.avatarUrl || "",
        lessonsAmount: project.lessonTemplates.length,
        lessonTemplates: project.lessonTemplates,
        isPublished: project.isPublished,
        githubRepoTemplateName: project.githubRepoTemplateName || null,
        state: project.state,
        tags: project.tags,
        isExpGainOn: project.isExpGainOn,
        scheduledProjects: project._count.projectSchedules,
        tasksAmount: project.lessonTemplates.reduce((sum, nextTemplate) => {
            if (!nextTemplate._count) {
                throw Error("Lesson template count not included");
            }
            return sum + nextTemplate._count.taskTemplates || 0;
        }, 0),
        name: project.name,
        theme: project.theme,
        type: project.type,
    };
};
exports.mapProjectTemplateToOwned = mapProjectTemplateToOwned;
