"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_UNRESOLVED_ORG_TASK_REVIEWS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_UNRESOLVED_ORG_TASK_REVIEWS = (0, client_1.gql) `
  query FindUnresolvedOrgTaskReviews {
    findUnresolvedOrgTaskReviews {
      id
      reviewState
      reviewRequestedAt
      task {
        id
        user {
          avatarUrl
          email
        }
        taskSchedule {
          assignee {
            avatarUrl
            email
          }
        }
        taskTemplate {
          title
          lessonTemplate {
            name
            theme
          }
        }
      }
    }
  }
`;
