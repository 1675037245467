"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEND_MEMBER_INVITATION = void 0;
const client_1 = require("@apollo/client");
exports.SEND_MEMBER_INVITATION = (0, client_1.gql) `
  mutation SendGroupMemberInvitation($groupId: String!, $userEmail: String!) {
    sendMemberInvitation(groupId: $groupId, userEmail: $userEmail) {
      id
    }
  }
`;
