"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateNewTeam = void 0;
const client_1 = require("@apollo/client");
const createNewTeam_1 = require("../mutations/createNewTeam");
const findGroupTeams_1 = require("../queries/findGroupTeams");
const useCreateNewTeam = () => {
    const [createNewTeam, { data, loading, error }] = (0, client_1.useMutation)(createNewTeam_1.CREATE_NEW_TEAM);
    const createTeam = (groupId) => __awaiter(void 0, void 0, void 0, function* () {
        yield createNewTeam({
            variables: {
                groupId,
            },
            update(cache, { data }) {
                const { findGroupTeams } = cache.readQuery({
                    query: findGroupTeams_1.FIND_GROUP_TEAMS,
                    variables: {
                        groupId,
                    },
                });
                cache.writeQuery({
                    query: findGroupTeams_1.FIND_GROUP_TEAMS,
                    variables: {
                        groupId,
                    },
                    data: {
                        findGroupTeams: [...findGroupTeams, data === null || data === void 0 ? void 0 : data.createNewTeam],
                    },
                });
            },
        });
    });
    return { createTeam, data, loading, error };
};
exports.useCreateNewTeam = useCreateNewTeam;
