"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindEditableGroupProgramEvaluations = void 0;
const client_1 = require("@apollo/client");
const findEditableProgramEvaluations_1 = require("../queries/findEditableProgramEvaluations");
const useFindEditableGroupProgramEvaluations = (groupId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findEditableProgramEvaluations_1.FIND_EDITABLE_GROUP_PROGRAM_EVALUATIONS, {
        variables: { groupId },
        fetchPolicy: "cache-first",
    });
    return {
        projectEvaluations: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findEditableProgramEvaluations) || [],
        error,
        loading,
        refetch,
    };
};
exports.useFindEditableGroupProgramEvaluations = useFindEditableGroupProgramEvaluations;
