"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_GROUP_PROJECT_SCHEDULES = void 0;
const client_1 = require("@apollo/client");
exports.FIND_GROUP_PROJECT_SCHEDULES = (0, client_1.gql) `
  query FindGroupProjectSchedulesByProgramId($programId: String!) {
    findGroupProjectSchedulesByProgramId(programId: $programId) {
      id
      state
      collaborationType
      maxCompletionTimeHours
      collaborators {
        id
        username
        email
        avatarUrl
      }
      projectTemplate {
        id
        name
        orderIndex
        theme
        type
        briefId
        tags {
          name
        }
      }
      lessonSchedules {
        id
        lessonTemplate {
          id
          orderIndex
          name
          briefId
        }
      }
    }
  }
`;
