"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetGroupAsPrimary = void 0;
const client_1 = require("@apollo/client");
const setGroupAsPrimary_1 = require("../mutations/setGroupAsPrimary");
const useSetGroupAsPrimary = (groupId) => {
    const [setPrimary, { loading, called }] = (0, client_1.useMutation)(setGroupAsPrimary_1.SET_GROUP_AS_PRIMARY);
    const setGroupAsPrimary = (primary) => __awaiter(void 0, void 0, void 0, function* () {
        return yield setPrimary({
            variables: {
                groupId,
                primary,
            },
            optimisticResponse: {
                setGroupAsPrimary: {
                    id: groupId,
                    //@ts-ignore
                    __typename: "GroupSettingsEntity",
                    isPrimaryGroup: primary,
                },
            },
            update: (cache, { data }) => {
                if (data === null || data === void 0 ? void 0 : data.setGroupAsPrimary) {
                    updateGroupInCache(cache, data.setGroupAsPrimary);
                }
            },
        });
    });
    const updateGroupInCache = (cache, data) => {
        const groupSettingsToUpdate = {
            id: groupId,
            __typename: "GroupSettingsEntity",
        };
        cache.modify({
            id: cache.identify(groupSettingsToUpdate),
            fields: {
                isPrimaryGroup(prev) {
                    return data.isPrimaryGroup || prev;
                },
            },
        });
    };
    return { setGroupAsPrimary, loading, called };
};
exports.useSetGroupAsPrimary = useSetGroupAsPrimary;
