"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PROJECT_TEMPLATE_COVER = void 0;
const client_1 = require("@apollo/client");
exports.FIND_PROJECT_TEMPLATE_COVER = (0, client_1.gql) `
  query FindProjectTemplateCover($projectTemplateId: String!) {
    findProjectTemplateCover(projectTemplateId: $projectTemplateId) {
      id
      url
    }
  }
`;
