"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_AI_PUBLIC_CONDITIONS = exports.FIND_AI_CONDITIONS = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_AI_CONDITIONS = (0, core_1.gql) `
  query FindAiConditions($taskTemplateId: String!) {
    findAiConditions(taskTemplateId: $taskTemplateId) {
      id
      goal
      context
      rules
      maxReviewRetries
      inputDataType
      model
      taskTemplateId
    }
  }
`;
exports.FIND_AI_PUBLIC_CONDITIONS = (0, core_1.gql) `
  query FindAiPublicConditions($taskTemplateId: String!) {
    findAiConditions(taskTemplateId: $taskTemplateId) {
      id
      maxReviewRetries
      inputDataType
    }
  }
`;
