"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUnpublishProjectTemplate = void 0;
const client_1 = require("@apollo/client");
const unPublishProjectTemplate_1 = require("../mutations/unPublishProjectTemplate");
const useUnpublishProjectTemplate = () => {
    const [unpublish, { loading, called }] = (0, client_1.useMutation)(unPublishProjectTemplate_1.UnPublishProjectTemplate);
    const unPublishProjectTemplate = (projectTemplateId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield unpublish({
            variables: {
                projectTemplateId,
            },
            update: (cache) => {
                updateProjectTemplateInCache(cache, projectTemplateId);
            },
        });
    });
    const updateProjectTemplateInCache = (cache, projectTemplateId) => {
        const projectTemplateToUpdate = {
            id: projectTemplateId,
            __typename: "ProjectTemplateEntity",
        };
        cache.modify({
            id: cache.identify(projectTemplateToUpdate),
            fields: {
                isPublished() {
                    return false;
                },
            },
        });
    };
    return { unPublishProjectTemplate, loading, called };
};
exports.useUnpublishProjectTemplate = useUnpublishProjectTemplate;
