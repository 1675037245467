"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCalculateMyProgramProgress = void 0;
const client_1 = require("@apollo/client");
const calculateMyProgramProgress_1 = require("../queries/calculateMyProgramProgress");
const progressUpdated_1 = require("../subscriptions/progressUpdated");
const initialProgress = 0;
const useCalculateMyProgramProgress = () => {
    const { data, loading, subscribeToMore } = (0, client_1.useQuery)(calculateMyProgramProgress_1.CALCULATE_MY_PROGRAM_PROGRESS);
    const subscribeToProgramProgress = (userId) => {
        subscribeToMore({
            document: progressUpdated_1.PROGRAM_PROGRESS_UPDATED,
            variables: { userId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data)
                    return prev;
                const { programProgressRecalculated } = subscriptionData.data;
                return {
                    calculateMyProgramProgress: programProgressRecalculated,
                };
            },
        });
    };
    return {
        subscribeToProgramProgress,
        programProgressInPercent: loading
            ? initialProgress
            : (data === null || data === void 0 ? void 0 : data.calculateMyProgramProgress.programProgressInPercent) ||
                initialProgress,
    };
};
exports.useCalculateMyProgramProgress = useCalculateMyProgramProgress;
