"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PUBLIC_GROUP_PAGE_DOCUMENT = void 0;
const client_1 = require("@apollo/client");
exports.GET_PUBLIC_GROUP_PAGE_DOCUMENT = (0, client_1.gql) `
  query FindPublicGroupPageDocument($pageId: String!) {
    findPublicGroupPageDocument(pageId: $pageId) {
      id
      content
    }
  }
`;
