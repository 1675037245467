"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_ORG_MEMBER = void 0;
const client_1 = require("@apollo/client");
exports.ADD_ORG_MEMBER = (0, client_1.gql) `
  mutation ($memberEmail: String!, $memberUsername: String!, $role: Role!) {
    addOrgMember(
      memberEmail: $memberEmail
      memberUsername: $memberUsername
      role: $role
    ) {
      id
      members {
        id
        username
        email
        roles
      }
    }
  }
`;
