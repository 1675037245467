"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useReviewConditions = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const updateReviewConditions_1 = require("../mutations/updateReviewConditions");
const findCodeReviewConditions_1 = require("../queries/findCodeReviewConditions");
const useReviewConditions = (taskTemplateId) => {
    var _a;
    const [updateCodeReview, { loading: isLoading }] = (0, client_1.useMutation)(updateReviewConditions_1.UPDATE_REVIEW_CONDITIONS);
    const reviewConditions = (0, client_1.useQuery)(findCodeReviewConditions_1.FIND_CODE_REVIEW_CONDITIONS, {
        variables: {
            taskTemplateId,
        },
    });
    const updateReviewConditions = (id, conditions) => __awaiter(void 0, void 0, void 0, function* () {
        return yield updateCodeReview({
            variables: {
                id,
                conditions,
            },
            optimisticResponse: {
                updateReviewConditions: Object.assign({ id, 
                    //@ts-ignore
                    __typename: "ReviewConditionEntity" }, conditions),
            },
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.updateReviewConditions))
                    return;
                updateReviewConditionsInCache(cache, id, conditions);
            },
        });
    });
    const updateReviewConditionsInCache = (cache, reviewConditionId, conditions) => {
        const reviewConditionToUpdate = {
            id: reviewConditionId,
            __typename: "ReviewConditionEntity",
        };
        cache.modify({
            id: cache.identify(reviewConditionToUpdate),
            fields: {
                isOn(prev) {
                    return conditions.isOn || prev;
                },
                isRequired(prev) {
                    return conditions.isRequired || prev;
                },
                isDeploymentRequired(prev) {
                    return conditions.isDeploymentRequired || prev;
                },
                enableManual(prev) {
                    return conditions.enableManual || prev;
                },
                enableAuto(prev) {
                    return conditions.enableAuto || prev;
                },
            },
        });
    };
    return {
        isLoading,
        updateReviewConditions,
        reviewConditions: reviewConditions.loading
            ? constants_1.initialReviewCondition
            : ((_a = reviewConditions.data) === null || _a === void 0 ? void 0 : _a.findReviewConditions) || constants_1.initialReviewCondition,
    };
};
exports.useReviewConditions = useReviewConditions;
