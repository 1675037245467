"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateTaskTemplatesOrder = void 0;
const client_1 = require("@apollo/client");
const updateTaskTemplates_1 = require("../mutations/updateTaskTemplates");
const findOwnedLessonTaskTemplates_1 = require("../queries/findOwnedLessonTaskTemplates");
const useUpdateTaskTemplatesOrder = (lessonTemplateId) => {
    const [updateExistingTaskTemplatesOrder] = (0, client_1.useMutation)(updateTaskTemplates_1.UPDATE_TASK_TEMPLATES);
    const reorderTaskTemplates = (taskTemplates) => {
        const mappedTaskTemplatesToUpdate = taskTemplates.map((element) => ({
            id: element.id,
            orderIndex: element.orderIndex,
        }));
        updateExistingTaskTemplatesOrder({
            variables: { data: mappedTaskTemplatesToUpdate },
            optimisticResponse: {
                __typename: "Mutation",
                updateTaskTemplates: mappedTaskTemplatesToUpdate,
            },
            update: (cache) => {
                updateTaskTemplatesOrderInCache(cache, taskTemplates);
            },
        });
    };
    const updateTaskTemplatesOrderInCache = (cache, taskTemplates) => {
        cache.writeQuery({
            query: findOwnedLessonTaskTemplates_1.FIND_OWNED_LESSON_TASK_TEMPLATES,
            variables: { lessonTemplateId },
            data: {
                findOwnedLessonTaskTemplates: taskTemplates,
            },
        });
    };
    return {
        reorderTaskTemplates,
    };
};
exports.useUpdateTaskTemplatesOrder = useUpdateTaskTemplatesOrder;
