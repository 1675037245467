"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindProjectTasks = void 0;
const client_1 = require("@apollo/client");
const mappers_1 = require("../mappers");
const findProjectTasks_1 = require("../queries/findProjectTasks");
const useFindProjectTasks = (projectId) => {
    const { data, loading } = (0, client_1.useQuery)(findProjectTasks_1.FIND_PROJECT_TASKS, {
        fetchPolicy: "network-only",
        variables: {
            projectId,
        },
    });
    return {
        tasks: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findProjectTasks.map((taskDto) => (0, mappers_1.mapTaskEntityToTask)(taskDto))) || [],
        loading,
    };
};
exports.useFindProjectTasks = useFindProjectTasks;
