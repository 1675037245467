"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ASSIGN_USER_TO_TASK = void 0;
const client_1 = require("@apollo/client");
exports.ASSIGN_USER_TO_TASK = (0, client_1.gql) `
  mutation AssignUserToTask($id: String!, $userId: String) {
    assignUserToTask(id: $id, userId: $userId) {
      id
      state
      user {
        id
        username
        avatarUrl
        email
      }
    }
  }
`;
