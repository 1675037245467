"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSyncGroupAccessPaymentByCheckoutId = void 0;
const client_1 = require("@apollo/client");
const syncGroupAccessPaymentByCheckoutId_1 = require("../queries/syncGroupAccessPaymentByCheckoutId");
const useSyncGroupAccessPaymentByCheckoutId = (checkoutId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(syncGroupAccessPaymentByCheckoutId_1.SYNC_GROUP_ACCESS_PAYMENT_BY_CHECKOUT_ID, {
        variables: { checkoutId },
    });
    return {
        access: (data === null || data === void 0 ? void 0 : data.syncGroupAccessPaymentByCheckoutId) || null,
        error,
        loading,
        refetch,
    };
};
exports.useSyncGroupAccessPaymentByCheckoutId = useSyncGroupAccessPaymentByCheckoutId;
