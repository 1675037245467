"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCheckUserGroupAccess = void 0;
const client_1 = require("@apollo/client");
const checkIfUserHasGroupAccess_1 = require("../queries/checkIfUserHasGroupAccess");
const useCheckUserGroupAccess = (groupId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(checkIfUserHasGroupAccess_1.CHECK_IF_USER_HAS_GROUP_ACCESS, {
        variables: { groupId },
    });
    return {
        hasAccess: loading ? false : (data === null || data === void 0 ? void 0 : data.checkIfUserHasGroupAccess) || false,
        error,
        loading,
        refetch,
    };
};
exports.useCheckUserGroupAccess = useCheckUserGroupAccess;
