"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.syncAccessByCheckoutId = void 0;
const queries_1 = require("../queries");
const syncAccessByCheckoutId = (apolloClient) => (lastCheckoutId) => __awaiter(void 0, void 0, void 0, function* () {
    const { data } = yield apolloClient.query({
        query: queries_1.SYNC_GROUP_ACCESS_PAYMENT_BY_CHECKOUT_ID,
        variables: { checkoutId: lastCheckoutId },
    });
    return data.syncGroupAccessPaymentByCheckoutId;
});
exports.syncAccessByCheckoutId = syncAccessByCheckoutId;
