"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitLesson = void 0;
const client_1 = require("@apollo/client");
const initLesson_1 = require("../mutations/initLesson");
const findMyLessonsWithProgress_1 = require("../queries/findMyLessonsWithProgress");
const useInitLesson = () => {
    const [init, { loading, called, client: { cache }, },] = (0, client_1.useMutation)(initLesson_1.INIT_LESSON);
    const initLesson = (lessonScheduleId, projectId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield init({
            variables: {
                lessonScheduleId,
                projectId,
            },
            update: (_cache, { data }) => {
                if (data === null || data === void 0 ? void 0 : data.initLesson) {
                    updateLessonsInCache(projectId, data.initLesson);
                }
            },
        });
    });
    const updateLessonsInCache = (projectId, lesson) => {
        const data = cache.readQuery({
            query: findMyLessonsWithProgress_1.FIND_MY_LESSONS_WITH_PROGRESS,
            variables: { projectId },
        });
        if (!(data === null || data === void 0 ? void 0 : data.findMyLessons))
            return;
        cache.writeQuery({
            query: findMyLessonsWithProgress_1.FIND_MY_LESSONS_WITH_PROGRESS,
            variables: { projectId },
            data: {
                findMyLessons: [...data.findMyLessons, lesson],
            },
        });
    };
    return { initLesson, loading, called };
};
exports.useInitLesson = useInitLesson;
