"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_MY_ORGANISATION_INVOICE_DETAILS = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_MY_ORGANISATION_INVOICE_DETAILS = (0, client_1.gql) `
  mutation ($data: JSONObject!) {
    updateMyOrganisationInvoiceDetails(data: $data) {
      id
      invoiceDetails {
        billingEmail
        city
        companyName
        city
        country
        postalCode
      }
    }
  }
`;
