"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetProjectRepoAsTemplate = void 0;
const client_1 = require("@apollo/client");
const setProjectTemplateRepositoryAsTemplate_1 = require("../mutations/setProjectTemplateRepositoryAsTemplate");
const useSetProjectRepoAsTemplate = () => {
    const [setAsTemplate, { loading }] = (0, client_1.useMutation)(setProjectTemplateRepositoryAsTemplate_1.SET_REPOSITORY_AS_TEMPLATE);
    const setProjectRepoAsTemplate = (projectTemplateId, repoName) => __awaiter(void 0, void 0, void 0, function* () {
        yield setAsTemplate({
            variables: { id: projectTemplateId, repoName },
        });
    });
    return {
        setProjectRepoAsTemplate,
        loading,
    };
};
exports.useSetProjectRepoAsTemplate = useSetProjectRepoAsTemplate;
