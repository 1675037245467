"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindGroupProjectSchedules = void 0;
const client_1 = require("@apollo/client");
const findGroupProjectSchedulesByProgramId_1 = require("../queries/findGroupProjectSchedulesByProgramId");
const useFindGroupProjectSchedules = (programId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findGroupProjectSchedulesByProgramId_1.FIND_GROUP_PROJECT_SCHEDULES, {
        variables: { programId },
        fetchPolicy: "cache-and-network",
    });
    return {
        projectSchedules: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findGroupProjectSchedulesByProgramId) || [],
        loading,
        refetch,
        error,
    };
};
exports.useFindGroupProjectSchedules = useFindGroupProjectSchedules;
