"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateTaskCodeSolution = void 0;
const client_1 = require("@apollo/client");
const updateTaskCodeSolution_1 = require("../mutations/updateTaskCodeSolution");
const useUpdateTaskCodeSolution = () => {
    const [updateCode, { error, loading, client }] = (0, client_1.useMutation)(updateTaskCodeSolution_1.UPDATE_TASK_CODE_SOLUTION);
    const updateCodeSolution = (taskId, code) => __awaiter(void 0, void 0, void 0, function* () {
        return yield updateCode({
            variables: {
                taskId,
                code,
            },
            update: (_cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.updateTaskCodeSolution.id))
                    return;
                updateCodeSolutionInCache(data.updateTaskCodeSolution.id, code);
            },
        });
    });
    const updateCodeSolutionInCache = (codeSolutionId, code) => {
        const codeSolutionToUpdate = {
            id: codeSolutionId,
            __typename: "CodeSolutionEntity",
        };
        client.cache.modify({
            id: client.cache.identify(codeSolutionToUpdate),
            fields: {
                code(prev) {
                    return code || prev;
                },
            },
        });
    };
    return {
        updateCodeSolution,
        loading,
        error,
    };
};
exports.useUpdateTaskCodeSolution = useUpdateTaskCodeSolution;
