"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindProjectTags = void 0;
const client_1 = require("@apollo/client");
const findProjectTags_1 = require("../queries/findProjectTags");
const useFindProjectTags = (projectTemplateId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findProjectTags_1.FIND_PROJECT_TAGS, {
        variables: { projectTemplateId },
        fetchPolicy: "cache-and-network",
    });
    return {
        tags: loading ? [] : (data === null || data === void 0 ? void 0 : data.findProjectTags) || [],
        loading,
        refetch,
        error,
    };
};
exports.useFindProjectTags = useFindProjectTags;
