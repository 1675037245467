"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_GROUP_DISCORD_SETTINGS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_GROUP_DISCORD_SETTINGS = (0, client_1.gql) `
  query FindGroupDiscordSettings($groupId: String!) {
    findGroupDiscordSettings(groupId: $groupId) {
      id
      discordAccessRoleId
      discordChannelId
      discordCategoryId
      discordChannelUrl
    }
  }
`;
