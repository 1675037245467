"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUEST_AI_REVIEW = void 0;
const client_1 = require("@apollo/client");
exports.REQUEST_AI_REVIEW = (0, client_1.gql) `
  mutation RequestAIReview($taskId: String!) {
    requestAiReview(taskId: $taskId) {
      id
      taskReview {
        id
        reviewState
        reviewRequests
      }
    }
  }
`;
