"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_TASKS_GITHUB_DETAILS = void 0;
const core_1 = require("@apollo/client/core");
exports.GET_TASKS_GITHUB_DETAILS = (0, core_1.gql) `
  query GetTasksGithubDetails($where: JSONObject!) {
    findGithubTasksDetails(where: $where) {
      repositoryName
      pullNumber
    }
  }
`;
