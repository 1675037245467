"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_PROJECT_TEMPLATE = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_PROJECT_TEMPLATE = (0, client_1.gql) `
  mutation UpdateProjectTemplate($id: String!, $data: JSONObject!) {
    updateProjectTemplate(input: { where: { id: $id }, data: $data }) {
      id
    }
  }
`;
