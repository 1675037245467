"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DELETE_TASK_TEMPLATE = void 0;
const core_1 = require("@apollo/client/core");
exports.DELETE_TASK_TEMPLATE = (0, core_1.gql) `
  mutation deleteTaskTemplate($id: String!) {
    deleteTaskTemplate(where: { id: $id }) {
      id
      lessonTemplateId
    }
  }
`;
