"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyStudentTasks = void 0;
const client_1 = require("@apollo/client");
const mappers_1 = require("../mappers");
const findMyStudentTasks_1 = require("../queries/findMyStudentTasks");
const useFindMyStudentTasks = (userId, programId) => {
    const { data, loading } = (0, client_1.useQuery)(findMyStudentTasks_1.FIND_MY_STUDENT_TASKS, {
        fetchPolicy: "network-only",
        variables: {
            userId,
            programId,
        },
    });
    return {
        tasks: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findMyStudentTasks.map((taskDto) => (0, mappers_1.mapTaskEntityToTask)(taskDto))) || [],
        loading,
    };
};
exports.useFindMyStudentTasks = useFindMyStudentTasks;
