"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTaskTags = void 0;
const client_1 = require("@apollo/client");
const findTaskTags_1 = require("../queries/findTaskTags");
const useFindTaskTags = (taskTemplateId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findTaskTags_1.FIND_TASK_TAGS, {
        variables: { taskTemplateId },
        fetchPolicy: "cache-and-network",
    });
    return {
        tags: loading ? [] : (data === null || data === void 0 ? void 0 : data.findTaskTags) || [],
        loading,
        refetch,
        error,
    };
};
exports.useFindTaskTags = useFindTaskTags;
