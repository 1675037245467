"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_GROUP_DISCORD_SETTINGS = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_GROUP_DISCORD_SETTINGS = (0, client_1.gql) `
  mutation UpdateGroupDiscordSettings(
    $discordSettingsId: String!
    $data: UpdateGroupDiscordSettingsInput!
  ) {
    updateGroupDiscordSettings(
      discordSettingsId: $discordSettingsId
      data: $data
    ) {
      id
      discordChannelUrl
      discordAccessRoleId
    }
  }
`;
