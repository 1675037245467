"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUnPublishPage = void 0;
const client_1 = require("@apollo/client");
const unpublishPage_1 = require("../mutations/unpublishPage");
const useUnPublishPage = () => {
    const [unpublish, { loading, called, client: { cache }, },] = (0, client_1.useMutation)(unpublishPage_1.UNPUBLISH_PAGE);
    const unpublishPage = (pageId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield unpublish({
            variables: { pageId },
            update: (_cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.unpublishPage))
                    return;
                const pageToUpdate = {
                    id: data.unpublishPage.id,
                    __typename: "GroupPageEntity",
                };
                cache.modify({
                    id: cache.identify(pageToUpdate),
                    fields: {
                        state(prev) {
                            return (data === null || data === void 0 ? void 0 : data.unpublishPage.state) || prev;
                        },
                    },
                });
            },
        });
    });
    return { unpublishPage, loading, called };
};
exports.useUnPublishPage = useUnPublishPage;
