"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APPROVE_ACCESS_REQUEST = void 0;
const client_1 = require("@apollo/client");
exports.APPROVE_ACCESS_REQUEST = (0, client_1.gql) `
  mutation ($accessRequestId: String!) {
    approveAccessRequest(accessRequestId: $accessRequestId) {
      id
      email
      status
    }
  }
`;
