"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_REPOSITORY_AS_TEMPLATE = void 0;
const client_1 = require("@apollo/client");
exports.SET_REPOSITORY_AS_TEMPLATE = (0, client_1.gql) `
  mutation SetProjectTemplateRepositoryAsTemplate(
    $id: String!
    $repoName: String!
  ) {
    setProjectTemplateRepositoryAsTemplate(id: $id, repoName: $repoName) {
      id
    }
  }
`;
