"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_STRIPE_PAYMENT = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_STRIPE_PAYMENT = (0, client_1.gql) `
  mutation CreateStripePayment($params: JSONObject!) {
    createStripePayment(createPaymentParams: $params) {
      client_secret
    }
  }
`;
