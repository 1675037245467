"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INITIATE_HACKATHON_ACCESS_PAYMENT = void 0;
const client_1 = require("@apollo/client");
exports.INITIATE_HACKATHON_ACCESS_PAYMENT = (0, client_1.gql) `
  mutation ($groupId: String!, $email: String!) {
    initiateHackathonPaymentCheckout(groupId: $groupId, email: $email) {
      id
      url
    }
  }
`;
