"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPricingPlans = void 0;
const client_1 = require("@apollo/client");
const findNerdbordProducts_1 = require("../queries/findNerdbordProducts");
const useFindPricingPlans = () => {
    const { data, loading } = (0, client_1.useQuery)(findNerdbordProducts_1.FIND_NERDBORD_SUBSCRIPTIONS, { fetchPolicy: "cache-first" });
    return {
        plans: loading ? [] : (data === null || data === void 0 ? void 0 : data.findSubscriptionsPricing) || [],
        loading,
    };
};
exports.useFindPricingPlans = useFindPricingPlans;
