"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublishTaskTemplate = void 0;
const client_1 = require("@apollo/client");
exports.PublishTaskTemplate = (0, client_1.gql) `
  mutation PublishTaskTemplate($id: String!) {
    publishTaskTemplate(id: $id) {
      id
    }
  }
`;
