"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_CODE_SOLUTION_LANGUAGE = void 0;
const core_1 = require("@apollo/client/core");
exports.UPDATE_CODE_SOLUTION_LANGUAGE = (0, core_1.gql) `
  mutation UpdateCodeSolutionTemplateLanguage(
    $id: String!
    $language: CodeLanguage!
  ) {
    updateCodeSolutionLanguage(id: $id, language: $language) {
      id
      language
    }
  }
`;
