"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRemoveTeamMember = void 0;
const client_1 = require("@apollo/client");
const removeTeamMember_1 = require("../mutations/removeTeamMember");
const findTeamMembers_1 = require("../queries/findTeamMembers");
const useRemoveTeamMember = () => {
    const [removeTeamMember, { data, loading, error }] = (0, client_1.useMutation)(removeTeamMember_1.REMOVE_TEAM_MEMBER);
    const removeMember = (teamId, userId) => __awaiter(void 0, void 0, void 0, function* () {
        yield removeTeamMember({
            variables: {
                teamId,
                userId,
            },
            update(cache) {
                const { findTeamMembers } = cache.readQuery({
                    query: findTeamMembers_1.FIND_TEAM_MEMBERS,
                    variables: { teamId },
                });
                cache.writeQuery({
                    query: findTeamMembers_1.FIND_TEAM_MEMBERS,
                    variables: { teamId },
                    data: {
                        findTeamMembers: findTeamMembers.filter((member) => member.id !== userId),
                    },
                });
            },
        });
    });
    return { removeMember, data, loading, error };
};
exports.useRemoveTeamMember = useRemoveTeamMember;
