"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SYNC_GROUP_ACCESS_PAYMENT = void 0;
const client_1 = require("@apollo/client");
exports.SYNC_GROUP_ACCESS_PAYMENT = (0, client_1.gql) `
  query SyncGroupAccessPayment($groupId: String!, $email: String!) {
    syncGroupAccessPayment(groupId: $groupId, email: $email) {
      id
      status
    }
  }
`;
