"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_ORG_ONBOARDING_COMPLETE = void 0;
const client_1 = require("@apollo/client");
exports.SET_ORG_ONBOARDING_COMPLETE = (0, client_1.gql) `
  mutation SetOrgOnboardingComplete($isOnboardingComplete: Boolean!) {
    setOrgOnboardingComplete(isOnboardingComplete: $isOnboardingComplete) {
      id
      isOnboardingComplete
    }
  }
`;
