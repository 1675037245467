"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DELETE_OWNED_PROJECT_TEMPLATE = void 0;
const core_1 = require("@apollo/client/core");
exports.DELETE_OWNED_PROJECT_TEMPLATE = (0, core_1.gql) `
  mutation DeleteOwnedProject($projectTemplateId: String!) {
    deleteOwnedProjectTemplate(projectTemplateId: $projectTemplateId) {
      id
    }
  }
`;
