"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_GROUP_CANDIDATES = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_GROUP_CANDIDATES = (0, core_1.gql) `
  query FindGroupApplications($groupId: String!) {
    findGroupCandidates(groupId: $groupId) {
      projects {
        id
        projectTemplate {
          id
          name
        }
        projectScore {
          id
          totalScore
          formattedCompletionTime
        }
      }
      user {
        id
        email
        username
        avatarUrl
      }
    }
  }
`;
