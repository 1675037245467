"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SAVE_AND_PUBLISH_PAGE = void 0;
const client_1 = require("@apollo/client");
exports.SAVE_AND_PUBLISH_PAGE = (0, client_1.gql) `
  mutation SaveAndPublishPage($pageId: String!, $data: GroupPageUpdateInput!) {
    saveAndPublishPage(pageId: $pageId, data: $data) {
      id
      slug
      document {
        content
      }
      shortDsc
      state
      termsAndConditionsUrl
      privacyPolicyUrl
      group {
        id
        name
        currency
        pricePerSeat
        state
        settings {
          id
          maxSeats
        }
      }
    }
  }
`;
