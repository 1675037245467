"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetTaskGithubDetails = void 0;
const client_1 = require("@apollo/client");
const getTaskGithubDetails_1 = require("../queries/getTaskGithubDetails");
const githubTaskDetailsUpdated_1 = require("../subscriptions/githubTaskDetailsUpdated");
const initialData = {
    id: "",
    taskId: "",
    merged: false,
    repositoryName: "",
    mentorReviewers: [],
};
const useGetTaskGithubDetails = (taskId) => {
    const { data, loading, error, subscribeToMore } = (0, client_1.useQuery)(getTaskGithubDetails_1.GET_TASK_GITHUB_DETAILS, {
        variables: { taskId },
        fetchPolicy: "network-only",
    });
    const subscribeToGithubTaskDetails = () => {
        subscribeToMore({
            document: githubTaskDetailsUpdated_1.GITHUB_TASK_DETAILS_UPDATED,
            variables: { taskId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data)
                    return prev;
                const { githubDetailsUpdated } = subscriptionData.data;
                return {
                    githubTaskDetails: Object.assign(Object.assign({}, prev.githubTaskDetails), githubDetailsUpdated),
                };
            },
        });
    };
    return {
        githubTaskDetails: loading
            ? initialData
            : (data === null || data === void 0 ? void 0 : data.githubTaskDetails) || initialData,
        loading,
        error,
        subscribeToGithubTaskDetails,
    };
};
exports.useGetTaskGithubDetails = useGetTaskGithubDetails;
