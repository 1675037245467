"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOGGLE_UPSELL_HIGHLIGHT = void 0;
const client_1 = require("@apollo/client");
exports.TOGGLE_UPSELL_HIGHLIGHT = (0, client_1.gql) `
  mutation ToggleUpsellHighlight(
    $groupUpsellId: String!
    $highlighted: Boolean!
  ) {
    toggleUpsellHighlight(
      groupUpsellId: $groupUpsellId
      highlighted: $highlighted
    ) {
      id
      highlighted
    }
  }
`;
