"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChangeOrgMemberRole = void 0;
const client_1 = require("@apollo/client");
const changeOrgMemberRole_1 = require("../mutations/changeOrgMemberRole");
const useChangeOrgMemberRole = () => {
    const [changeOrgMemberRole, { loading, called }] = (0, client_1.useMutation)(changeOrgMemberRole_1.CHANGE_ORG_MEMBER_ROLE);
    const changeMemberRole = (memberEmail, role) => __awaiter(void 0, void 0, void 0, function* () {
        return yield changeOrgMemberRole({
            variables: {
                memberEmail,
                role,
            },
            update: (cache, { data }) => {
                if (data === null || data === void 0 ? void 0 : data.changeOrgMemberRole) {
                    updateUserInCache(cache, data.changeOrgMemberRole.id, data.changeOrgMemberRole.roles);
                }
            },
        });
    });
    const updateUserInCache = (cache, userId, roles) => {
        const userToUpdate = {
            id: userId,
            __typename: "UserEntity",
        };
        cache.modify({
            id: cache.identify(userToUpdate),
            fields: {
                roles() {
                    return roles;
                },
            },
        });
    };
    return { changeMemberRole, loading, called };
};
exports.useChangeOrgMemberRole = useChangeOrgMemberRole;
