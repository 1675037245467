"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_TASK_PROJECT_DEPLOYMENT_URL = void 0;
const core_1 = require("@apollo/client/core");
exports.UPDATE_TASK_PROJECT_DEPLOYMENT_URL = (0, core_1.gql) `
  mutation UpdateTaskProjectDeploymentUrl($taskId: String!, $url: String!) {
    updateTaskProjectDeploymentUrl(taskId: $taskId, url: $url) {
      id
      deploymentUrl
    }
  }
`;
