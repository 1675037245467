"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_BOARD = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_BOARD = (0, client_1.gql) `
  query FindMyBoard {
    findMyBoard {
      id
      activeProgramId
      activeProjectId
      activeProgram {
        id
        groupId
        group {
          id
          name
          state
        }
      }
    }
  }
`;
