"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_NEW_TEAM = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_NEW_TEAM = (0, client_1.gql) `
  mutation CreateNewTeam($groupId: String!) {
    createNewTeam(groupId: $groupId) {
      id
      name
    }
  }
`;
