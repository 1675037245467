"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCodeSolutionTemplate = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const updateCodeSolutionLanguage_1 = require("../mutations/updateCodeSolutionLanguage");
const findCodeSolutionTemplate_1 = require("../queries/findCodeSolutionTemplate");
const useCodeSolutionTemplate = (taskTemplateId) => {
    const { data, loading } = (0, client_1.useQuery)(findCodeSolutionTemplate_1.FIND_CODE_SOLUTION_TEMPLATE, {
        variables: {
            taskTemplateId,
        },
        fetchPolicy: "network-only",
    });
    const [updateLanguage, updateLanguageRequest] = (0, client_1.useMutation)(updateCodeSolutionLanguage_1.UPDATE_CODE_SOLUTION_LANGUAGE);
    const updateCodeSolutionLanguage = (id, language) => __awaiter(void 0, void 0, void 0, function* () {
        return yield updateLanguage({
            variables: {
                id,
                language,
            },
        });
    });
    return {
        codeSolutionTemplate: loading
            ? constants_1.initialCodeSolutionTemplate
            : (data === null || data === void 0 ? void 0 : data.findCodeSolutionTemplate) || constants_1.initialCodeSolutionTemplate,
        updateCodeSolutionLanguage,
        updateLanguageRequest,
    };
};
exports.useCodeSolutionTemplate = useCodeSolutionTemplate;
