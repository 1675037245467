"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PUBLIC_PRIMARY_GROUP = void 0;
const client_1 = require("@apollo/client");
exports.FIND_PUBLIC_PRIMARY_GROUP = (0, client_1.gql) `
  query FindPublicPrimaryGroup {
    findPublicPrimaryGroup {
      id
      name
      state
      currency
      pricePerSeat
      paymentMode
      discordSettings {
        id
        discordChannelUrl
      }
      settings {
        id
        availabilityEndDate
        startAt
        endAt
      }
      page {
        shortDsc
      }
    }
  }
`;
