"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddMembersToGroup = void 0;
const client_1 = require("@apollo/client");
const addMemberToGroup_1 = require("../mutations/addMemberToGroup");
const findGroupAccessRequests_1 = require("../queries/findGroupAccessRequests");
const findMyGroupUsers_1 = require("../queries/findMyGroupUsers");
const useAddMembersToGroup = () => {
    const [addMembers, { loading, called }] = (0, client_1.useMutation)(addMemberToGroup_1.ADD_MEMBERS_TO_GROUP);
    const addMembersToGroup = (groupId, emails) => __awaiter(void 0, void 0, void 0, function* () {
        return yield addMembers({
            variables: {
                groupId,
                emails,
            },
            refetchQueries: [
                { query: findMyGroupUsers_1.FIND_MY_GROUP_USERS, variables: { id: groupId } },
            ],
            update: (cache, { data }) => {
                if (data === null || data === void 0 ? void 0 : data.addMembersToGroup) {
                    updateGroupAccessInCache(cache, groupId, data.addMembersToGroup);
                }
            },
        });
    });
    const updateGroupAccessInCache = (cache, groupId, createdAccess) => {
        const query = cache.readQuery({
            query: findGroupAccessRequests_1.FIND_GROUPS_ACCESS_REQUESTS,
            variables: { groupId },
        });
        cache.writeQuery({
            query: findGroupAccessRequests_1.FIND_GROUPS_ACCESS_REQUESTS,
            variables: { groupId },
            data: {
                findGroupAccessRequests: [
                    ...query.findGroupAccessRequests,
                    createdAccess,
                ],
            },
        });
    };
    return { addMembersToGroup, loading, called };
};
exports.useAddMembersToGroup = useAddMembersToGroup;
