"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPublicProjectLessonTemplates = void 0;
const client_1 = require("@apollo/client");
const findPublicProjectLessonTemplates_1 = require("../queries/findPublicProjectLessonTemplates");
const useFindPublicProjectLessonTemplates = (projectTemplateId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findPublicProjectLessonTemplates_1.FIND_PUBLIC_PROJECT_LESSON_TEMPLATES, {
        variables: { projectTemplateId },
        fetchPolicy: "cache-first", // Used for first execution
    });
    return {
        lessonTemplates: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findPublicProjectLessonTemplates) || [],
        loading,
        refetch,
        error,
    };
};
exports.useFindPublicProjectLessonTemplates = useFindPublicProjectLessonTemplates;
