"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FORCE_APPROVE_TASK_PULL = void 0;
const client_1 = require("@apollo/client");
exports.FORCE_APPROVE_TASK_PULL = (0, client_1.gql) `
  mutation ForceApproveCodeReview($taskId: String!) {
    forceApproveCodeReview(taskId: $taskId) {
      id
      taskReview {
        id
        reviewState
      }
    }
  }
`;
