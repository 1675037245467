"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PROJECT_TASKS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_PROJECT_TASKS = (0, client_1.gql) `
  query FindProjectTasks($projectId: String!) {
    findProjectTasks(projectId: $projectId) {
      id
      state
      orderIndex
      taskReview {
        id
        reviewState
      }
      taskSchedule {
        assignee {
          id
          username
          avatarUrl
          email
        }
      }
      user {
        id
        username
        avatarUrl
        email
      }
      taskTemplate {
        id
        title
        orderIndex
        lessonTemplate {
          id
          name
          theme
          taskTemplates {
            id
          }
        }
      }
    }
  }
`;
