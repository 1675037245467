"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTaskAiSolution = void 0;
const client_1 = require("@apollo/client");
const findTaskAiSolution_1 = require("../queries/findTaskAiSolution");
const useFindTaskAiSolution = (taskId) => {
    const { data, loading, client, subscribeToMore } = (0, client_1.useQuery)(findTaskAiSolution_1.FIND_TASK_AI_SOLUTION, {
        fetchPolicy: "network-only",
        variables: {
            taskId,
        },
    });
    const updateAiSolutionInCache = (newData) => {
        if (!data) {
            return;
        }
        const updatedData = {
            findTaskAiSolution: Object.assign(Object.assign({}, data.findTaskAiSolution), newData),
        };
        client.writeQuery({
            query: findTaskAiSolution_1.FIND_TASK_AI_SOLUTION,
            data: updatedData,
        });
    };
    return {
        aiSolution: loading
            ? null
            : ((data === null || data === void 0 ? void 0 : data.findTaskAiSolution) && data.findTaskAiSolution) || null,
        updateAiSolutionInCache,
        loading,
    };
};
exports.useFindTaskAiSolution = useFindTaskAiSolution;
