"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_LESSONS_WITH_PROGRESS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_LESSONS_WITH_PROGRESS = (0, client_1.gql) `
  query FindMyLessons($projectId: String!) {
    findMyLessons(projectId: $projectId) {
      lesson {
        id
        state
        isVisibleOnBoard
        lessonScheduleId
        lessonTemplate {
          id
          orderIndex
          name
          briefId
        }
      }
      progress {
        completedTasksAmount
        scheduledTasksAmount
      }
    }
  }
`;
