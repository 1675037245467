"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_ORGANISATION = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_ORGANISATION = (0, client_1.gql) `
  query FindMyOrganisation {
    findMyOrganisation {
      id
      name
      email
      avatarSrc
      isOnboardingComplete
      members {
        id
      }
    }
  }
`;
