"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_TEAM_NAME = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_TEAM_NAME = (0, client_1.gql) `
  mutation UpdateTeamName($teamId: String!, $name: String!) {
    updateTeamName(teamId: $teamId, name: $name) {
      id
      name
    }
  }
`;
