"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_ACCESS_PLAN = void 0;
const client_1 = require("@apollo/client");
exports.SET_ACCESS_PLAN = (0, client_1.gql) `
  mutation SetAccessPlan($accessRequestId: String!, $plan: AccessPlan!) {
    setAccessPlan(groupAccessId: $accessRequestId, plan: $plan) {
      id
      email
      status
      plan
    }
  }
`;
