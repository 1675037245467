"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAuthedUser = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const getAuthedUser_1 = require("../queries/getAuthedUser");
const userUpdated_1 = require("../subscriptions/userUpdated");
const useGetAuthedUser = (initialUser) => {
    const { data, error, loading, refetch, subscribeToMore } = (0, client_1.useQuery)(getAuthedUser_1.AUTHED_USER);
    const subscribeToUser = (userId) => {
        subscribeToMore({
            document: userUpdated_1.USER_UPDATED,
            variables: { userId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data)
                    return prev;
                const { userUpdated } = subscriptionData.data;
                return {
                    user: userUpdated,
                };
            },
        });
    };
    return {
        user: loading
            ? initialUser || constants_1.initialUserValues
            : (data === null || data === void 0 ? void 0 : data.user) || constants_1.initialUserValues,
        error,
        loading,
        refetch,
        subscribeToUser,
    };
};
exports.useGetAuthedUser = useGetAuthedUser;
