"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMakePublicProjectTemplate = void 0;
const client_1 = require("@apollo/client");
const makePublicProjectTemplate_1 = require("../mutations/makePublicProjectTemplate");
const useMakePublicProjectTemplate = () => {
    const [makePublic, { loading, called }] = (0, client_1.useMutation)(makePublicProjectTemplate_1.MAKE_PUBLIC_PROJECT_TEMPLATE);
    const showOnMarketplace = (projectTemplateId, viewOnMarketplace) => __awaiter(void 0, void 0, void 0, function* () {
        return yield makePublic({
            variables: {
                projectTemplateId,
                viewOnMarketplace,
            },
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.makePublicProjectTemplate))
                    return;
                const { makePublicProjectTemplate } = data;
                updateProjectTemplateInCache(cache, makePublicProjectTemplate.id, viewOnMarketplace);
            },
        });
    });
    const updateProjectTemplateInCache = (cache, projectTemplateId, viewOnMarketplace) => {
        const projectTemplateToUpdate = {
            id: projectTemplateId,
            __typename: "ProjectTemplateEntity",
        };
        cache.modify({
            id: cache.identify(projectTemplateToUpdate),
            fields: {
                viewOnMarketplace(prev) {
                    return viewOnMarketplace || prev;
                },
            },
        });
    };
    return { showOnMarketplace, loading, called };
};
exports.useMakePublicProjectTemplate = useMakePublicProjectTemplate;
