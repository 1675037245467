"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetAccessPlan = void 0;
const client_1 = require("@apollo/client");
const functions_1 = require("../functions");
const setAccessPlan_1 = require("../mutations/setAccessPlan");
const useSetAccessPlan = () => {
    const [setPlan, { loading, called,
    // client: { cache },
     },] = (0, client_1.useMutation)(setAccessPlan_1.SET_ACCESS_PLAN);
    const setGroupAccessPlan = (accessRequestId, plan) => __awaiter(void 0, void 0, void 0, function* () {
        return yield setPlan({
            variables: { accessRequestId, plan },
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.setAccessPlan))
                    return;
                (0, functions_1.updateGroupAccessPlanInCache)(cache, accessRequestId, data.setAccessPlan.plan);
            },
        });
    });
    return { setGroupAccessPlan, loading, called };
};
exports.useSetAccessPlan = useSetAccessPlan;
