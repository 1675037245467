"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindProjectLessons = void 0;
const client_1 = require("@apollo/client");
const __1 = require("../..");
const findProjectLessonsWithProgress_1 = require("../queries/findProjectLessonsWithProgress");
const useFindProjectLessons = (projectId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findProjectLessonsWithProgress_1.FIND_PROJECT_LESSONS_WITH_PROGRESS, {
        variables: { projectId },
        fetchPolicy: "network-only",
    });
    return {
        lessons: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findProjectLessons.map(__1.mapLessonEntityToLesson)) || [],
        error,
        loading,
        refetch,
    };
};
exports.useFindProjectLessons = useFindProjectLessons;
