"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGitConditions = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const updatePullRequestConditions_1 = require("../mutations/updatePullRequestConditions");
const updateReviewConditions_1 = require("../mutations/updateReviewConditions");
const findCodeReviewConditions_1 = require("../queries/findCodeReviewConditions");
const findPullRequestConditions_1 = require("../queries/findPullRequestConditions");
const useGitConditions = (taskTemplateId) => {
    var _a, _b;
    const [updatePull, updatePRRequest] = (0, client_1.useMutation)(updatePullRequestConditions_1.UPDATE_PULL_CONDITIONS);
    const [updateCodeReview, updateCRRequest] = (0, client_1.useMutation)(updateReviewConditions_1.UPDATE_REVIEW_CONDITIONS);
    const pullConditions = (0, client_1.useQuery)(findPullRequestConditions_1.FIND_PULL_REQUEST_CONDITIONS, {
        variables: {
            taskTemplateId,
        },
    });
    const codeReviewConditions = (0, client_1.useQuery)(findCodeReviewConditions_1.FIND_CODE_REVIEW_CONDITIONS, {
        variables: {
            taskTemplateId,
        },
    });
    const updatePullRequestConditions = (id, conditions) => __awaiter(void 0, void 0, void 0, function* () {
        return yield updatePull({
            variables: {
                id,
                conditions,
            },
            optimisticResponse: {
                updatePullRequestConditions: Object.assign({ id, 
                    //@ts-ignore
                    __typename: "PullConditionEntity" }, conditions),
            },
            update: (_cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.updatePullRequestConditions))
                    return;
                updatePullConditionsInCache(id, conditions);
            },
        });
    });
    const updateCodeReviewConditions = (id, conditions) => __awaiter(void 0, void 0, void 0, function* () {
        return yield updateCodeReview({
            variables: {
                id,
                conditions,
            },
            optimisticResponse: {
                updateReviewConditions: Object.assign({ id, 
                    //@ts-ignore
                    __typename: "ReviewConditionEntity" }, conditions),
            },
            update: (_cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.updateReviewConditions))
                    return;
                updateCodeReviewConditionsInCache(id, conditions);
            },
        });
    });
    const updatePullConditionsInCache = (pullConditionId, conditions) => {
        const { client: { cache }, } = updatePRRequest;
        const pullConditionToUpdate = {
            id: pullConditionId,
            __typename: "PullConditionEntity",
        };
        cache.modify({
            id: cache.identify(pullConditionToUpdate),
            fields: {
                isOn(prev) {
                    return conditions.isOn || prev;
                },
                isMergeRequired(prev) {
                    return conditions.isMergeRequired || prev;
                },
            },
        });
    };
    const updateCodeReviewConditionsInCache = (reviewConditionId, conditions) => {
        const { client: { cache }, } = updatePRRequest;
        const reviewConditionToUpdate = {
            id: reviewConditionId,
            __typename: "ReviewConditionEntity",
        };
        cache.modify({
            id: cache.identify(reviewConditionToUpdate),
            fields: {
                isOn(prev) {
                    return conditions.isOn || prev;
                },
                isRequired(prev) {
                    return conditions.isRequired || prev;
                },
            },
        });
    };
    return {
        updatePRRequest,
        updateCRRequest,
        updatePullRequestConditions,
        updateCodeReviewConditions,
        codeReviewConditions: codeReviewConditions.loading
            ? constants_1.initialReviewCondition
            : ((_a = codeReviewConditions.data) === null || _a === void 0 ? void 0 : _a.findReviewConditions) ||
                constants_1.initialReviewCondition,
        pullRequestConditions: pullConditions.loading
            ? constants_1.initialPullCondition
            : ((_b = pullConditions.data) === null || _b === void 0 ? void 0 : _b.findPullRequestConditions) || constants_1.initialPullCondition,
    };
};
exports.useGitConditions = useGitConditions;
