"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitTeamProject = void 0;
const client_1 = require("@apollo/client");
const initProject_1 = require("../mutations/initProject");
const findTeamProjects_1 = require("../queries/findTeamProjects");
const useInitTeamProject = () => {
    const [init, { loading, called }] = (0, client_1.useMutation)(initProject_1.INIT_PROJECT);
    const initProject = (projectScheduleId, teamId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield init({
            variables: {
                projectScheduleId,
                teamId,
            },
            update: (cache, { data }) => {
                const existingProjects = cache.readQuery({
                    query: findTeamProjects_1.FIND_TEAM_PROJECTS,
                    variables: { teamId },
                });
                if ((data === null || data === void 0 ? void 0 : data.initProject) && (existingProjects === null || existingProjects === void 0 ? void 0 : existingProjects.findTeamProjects)) {
                    cache.writeQuery({
                        query: findTeamProjects_1.FIND_TEAM_PROJECTS,
                        variables: { teamId },
                        data: {
                            findTeamProjects: [
                                ...existingProjects.findTeamProjects,
                                data.initProject,
                            ],
                        },
                    });
                }
            },
        });
    });
    return { initProject, loading, called };
};
exports.useInitTeamProject = useInitTeamProject;
