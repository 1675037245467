"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_GROUP_NAME = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_GROUP_NAME = (0, client_1.gql) `
  mutation UpdateGroupName($groupId: String!, $name: String!) {
    updateGroupName(groupId: $groupId, name: $name) {
      id
      name
    }
  }
`;
