"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTaskAssignee = void 0;
const client_1 = require("@apollo/client");
const taskAssigneeMapper_1 = require("../mappers/taskAssigneeMapper");
const findTaskAssignee_1 = require("../queries/findTaskAssignee");
const useFindTaskAssignee = (taskId) => {
    const { data, loading, refetch } = (0, client_1.useQuery)(findTaskAssignee_1.FIND_TASK_ASSIGNEE, {
        variables: {
            taskId,
        },
        fetchPolicy: "cache-first",
    });
    return {
        assignee: !loading
            ? ((data === null || data === void 0 ? void 0 : data.findMyTask) && (0, taskAssigneeMapper_1.taskAssigneeMapper)(data.findMyTask)) || null
            : null,
        loading,
        refetch,
    };
};
exports.useFindTaskAssignee = useFindTaskAssignee;
