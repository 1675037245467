"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_PULL_CONDITIONS = void 0;
const core_1 = require("@apollo/client/core");
exports.UPDATE_PULL_CONDITIONS = (0, core_1.gql) `
  mutation UpdatePullRequestConditions($id: String!, $conditions: JSONObject!) {
    updatePullRequestConditions(id: $id, conditions: $conditions) {
      id
      isOn
      isMergeRequired
      isRequired
      codeReviewPrompt
    }
  }
`;
