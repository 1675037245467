"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_LESSON_TEMPLATE = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_LESSON_TEMPLATE = (0, client_1.gql) `
  mutation CreateLessonTemplate($projectTemplateId: String!, $theme: Theme!) {
    createLessonTemplate(projectTemplateId: $projectTemplateId, theme: $theme) {
      id
      name
      theme
      isPublished
      orderIndex
      projectTemplateId
      taskTemplates {
        id
      }
    }
  }
`;
