"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_PROJECT_TEMPLATES = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_PROJECT_TEMPLATES = (0, client_1.gql) `
  mutation UpdateProjects($data: [JSONObject!]!) {
    updateProjectTemplates(input: { data: $data }) {
      id
      orderIndex
    }
  }
`;
