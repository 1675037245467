"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.READ_USER_NOTIFICATIONS = void 0;
const client_1 = require("@apollo/client");
exports.READ_USER_NOTIFICATIONS = (0, client_1.gql) `
  mutation ReadUserNotifications {
    readUserNotifications {
      id
    }
  }
`;
