"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_MY_BIO = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_MY_BIO = (0, client_1.gql) `
  mutation UpdateMyBio($bio: String!) {
    updateMyBio(bio: $bio) {
      id
      bio
    }
  }
`;
