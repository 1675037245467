"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_TASK_ASSIGNEE = void 0;
const client_1 = require("@apollo/client");
exports.FIND_TASK_ASSIGNEE = (0, client_1.gql) `
  query FindTaskAssignee($taskId: String!) {
    findMyTask(taskId: $taskId) {
      id
      user {
        id
        username
        avatarUrl
        email
      }
    }
  }
`;
