"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindAvailableProjects = void 0;
const client_1 = require("@apollo/client");
const mappers_1 = require("../mappers");
const findIndividualProjectSchedulesByProgramId_1 = require("../queries/findIndividualProjectSchedulesByProgramId");
const useFindAvailableProjects = () => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findIndividualProjectSchedulesByProgramId_1.FIND_INDIVIDUAL_PROJECT_SCHEDULES, {
        fetchPolicy: "cache-and-network",
    });
    return {
        projectSchedules: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findAvailableProjectsForUser.map(mappers_1.mapProjectSchedule)) || [],
        loading,
        refetch,
        error,
    };
};
exports.useFindAvailableProjects = useFindAvailableProjects;
