"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMySubscription = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findMySubscription_1 = require("../queries/findMySubscription");
const useFindMySubscription = () => {
    const { data, loading } = (0, client_1.useQuery)(findMySubscription_1.FIND_MY_SUBSCRIPTION);
    return {
        subscription: loading
            ? constants_1.initialSubscription
            : (data === null || data === void 0 ? void 0 : data.findMySubscription) || constants_1.initialSubscription,
        loading,
    };
};
exports.useFindMySubscription = useFindMySubscription;
