"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useManageProjectCollaborator = void 0;
const client_1 = require("@apollo/client");
const addProjectCollaborator_1 = require("../mutations/addProjectCollaborator");
const removeProjectCollaborator_1 = require("../mutations/removeProjectCollaborator");
const useManageProjectCollaborator = () => {
    // Define the useMutation hook with result and variable types
    const [addColaborator, addCollaboratorRequest] = (0, client_1.useMutation)(addProjectCollaborator_1.ADD_PROJECT_COLLABORATOR);
    const [removeColaborator, removeCollaboratorRequest] = (0, client_1.useMutation)(removeProjectCollaborator_1.REMOVE_PROJECT_COLLABORATOR);
    const addProjectCollaborator = (projectScheduleId, userId) => __awaiter(void 0, void 0, void 0, function* () {
        yield addColaborator({
            variables: {
                projectScheduleId,
                userId,
            },
        });
    });
    const removeProjectCollaborator = (projectScheduleId, userId) => __awaiter(void 0, void 0, void 0, function* () {
        yield removeColaborator({
            variables: {
                projectScheduleId,
                userId,
            },
        });
    });
    return {
        addProjectCollaborator,
        removeProjectCollaborator,
        loading: removeCollaboratorRequest.loading || addCollaboratorRequest.loading,
    };
};
exports.useManageProjectCollaborator = useManageProjectCollaborator;
