"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PROJECT_WITH_PROGRESS = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_PROJECT_WITH_PROGRESS = (0, core_1.gql) `
  query FindProject($projectId: String!) {
    findProject(projectId: $projectId) {
      progress {
        completedTasksAmount
        scheduledTasksAmount
      }
      project {
        id
        state
        projectScheduleId
        projectSchedule {
          id
          collaborationType
          lessonSchedules {
            id
            lessonTemplate {
              id
              orderIndex
              name
              briefId
            }
          }
        }
        projectTemplate {
          id
          name
          difficultyLevel
          orderIndex
          subtitle
          theme
          type
          briefId
          tags {
            id
            name
          }
        }
      }
    }
  }
`;
