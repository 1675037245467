"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_ACTIVE_PROJECT_WITH_PROGRESS = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_MY_ACTIVE_PROJECT_WITH_PROGRESS = (0, core_1.gql) `
  query FindMyActiveProject {
    findMyActiveProject {
      progress {
        completedTasksAmount
        scheduledTasksAmount
      }
      project {
        id
        state
        projectScheduleId
        teamId
        startedAt
        projectSchedule {
          id
          maxCompletionTimeHours
          collaborationType
          isPublic
          program {
            id
            groupId
          }
          lessonSchedules {
            id
            state
            lessonTemplate {
              id
              theme
              orderIndex
              name
              briefId
            }
          }
        }
        lessons {
          id
        }
        projectTemplate {
          id
          name
          subtitle
          orderIndex
          theme
          type
          tags {
            name
          }
        }
      }
    }
  }
`;
