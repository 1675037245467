"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteLessonTemplate = void 0;
const client_1 = require("@apollo/client");
const countMyOrgContent_1 = require("../../organisation/queries/countMyOrgContent");
const deleteOwnedLessonTemplate_1 = require("../mutations/deleteOwnedLessonTemplate");
const findProjectLessonTemplates_1 = require("../queries/findProjectLessonTemplates");
const useDeleteLessonTemplate = () => {
    const [deleteLessonTemplate, { error, loading }] = (0, client_1.useMutation)(deleteOwnedLessonTemplate_1.DELETE_OWNED_LESSON_TEMPLATE);
    const deleteLesson = (id) => __awaiter(void 0, void 0, void 0, function* () {
        return yield deleteLessonTemplate({
            variables: {
                id,
            },
            update: (cache) => {
                decrementOrgLessonsAmount(cache);
                updateLessonTemplatesInCache(cache, id);
            },
        });
    });
    const decrementOrgLessonsAmount = (cache) => {
        const orgContent = cache.readQuery({
            query: countMyOrgContent_1.MONITOR_CONTENT_AMOUNT,
        });
        if (orgContent) {
            cache.writeQuery({
                query: countMyOrgContent_1.MONITOR_CONTENT_AMOUNT,
                data: {
                    monitorContentAmount: Object.assign(Object.assign({}, orgContent.monitorContentAmount), { lessons: orgContent.monitorContentAmount.lessons - 1 }),
                },
            });
        }
    };
    const updateLessonTemplatesInCache = (cache, lessonTemplateId) => {
        const projectTemplateId = getProjectTemplateIdFromLessonTemplate(cache, lessonTemplateId);
        const { findOwnedProjectLessonTemplates } = cache.readQuery({
            query: findProjectLessonTemplates_1.FIND_OWNED_PROJECT_LESSON_TEMPLATES,
            variables: { projectTemplateId },
        });
        const filteredLessons = findOwnedProjectLessonTemplates.filter((single) => single.id !== lessonTemplateId);
        cache.writeQuery({
            query: findProjectLessonTemplates_1.FIND_OWNED_PROJECT_LESSON_TEMPLATES,
            variables: { projectTemplateId },
            data: {
                findOwnedProjectLessonTemplates: filteredLessons,
            },
        });
    };
    const getProjectTemplateIdFromLessonTemplate = (cache, lessonTemplateId) => {
        const lessonTemplateToUpdate = {
            id: lessonTemplateId,
            __typename: "LessonTemplateEntity",
        };
        const { projectTemplateId } = cache.readFragment({
            id: cache.identify(lessonTemplateToUpdate),
            fragment: (0, client_1.gql) `
        fragment FoundLesson on LessonTemplateEntity {
          projectTemplateId
        }
      `,
        });
        return projectTemplateId;
    };
    return { deleteLesson, loading, error };
};
exports.useDeleteLessonTemplate = useDeleteLessonTemplate;
