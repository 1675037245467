"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VIEW_GROUP_IN_MARKETPLACE = void 0;
const client_1 = require("@apollo/client");
exports.VIEW_GROUP_IN_MARKETPLACE = (0, client_1.gql) `
  mutation ViewGroupInMarketplace($groupId: String!, $data: JSONObject!) {
    updateGroupSettings(groupId: $groupId, data: $data) {
      id
      viewInMarketplace
    }
  }
`;
