"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_LESSON_SCHEDULE_STATE = void 0;
const client_1 = require("@apollo/client");
exports.SET_LESSON_SCHEDULE_STATE = (0, client_1.gql) `
  mutation SetLessonScheduleState($id: String!, $state: SchedulingState!) {
    setLessonScheduleState(id: $id, state: $state) {
      id
      state
    }
  }
`;
