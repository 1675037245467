"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteProjectTemplate = void 0;
const client_1 = require("@apollo/client");
const deleteOwnedProjectTemplate_1 = require("../mutations/deleteOwnedProjectTemplate");
const findProjectTemplates_1 = require("../queries/findProjectTemplates");
const useDeleteProjectTemplate = () => {
    const [deleteProject, { error, loading, client }] = (0, client_1.useMutation)(deleteOwnedProjectTemplate_1.DELETE_OWNED_PROJECT_TEMPLATE);
    const deleteProjectTemplate = (projectTemplateId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield deleteProject({
            variables: {
                projectTemplateId,
            },
            update: (cache) => {
                updateProjectsInCache(cache, projectTemplateId);
            },
        });
    });
    const updateProjectsInCache = (cache, deletedProjectId) => {
        const data = cache.readQuery({
            query: findProjectTemplates_1.FIND_MY_PROJECTS_TEMPLATES,
        });
        if (!(data === null || data === void 0 ? void 0 : data.findProjectTemplates)) {
            client.refetchQueries({ include: [findProjectTemplates_1.FIND_MY_PROJECTS_TEMPLATES] });
            return;
        }
        const updatedProjects = data.findProjectTemplates.filter((project) => project.id !== deletedProjectId);
        cache.writeQuery({
            query: findProjectTemplates_1.FIND_MY_PROJECTS_TEMPLATES,
            data: {
                findProjectTemplates: updatedProjects,
            },
        });
    };
    return { deleteProjectTemplate, loading, error };
};
exports.useDeleteProjectTemplate = useDeleteProjectTemplate;
