"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTeam = void 0;
const client_1 = require("@apollo/client");
const findTeam_1 = require("../queries/findTeam");
const useFindTeam = (teamId) => {
    const { data, loading, error } = (0, client_1.useQuery)(findTeam_1.FIND_TEAM, {
        variables: { teamId },
    });
    return { team: loading ? null : (data === null || data === void 0 ? void 0 : data.findTeam) || null, loading, error };
};
exports.useFindTeam = useFindTeam;
