"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INIT_GROUP_SETTINGS = void 0;
const client_1 = require("@apollo/client");
exports.INIT_GROUP_SETTINGS = (0, client_1.gql) `
  mutation InitGroupSettings($groupId: String!) {
    initGroupSettings(groupId: $groupId) {
      id
      availabilityEndDate
      startAt
      endAt
    }
  }
`;
