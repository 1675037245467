"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_NERDBORD_SUBSCRIPTIONS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_NERDBORD_SUBSCRIPTIONS = (0, client_1.gql) `
  query FindNerdbordProducts {
    findSubscriptionsPricing {
      id
      unit_amount
      product
      currency
      type
      recurring {
        aggregate_usage
        interval
        interval_count
        usage_type
      }
    }
  }
`;
