"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_TASK = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_TASK = (0, client_1.gql) `
  query FindMyTask($taskId: String!) {
    findMyTask(taskId: $taskId) {
      id
      state
      orderIndex
      lessonId
      taskScheduleId
      lesson {
        projectId
      }
      textSolutionId
      taskReview {
        id
        reviewState
        reviewRequests
      }
      taskTemplate {
        id
        title
        type
        briefId
        tags {
          name
        }
        lessonTemplate {
          id
          name
          theme
          orderIndex
        }
        pullRequestCondition {
          isOn
          isMergeRequired
          isRequired
        }
        reviewCondition {
          isOn
          isRequired
          price
        }
      }
    }
  }
`;
