"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_SUBSCRIPTION = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_SUBSCRIPTION = (0, client_1.gql) `
  query FindMySubscription {
    findMySubscription {
      id
      plan
      seats
      stripePeriodEnd
      stripeSubscriptionStatus
      isOnExpirationPeriod
    }
  }
`;
