"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TASK_REVIEW_REQUESTED_IN_ORG = void 0;
const client_1 = require("@apollo/client");
exports.TASK_REVIEW_REQUESTED_IN_ORG = (0, client_1.gql) `
  subscription TaskReviewRequestedInOrg($orgId: String!) {
    reviewRequestedInOrg(orgId: $orgId) {
      id
      reviewState
      reviewRequestedAt
      taskId
      task {
        id
        user {
          avatarUrl
          email
        }
        taskSchedule {
          assignee {
            avatarUrl
            email
          }
        }
        taskTemplate {
          title
          lessonTemplate {
            name
            theme
          }
        }
      }
    }
  }
`;
