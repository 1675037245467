"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindProjectScheduleLessonSchedules = void 0;
const client_1 = require("@apollo/client");
const findLessonSchedulesByProjectScheduleId_1 = require("../queries/findLessonSchedulesByProjectScheduleId");
const useFindProjectScheduleLessonSchedules = (projectScheduleId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findLessonSchedulesByProjectScheduleId_1.FIND_LESSON_SCHEDULES_BY_PROJECT_SCHEDULE_ID, {
        variables: { projectScheduleId },
        fetchPolicy: "network-only",
    });
    return {
        lessonSchedules: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findProjectScheduleLessonSchedules) || [],
        loading,
        refetch,
        error,
    };
};
exports.useFindProjectScheduleLessonSchedules = useFindProjectScheduleLessonSchedules;
