"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPublicPage = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const mappers_1 = require("../mappers");
const queries_1 = require("../queries");
const useFindPublicPage = (slug) => {
    const { data, loading } = (0, client_1.useQuery)(queries_1.FIND_PUBLIC_GROUP_PAGE, {
        variables: { slug },
    });
    return {
        page: loading
            ? constants_1.initialPageData
            : (data === null || data === void 0 ? void 0 : data.findPublicGroupPage)
                ? (0, mappers_1.mapGroupPageEntityToGroupPageData)(data.findPublicGroupPage)
                : constants_1.initialPageData,
    };
};
exports.useFindPublicPage = useFindPublicPage;
