"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateLessonTemplatesOrder = void 0;
const client_1 = require("@apollo/client");
const updateLessonTemplates_1 = require("../mutations/updateLessonTemplates");
const findProjectLessonTemplates_1 = require("../queries/findProjectLessonTemplates");
const useUpdateLessonTemplatesOrder = (projectTemplateId) => {
    const [updateExistingLessonTemplatesOrder] = (0, client_1.useMutation)(updateLessonTemplates_1.UPDATE_MY_LESSON_TEMPLATES);
    const reorderLessonTemplates = (lessonTemplates) => {
        const mappedLessonTemplatesToUpdate = lessonTemplates.map((element) => ({
            id: element.id,
            orderIndex: element.orderIndex,
        }));
        updateExistingLessonTemplatesOrder({
            variables: { data: mappedLessonTemplatesToUpdate },
            optimisticResponse: {
                __typename: "Mutation",
                updateMyLessonTemplates: mappedLessonTemplatesToUpdate,
            },
            update: (cache) => {
                updateLessonTemplatesOrderInCache(cache, lessonTemplates);
            },
        });
    };
    const updateLessonTemplatesOrderInCache = (cache, lessonTemplates) => {
        cache.writeQuery({
            query: findProjectLessonTemplates_1.FIND_OWNED_PROJECT_LESSON_TEMPLATES,
            variables: { projectTemplateId },
            data: {
                findOwnedProjectLessonTemplates: lessonTemplates,
            },
        });
    };
    return {
        reorderLessonTemplates,
    };
};
exports.useUpdateLessonTemplatesOrder = useUpdateLessonTemplatesOrder;
