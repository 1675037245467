"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateLessonTemplate = void 0;
const client_1 = require("@apollo/client");
const createLessonTemplate_1 = require("../mutations/createLessonTemplate");
const findProjectLessonTemplates_1 = require("../queries/findProjectLessonTemplates");
const useCreateLessonTemplate = () => {
    const [createSingleLesson, { error, loading }] = (0, client_1.useMutation)(createLessonTemplate_1.CREATE_LESSON_TEMPLATE);
    const createLessonTemplate = (projectTemplateId, theme) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const response = yield createSingleLesson({
            variables: {
                projectTemplateId,
                theme,
            },
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.createLessonTemplate))
                    return;
                updateProjectTemplateLessonsAmount(cache, projectTemplateId, data.createLessonTemplate);
                updateLessonTemplatesInCache(cache, projectTemplateId, data.createLessonTemplate);
            },
        });
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.createLessonTemplate;
    });
    const updateProjectTemplateLessonsAmount = (cache, projectTemplateId, newLessonTemplate) => {
        const projectTemplateToUpdate = {
            id: projectTemplateId,
            __typename: "ProjectTemplateEntity",
        };
        cache.modify({
            id: cache.identify(projectTemplateToUpdate),
            fields: {
                lessonTemplates(prev) {
                    return Object.assign(Object.assign({}, prev), { newLessonTemplate });
                },
            },
        });
    };
    const updateLessonTemplatesInCache = (cache, projectTemplateId, newLessonTemplate) => {
        const { findOwnedProjectLessonTemplates } = cache.readQuery({
            query: findProjectLessonTemplates_1.FIND_OWNED_PROJECT_LESSON_TEMPLATES,
            variables: { projectTemplateId },
        });
        cache.writeQuery({
            query: findProjectLessonTemplates_1.FIND_OWNED_PROJECT_LESSON_TEMPLATES,
            variables: { projectTemplateId },
            data: {
                findOwnedProjectLessonTemplates: [
                    ...findOwnedProjectLessonTemplates,
                    newLessonTemplate,
                ],
            },
        });
    };
    return { createLessonTemplate, loading, error };
};
exports.useCreateLessonTemplate = useCreateLessonTemplate;
