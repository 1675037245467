"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapProjectSchedule = void 0;
const mapProjectSchedule = (projectSchedule) => {
    var _a, _b;
    if (!projectSchedule.projectTemplate) {
        throw new Error("Project template is missing");
    }
    if (!projectSchedule.program) {
        throw new Error("Program is missing");
    }
    if (!projectSchedule.program.group) {
        throw new Error("Group is missing");
    }
    return {
        id: projectSchedule.id,
        name: projectSchedule.projectTemplate.name,
        subtitle: projectSchedule.projectTemplate.subtitle || null,
        theme: projectSchedule.projectTemplate.theme,
        projectTemplateId: projectSchedule.projectTemplate.id,
        isPublic: projectSchedule.isPublic,
        state: projectSchedule.state,
        type: projectSchedule.projectTemplate.type,
        tags: !!((_b = (_a = projectSchedule.projectTemplate) === null || _a === void 0 ? void 0 : _a.tags) === null || _b === void 0 ? void 0 : _b.length)
            ? projectSchedule.projectTemplate.tags.map((tag) => tag.name)
            : [],
        coverImageId: projectSchedule.projectTemplate.coverImageId || null,
        groupName: projectSchedule.program.group.name,
        groupState: projectSchedule.program.group.state,
        groupId: projectSchedule.program.groupId,
        maxCompletionTimeHours: projectSchedule.maxCompletionTimeHours || null,
    };
};
exports.mapProjectSchedule = mapProjectSchedule;
