"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialLessonValues = void 0;
const constants_1 = require("../lesson-schedule/constants");
exports.initialLessonValues = {
    state: "",
    projectId: "",
    lessonScheduleId: "",
    lessonSchedule: constants_1.initialLessonSchedule,
    isVisibleOnBoard: false,
    tasks: [],
    lessonTemplate: {},
    lessonTemplateId: "",
    id: "",
};
