"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetupDiscordChannelsForTeam = void 0;
const client_1 = require("@apollo/client");
const setupDiscordChannelsForTeam_1 = require("../mutations/setupDiscordChannelsForTeam");
const useSetupDiscordChannelsForTeam = () => {
    const [setup, { data, loading, error }] = (0, client_1.useMutation)(setupDiscordChannelsForTeam_1.SETUP_DISCORD_CHANNELS_FOR_TEAM);
    const setupDiscordChannelsForTeam = (teamId) => __awaiter(void 0, void 0, void 0, function* () {
        yield setup({
            variables: {
                teamId,
            },
            update(cache, { data }) {
                cache.modify({
                    id: cache.identify({
                        _type: "TeamEntity",
                        _id: teamId,
                    }),
                    fields: {
                        discordTextChannelId(prev) {
                            return ((data === null || data === void 0 ? void 0 : data.setupDiscordChannelsForTeam.discordTextChannelId) || prev);
                        },
                        discordVoiceChannelId(prev) {
                            return ((data === null || data === void 0 ? void 0 : data.setupDiscordChannelsForTeam.discordVoiceChannelId) || prev);
                        },
                    },
                });
            },
        });
    });
    return { setupDiscordChannelsForTeam, data, loading, error };
};
exports.useSetupDiscordChannelsForTeam = useSetupDiscordChannelsForTeam;
