"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_LESSON_SCHEDULE = void 0;
const client_1 = require("@apollo/client");
exports.FIND_LESSON_SCHEDULE = (0, client_1.gql) `
  query FindLessonSchedule($id: String!) {
    findLessonSchedule(id: $id) {
      id
      projectScheduleId
      lessonTemplate {
        id
        name
        theme
        orderIndex
        isPublished
        projectTemplateId
        briefId
      }
    }
  }
`;
