"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindGroupDiscordSettings = void 0;
const client_1 = require("@apollo/client");
const findGroupDiscordSettings_1 = require("../queries/findGroupDiscordSettings");
/**
 * Hook to fetch the Discord settings of a group.
 *
 * @param {string} groupId - The ID of the group.
 * @returns {Object} An object containing the following properties:
 * - discordSettings: The Discord settings of the group. If the data is still loading, this will be null.
 * - error: Any error that occurred while fetching the data.
 * - loading: A boolean indicating whether the data is still loading.
 * - refetch: A function to refetch the data.
 *
 * @example
 * const { discordSettings, error, loading, refetch } = useFindGroupDiscordSettings('groupId123');
 */
const useFindGroupDiscordSettings = (groupId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findGroupDiscordSettings_1.FIND_GROUP_DISCORD_SETTINGS, {
        variables: { groupId },
        fetchPolicy: "cache-first",
    });
    return {
        discordSettings: loading ? null : (data === null || data === void 0 ? void 0 : data.findGroupDiscordSettings) || null,
        error,
        loading,
        refetch,
    };
};
exports.useFindGroupDiscordSettings = useFindGroupDiscordSettings;
