"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCheckIfTaskIsCollaborative = void 0;
const client_1 = require("@apollo/client");
const checkIfTaskIsCollaborative_1 = require("../queries/checkIfTaskIsCollaborative");
const useCheckIfTaskIsCollaborative = (taskId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(checkIfTaskIsCollaborative_1.CHECK_IF_TASK_IS_COLLABORATIVE, {
        variables: { taskId },
    });
    return {
        isTaskCollaborative: loading
            ? false
            : (data === null || data === void 0 ? void 0 : data.checkIfTaskIsCollaborative) || false,
        error,
        loading,
        refetch,
    };
};
exports.useCheckIfTaskIsCollaborative = useCheckIfTaskIsCollaborative;
