"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_PAYMENT_CARDS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_PAYMENT_CARDS = (0, client_1.gql) `
  query FindMyPaymentCards {
    findMyPaymentCards {
      id
      card {
        last4
        brand
      }
    }
  }
`;
