"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindHackathonsEvaluations = void 0;
const client_1 = require("@apollo/client");
const findHackathonsEvaluations_1 = require("../queries/findHackathonsEvaluations");
const useFindHackathonsEvaluations = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findHackathonsEvaluations_1.FIND_HACKATHON_EVALUATIONS, {
        fetchPolicy: "cache-first",
    });
    return {
        projectEvaluations: loading ? [] : (data === null || data === void 0 ? void 0 : data.findHackathonsEvaluations) || [],
        error,
        loading,
        refetch,
    };
};
exports.useFindHackathonsEvaluations = useFindHackathonsEvaluations;
