"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PROJECT_SCHEDULE = void 0;
const client_1 = require("@apollo/client");
exports.FIND_PROJECT_SCHEDULE = (0, client_1.gql) `
  query FindProjectSchedule($id: String!) {
    findProjectSchedule(id: $id) {
      id
      maxCompletionTimeHours
      projectTemplate {
        id
        name
        theme
        orderIndex
        subtitle
        type
        difficultyLevel
        isPublished
        briefId
        tags {
          name
        }
      }
      lessonSchedules {
        id
      }
    }
  }
`;
