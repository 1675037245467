"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHANGE_ORG_MEMBER_ROLE = void 0;
const client_1 = require("@apollo/client");
exports.CHANGE_ORG_MEMBER_ROLE = (0, client_1.gql) `
  mutation ($memberEmail: String!, $role: Role!) {
    changeOrgMemberRole(memberEmail: $memberEmail, role: $role) {
      id
    }
  }
`;
