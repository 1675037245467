"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindOwnedProjectLessonTemplates = void 0;
const client_1 = require("@apollo/client");
const findProjectLessonTemplates_1 = require("../queries/findProjectLessonTemplates");
const useFindOwnedProjectLessonTemplates = (projectTemplateId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findProjectLessonTemplates_1.FIND_OWNED_PROJECT_LESSON_TEMPLATES, {
        variables: { projectTemplateId },
    });
    return {
        lessonTemplates: loading ? [] : (data === null || data === void 0 ? void 0 : data.findOwnedProjectLessonTemplates) || [],
        loading,
        refetch,
        error,
    };
};
exports.useFindOwnedProjectLessonTemplates = useFindOwnedProjectLessonTemplates;
