"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GENERATE_PROJECT_TEMPLATE = void 0;
const client_1 = require("@apollo/client");
exports.GENERATE_PROJECT_TEMPLATE = (0, client_1.gql) `
  mutation GenerateProjectTemplate($prompt: String!) {
    generateProjectTemplate(prompt: $prompt) {
      id
      name
      isPublished
      type
      state
      theme
      authorId
      author {
        email
        avatarUrl
        username
      }
      githubRepoTemplateName
      lessonTemplates {
        _count {
          taskTemplates
        }
      }
      _count {
        projectSchedules
      }
    }
  }
`;
