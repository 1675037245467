"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTaskReview = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findTaskReview_1 = require("../queries/findTaskReview");
const taskReviewUpdated_1 = require("../subscriptions/taskReviewUpdated");
const useFindTaskReview = (taskId) => {
    const { data, loading, subscribeToMore, client: { cache }, } = (0, client_1.useQuery)(findTaskReview_1.FIND_TASK_REVIEW, {
        variables: {
            taskId,
        },
    });
    const subscribeToTaskReviewUpdate = () => {
        subscribeToMore({
            document: taskReviewUpdated_1.TASK_REVIEW_UPDATED,
            variables: { taskId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data)
                    return prev;
                const { taskReviewUpdated } = subscriptionData.data;
                updateTaskReviewInCache(cache, taskReviewUpdated.id, taskReviewUpdated.reviewState);
                return {
                    findTaskReview: taskReviewUpdated,
                };
            },
        });
    };
    const updateTaskReviewInCache = (cache, taskReviewId, state) => {
        const reviewToUpdate = {
            id: taskReviewId,
            __typename: "TaskReviewEntity",
        };
        cache.modify({
            id: cache.identify(reviewToUpdate),
            fields: {
                reviewState() {
                    return state;
                },
            },
        });
    };
    return {
        taskReview: loading
            ? constants_1.initialTaskReview
            : (data === null || data === void 0 ? void 0 : data.findTaskReview) || constants_1.initialTaskReview,
        subscribeToTaskReviewUpdate,
        loading,
    };
};
exports.useFindTaskReview = useFindTaskReview;
