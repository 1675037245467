"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindMyOrgInvoiceDetails = void 0;
const client_1 = require("@apollo/client");
const findMyOrgInvoiceDetails_1 = require("../queries/findMyOrgInvoiceDetails");
const useFindMyOrgInvoiceDetails = () => {
    var _a;
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findMyOrgInvoiceDetails_1.FIND_MY_ORG_INVOICE_DETAILS);
    return {
        invoiceDetails: loading
            ? null
            : ((_a = data === null || data === void 0 ? void 0 : data.findMyOrganisation) === null || _a === void 0 ? void 0 : _a.invoiceDetails) || null,
        error,
        loading,
        refetch,
    };
};
exports.useFindMyOrgInvoiceDetails = useFindMyOrgInvoiceDetails;
