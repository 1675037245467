"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGenerateProjectTemplate = void 0;
const client_1 = require("@apollo/client");
const generateProjectTemplate_1 = require("../mutations/generateProjectTemplate");
const findProjectTemplates_1 = require("../queries/findProjectTemplates");
const useGenerateProjectTemplate = () => {
    const [generate, { error, loading }] = (0, client_1.useMutation)(generateProjectTemplate_1.GENERATE_PROJECT_TEMPLATE);
    const generateProjectTemplate = (prompt) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const response = yield generate({
            variables: {
                prompt,
            },
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.generateProjectTemplate))
                    return;
                updateProjects(cache, data.generateProjectTemplate);
            },
        });
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.generateProjectTemplate;
    });
    const updateProjects = (cache, addedProject) => {
        const { findProjectTemplates } = cache.readQuery({
            query: findProjectTemplates_1.FIND_MY_PROJECTS_TEMPLATES,
        });
        cache.writeQuery({
            query: findProjectTemplates_1.FIND_MY_PROJECTS_TEMPLATES,
            data: {
                findProjectTemplates: [...findProjectTemplates, addedProject],
            },
        });
    };
    return { generateProjectTemplate, loading, error };
};
exports.useGenerateProjectTemplate = useGenerateProjectTemplate;
