"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPublicGroupProgramEvaluations = void 0;
const client_1 = require("@apollo/client");
const findPublicProgramEvaluations_1 = require("../queries/findPublicProgramEvaluations");
const useFindPublicGroupProgramEvaluations = (groupId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findPublicProgramEvaluations_1.FIND_PUBLIC_GROUP_PROGRAM_EVALUATIONS, {
        variables: { groupId },
        fetchPolicy: "cache-first",
    });
    return {
        projectEvaluations: loading ? [] : (data === null || data === void 0 ? void 0 : data.findPublicProgramEvaluations) || [],
        error,
        loading,
        refetch,
    };
};
exports.useFindPublicGroupProgramEvaluations = useFindPublicGroupProgramEvaluations;
