"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTaskTemplate = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findMyTaskTemplate_1 = require("../queries/findMyTaskTemplate");
const useFindTaskTemplate = (taskTemplateId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findMyTaskTemplate_1.FIND_MY_TASK_TEMPLATE, {
        fetchPolicy: "cache-first",
        variables: {
            taskTemplateId,
        },
    });
    return {
        taskTemplate: loading
            ? constants_1.defaultTaskTemplate
            : (data === null || data === void 0 ? void 0 : data.findTaskTemplate) || constants_1.defaultTaskTemplate,
        loading,
        refetch,
        error,
    };
};
exports.useFindTaskTemplate = useFindTaskTemplate;
