"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTaskSubscriptions = void 0;
const client_1 = require("@apollo/client");
const react_1 = require("react");
const mappers_1 = require("../mappers");
const taskCreated_1 = require("../subscriptions/taskCreated");
const taskDeleted_1 = require("../subscriptions/taskDeleted");
const taskUpdated_1 = require("../subscriptions/taskUpdated");
const useTaskSubscriptions = (params) => {
    const { onTaskUpdated, onTaskDeleted, onTaskCreated } = params;
    const { data: updatedData } = (0, client_1.useSubscription)(taskUpdated_1.TASK_UPDATED, {
        variables: {
            projectId: params.projectId,
        },
    });
    const { data: deletedData } = (0, client_1.useSubscription)(taskDeleted_1.TASK_DELETED, {
        variables: {
            projectId: params.projectId,
        },
    });
    const { data: createdData } = (0, client_1.useSubscription)(taskCreated_1.TASK_CREATED, {
        variables: {
            projectId: params.projectId,
        },
    });
    (0, react_1.useEffect)(() => {
        if (updatedData) {
            onTaskUpdated((0, mappers_1.mapTaskEntityToTask)(updatedData.taskUpdated));
        }
    }, [updatedData]);
    (0, react_1.useEffect)(() => {
        if (deletedData) {
            onTaskDeleted(deletedData.taskDeleted.id);
        }
    }, [deletedData]);
    (0, react_1.useEffect)(() => {
        if (createdData) {
            onTaskCreated((0, mappers_1.mapTaskEntityToTask)(createdData.taskCreated));
        }
    }, [createdData]);
};
exports.useTaskSubscriptions = useTaskSubscriptions;
