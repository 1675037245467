"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCompileCode = void 0;
const client_1 = require("@apollo/client");
const compileCode_1 = require("../mutations/compileCode");
const useCompileCode = () => {
    const [compile, { loading }] = (0, client_1.useMutation)(compileCode_1.COMPILE_CODE);
    const compileCode = (code, lang) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield compile({ variables: { code, lang } });
        return (data === null || data === void 0 ? void 0 : data.compileCode) || null;
    });
    return { compileCode, loading };
};
exports.useCompileCode = useCompileCode;
