"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_UPCOMING_INVOICE = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_UPCOMING_INVOICE = (0, client_1.gql) `
  query FindMyUpcomingInvoice {
    findMyUpcomingInvoice {
      id
      currency
      next_payment_attempt
      amount_due
      subscription {
        id
        quantity
      }
    }
  }
`;
