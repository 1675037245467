"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESTART_PROJECT = void 0;
const client_1 = require("@apollo/client");
exports.RESTART_PROJECT = (0, client_1.gql) `
  mutation RestartProject($projectId: String!) {
    restartProject(projectId: $projectId) {
      id
    }
  }
`;
