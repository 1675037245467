"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_CODE_SOLUTION_TEMPLATE = void 0;
const core_1 = require("@apollo/client/core");
exports.UPDATE_CODE_SOLUTION_TEMPLATE = (0, core_1.gql) `
  mutation UpdateCodeSolutionTemplate($id: String!, $code: String!) {
    updateCodeSolutionTemplate(id: $id, code: $code) {
      id
      code
      language
    }
  }
`;
