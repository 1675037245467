"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RENEW_OWNED_SUBSCRIPTION = void 0;
const client_1 = require("@apollo/client");
exports.RENEW_OWNED_SUBSCRIPTION = (0, client_1.gql) `
  mutation RenewOwnedSubscription {
    renewMySubscription {
      id
      plan
      isOnExpirationPeriod
    }
  }
`;
