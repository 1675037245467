"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSyncGroupAccessPayment = void 0;
const client_1 = require("@apollo/client");
const graphql_1 = require("../../types/graphql");
const syncGroupAccessPayment_1 = require("../queries/syncGroupAccessPayment");
const useSyncGroupAccessPayment = (groupId, email) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(syncGroupAccessPayment_1.SYNC_GROUP_ACCESS_PAYMENT, {
        variables: { groupId, email },
    });
    return {
        paymentComplete: loading
            ? false
            : (data === null || data === void 0 ? void 0 : data.syncGroupAccessPayment.status) === graphql_1.AccessRequestStatus.APPROVED ||
                false,
        error,
        loading,
        refetch,
    };
};
exports.useSyncGroupAccessPayment = useSyncGroupAccessPayment;
