"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TASK_COMMENT_ADDED = void 0;
const client_1 = require("@apollo/client");
exports.TASK_COMMENT_ADDED = (0, client_1.gql) `
  subscription OnTaskCommentAddedSubscription($taskId: String!) {
    taskCommentAdded(taskId: $taskId) {
      id
      comment
      authorId
      author {
        id
        username
        avatarUrl
      }
    }
  }
`;
