"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_MY_DISCORD_USER_ID = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_MY_DISCORD_USER_ID = (0, client_1.gql) `
  mutation UpdateDiscordUserId($discordUserId: String!, $accessToken: String!) {
    updateDiscordUserId(
      discordUserId: $discordUserId
      accessToken: $accessToken
    ) {
      id
      email
      username
      avatarUrl
      isBlocked
      organisationId
      discordUserId
      roles
      isOnboardingComplete
    }
  }
`;
