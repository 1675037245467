"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DELETE_COMMENT = void 0;
const client_1 = require("@apollo/client");
exports.DELETE_COMMENT = (0, client_1.gql) `
  mutation DeleteComment($commentId: String!) {
    deleteComment(commentId: $commentId) {
      id
      comment
      authorId
    }
  }
`;
