"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_RATING = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_RATING = (0, client_1.gql) `
  query FindMyRating {
    findMyRating {
      id
      isLevelUp
      isRankUp
      experiencePoints
      experienceToNextLevel
      level
      rank
    }
  }
`;
