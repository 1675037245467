"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_UPSELL = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_UPSELL = (0, client_1.gql) `
  mutation CreateUpsell($groupId: String!, $groupSettingsId: String!) {
    createUpsell(groupId: $groupId, groupSettingsId: $groupSettingsId) {
      id
      groupId
      highlighted
    }
  }
`;
