"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_DOCUMENT = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_DOCUMENT = (0, client_1.gql) `
  mutation UpdateDocument(
    $id: String!
    $content: JSONObject!
    $notionPageId: String
  ) {
    updateDocument(id: $id, content: $content, notionPageId: $notionPageId) {
      id
      notionPageId
      content
    }
  }
`;
