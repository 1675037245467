"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAiPublicConditions = void 0;
const client_1 = require("@apollo/client");
const findAiConditions_1 = require("../queries/findAiConditions");
const useAiPublicConditions = (taskTemplateId) => {
    var _a;
    const aiConditions = (0, client_1.useQuery)(findAiConditions_1.FIND_AI_PUBLIC_CONDITIONS, {
        variables: {
            taskTemplateId,
        },
    });
    return {
        aiConditions: aiConditions.loading
            ? null
            : (_a = aiConditions.data) === null || _a === void 0 ? void 0 : _a.findAiConditions,
    };
};
exports.useAiPublicConditions = useAiPublicConditions;
