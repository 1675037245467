"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DETACH_PULL_REQUEST = void 0;
const client_1 = require("@apollo/client");
exports.DETACH_PULL_REQUEST = (0, client_1.gql) `
  mutation DetachPull($pullNumber: Int!, $repoName: String!) {
    pullDetach(pullNumber: $pullNumber, repoName: $repoName) {
      id
    }
  }
`;
