"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REVOKE_TEAM_API_KEY = void 0;
const core_1 = require("@apollo/client/core");
exports.REVOKE_TEAM_API_KEY = (0, core_1.gql) `
  mutation RevokeTeamApiKey($teamId: String!) {
    revokeTeamApiKey(teamId: $teamId) {
      id
    }
  }
`;
