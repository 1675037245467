"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SUBMIT_USER_REGISTRATION = void 0;
const client_1 = require("@apollo/client");
exports.SUBMIT_USER_REGISTRATION = (0, client_1.gql) `
  mutation SubmitHackathonUserRegistration(
    $payload: HackathonUserRegistrationFormPayload!
  ) {
    submitHackathonUserRegistration(payload: $payload) {
      id
      email
    }
  }
`;
