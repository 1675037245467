"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_MY_USERNAME = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_MY_USERNAME = (0, client_1.gql) `
  mutation UpdateMyUsername($username: String!) {
    updateMyUsername(username: $username) {
      id
      email
      username
      avatarUrl
      isBlocked
      organisationId
      discordUserId
      roles
      isOnboardingComplete
    }
  }
`;
