"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REMOVE_ORG_GITHUB_INSTALLATION = void 0;
const client_1 = require("@apollo/client");
exports.REMOVE_ORG_GITHUB_INSTALLATION = (0, client_1.gql) `
  mutation RemoveGithubOrgInstallation {
    removeGithubOrgInstallation {
      id
    }
  }
`;
