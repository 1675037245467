"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToggleGroupActive = void 0;
const client_1 = require("@apollo/client");
const activateGroup_1 = require("../mutations/activateGroup");
const deactivateGroup_1 = require("../mutations/deactivateGroup");
const useToggleGroupActive = () => {
    const [activate, activateRequest] = (0, client_1.useMutation)(activateGroup_1.ACTIVATE_GROUP);
    const [deactivate, deactivateRequest] = (0, client_1.useMutation)(deactivateGroup_1.DEACTIVATE_GROUP);
    const activateGroup = (id) => __awaiter(void 0, void 0, void 0, function* () {
        return yield activate({
            variables: {
                id,
            },
            update: (cache, { data }) => {
                if (data === null || data === void 0 ? void 0 : data.activateGroup) {
                    updateGroupInCache(cache, data.activateGroup.id, data.activateGroup.state);
                }
            },
        });
    });
    const deactivateGroup = (id) => __awaiter(void 0, void 0, void 0, function* () {
        return yield deactivate({
            variables: {
                id,
            },
            update: (cache, { data }) => {
                if (data === null || data === void 0 ? void 0 : data.deactivateGroup) {
                    updateGroupInCache(cache, data.deactivateGroup.id, data.deactivateGroup.state);
                }
            },
        });
    });
    const updateGroupInCache = (cache, id, state) => {
        const groupToUpdate = {
            id: id,
            __typename: "GroupEntity",
        };
        cache.modify({
            id: cache.identify(groupToUpdate),
            fields: {
                state() {
                    return state;
                },
            },
        });
    };
    return { deactivateGroup, activateGroup, deactivateRequest, activateRequest };
};
exports.useToggleGroupActive = useToggleGroupActive;
