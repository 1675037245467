"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_TASK_PROJECT = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_TASK_PROJECT = (0, core_1.gql) `
  query FindTaskProject($taskId: String!) {
    findTaskProject(taskId: $taskId) {
      progress {
        completedTasksAmount
        scheduledTasksAmount
      }
      project {
        id
        state
        projectScheduleId
        deploymentUrl
        repositoryOwner
        repositoryName
        teamId
        projectSchedule {
          collaborationType
          isPublic
          lessonSchedules {
            id
            state
            lessonTemplate {
              id
              orderIndex
              name
              briefId
            }
          }
        }
        projectTemplate {
          id
          name
          subtitle
          orderIndex
          theme
          type
          tags {
            name
          }
        }
      }
    }
  }
`;
