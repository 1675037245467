"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_OWNED_PROJECT_TEMPLATE = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_OWNED_PROJECT_TEMPLATE = (0, client_1.gql) `
  mutation UpdateOwnedProjectTemplate($input: ProjectTemplateUpdateInput!) {
    updateOwnedProjectTemplate(input: $input) {
      id
      subtitle
    }
  }
`;
