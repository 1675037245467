"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRemoveContentFromProgram = void 0;
const client_1 = require("@apollo/client");
const removeProjectSchedulesFromProgram_1 = require("../mutations/removeProjectSchedulesFromProgram");
const useRemoveContentFromProgram = () => {
    const [removeContent, { loading, called }] = (0, client_1.useMutation)(removeProjectSchedulesFromProgram_1.REMOVE_PROJECT_TEMPLATES_FROM_PROGRAM);
    const removeContentFromProgram = (programId, projectTemplateIds) => __awaiter(void 0, void 0, void 0, function* () {
        return yield removeContent({
            variables: {
                programId,
                projectTemplateIds,
            },
            update: (cache, { data }) => {
                if (data === null || data === void 0 ? void 0 : data.removeProjectSchedulesFromProgram) {
                    const programToUpdate = {
                        id: data.removeProjectSchedulesFromProgram.id,
                        __typename: "ProgramEntity",
                    };
                    cache.modify({
                        id: cache.identify(programToUpdate),
                        fields: {
                            projectSchedules(prev) {
                                return (data.removeProjectSchedulesFromProgram.projectSchedules ||
                                    prev);
                            },
                        },
                    });
                }
            },
        });
    });
    return { removeContentFromProgram, loading, called };
};
exports.useRemoveContentFromProgram = useRemoveContentFromProgram;
