"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteTaskTemplate = void 0;
const client_1 = require("@apollo/client");
const countMyOrgContent_1 = require("../../organisation/queries/countMyOrgContent");
const deleteTaskTemplate_1 = require("../mutations/deleteTaskTemplate");
const findOwnedLessonTaskTemplates_1 = require("../queries/findOwnedLessonTaskTemplates");
const useDeleteTaskTemplate = () => {
    const [deleteTemplate, { error, loading, client: { cache }, },] = (0, client_1.useMutation)(deleteTaskTemplate_1.DELETE_TASK_TEMPLATE);
    const deleteTaskTemplate = (id) => __awaiter(void 0, void 0, void 0, function* () {
        return yield deleteTemplate({
            variables: {
                id,
            },
            update: (_cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.deleteTaskTemplate))
                    return;
                decrementOrgTasksAmount();
                updateLessonTasks(data.deleteTaskTemplate.lessonTemplateId, data.deleteTaskTemplate.id);
                updateTaskTemplatesList(data.deleteTaskTemplate.id);
            },
        });
    });
    const decrementOrgTasksAmount = () => {
        const orgContent = cache.readQuery({
            query: countMyOrgContent_1.MONITOR_CONTENT_AMOUNT,
        });
        if (orgContent) {
            cache.writeQuery({
                query: countMyOrgContent_1.MONITOR_CONTENT_AMOUNT,
                data: {
                    monitorContentAmount: Object.assign(Object.assign({}, orgContent.monitorContentAmount), { tasks: orgContent.monitorContentAmount.tasks - 1 }),
                },
            });
        }
    };
    const updateLessonTasks = (lessonTemplateId, deletedTaskId) => {
        const lessonTemplateToUpdate = {
            id: lessonTemplateId,
            __typename: "LessonTemplateEntity",
        };
        cache.modify({
            id: cache.identify(lessonTemplateToUpdate),
            fields: {
                taskTemplates(prev) {
                    const updatedTaskTemplates = prev.filter((taskTemplate) => taskTemplate.id !== deletedTaskId);
                    return updatedTaskTemplates;
                },
            },
        });
    };
    const updateTaskTemplatesList = (deletedTaskTemplateId) => {
        const lessonID = getLessonID(cache, deletedTaskTemplateId);
        const { findOwnedLessonTaskTemplates } = cache.readQuery({
            query: findOwnedLessonTaskTemplates_1.FIND_OWNED_LESSON_TASK_TEMPLATES,
            variables: {
                lessonTemplateId: lessonID,
            },
        });
        const updatedTaskTemplates = findOwnedLessonTaskTemplates.filter((taskTemplate) => taskTemplate.id !== deletedTaskTemplateId);
        cache.writeQuery({
            query: findOwnedLessonTaskTemplates_1.FIND_OWNED_LESSON_TASK_TEMPLATES,
            variables: {
                lessonTemplateId: lessonID,
            },
            data: {
                findOwnedLessonTaskTemplates: updatedTaskTemplates,
            },
        });
    };
    const getLessonID = (cache, taskTemplateId) => {
        var _a;
        const taskToRemove = {
            id: taskTemplateId,
            __typename: "TaskTemplateEntity",
        };
        const taskTemplateToRemove = cache.readFragment({
            id: cache.identify(taskToRemove),
            fragment: (0, client_1.gql) `
        fragment RemovedTaskTemplate on TaskTemplateEntity {
          id
          lessonTemplate {
            id
          }
        }
      `,
        });
        return ((_a = taskTemplateToRemove.lessonTemplate) === null || _a === void 0 ? void 0 : _a.id) || 0;
    };
    return { deleteTaskTemplate, loading, error };
};
exports.useDeleteTaskTemplate = useDeleteTaskTemplate;
