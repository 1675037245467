"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateCodeSolutionLanguage = void 0;
const client_1 = require("@apollo/client");
const updateCodeSolutionLanguage_1 = require("../mutations/updateCodeSolutionLanguage");
const useUpdateCodeSolutionLanguage = () => {
    const [updateTaskTemplate, { error, loading, client }] = (0, client_1.useMutation)(updateCodeSolutionLanguage_1.UPDATE_CODE_SOLUTION_LANGUAGE);
    const updateCodeSolutionLanguage = (taskTemplateId, language) => __awaiter(void 0, void 0, void 0, function* () {
        return yield updateTaskTemplate({
            variables: {
                id: taskTemplateId,
                language,
            },
            update: (_cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.updateCodeSolutionLanguage.codeSolutionTemplate))
                    return;
                updateCodeSolutionTemplateInCache(data.updateCodeSolutionLanguage.codeSolutionTemplate.id, language);
            },
        });
    });
    const updateCodeSolutionTemplateInCache = (codeSolutionTemplateId, language) => {
        const codeSolutionTemplateToUpdate = {
            id: codeSolutionTemplateId,
            __typename: "CodeSolutionTemplateEntity",
        };
        client.cache.modify({
            id: client.cache.identify(codeSolutionTemplateToUpdate),
            fields: {
                language(prev) {
                    return language || prev;
                },
                code() {
                    return "";
                },
            },
        });
    };
    return {
        updateCodeSolutionLanguage,
        loading,
        error,
    };
};
exports.useUpdateCodeSolutionLanguage = useUpdateCodeSolutionLanguage;
