"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RETRY_PAYMENT_FOR_INVOICE = void 0;
const client_1 = require("@apollo/client");
exports.RETRY_PAYMENT_FOR_INVOICE = (0, client_1.gql) `
  mutation RetryPaymentForInvoice(
    $orgId: String!
    $paymentMethodId: String!
    $invoiceId: String!
  ) {
    retryPaymentForSubscription(
      orgId: $orgId
      paymentMethodId: $paymentMethodId
      invoiceId: $invoiceId
    ) {
      id
      payment_intent {
        status
        client_secret
      }
      subscription {
        id
        status
        product {
          id
        }
      }
    }
  }
`;
