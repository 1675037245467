"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REMOVE_ORG_MEMBER = void 0;
const client_1 = require("@apollo/client");
exports.REMOVE_ORG_MEMBER = (0, client_1.gql) `
  mutation ($memberEmail: String!) {
    removeOrgMember(memberEmail: $memberEmail) {
      id
    }
  }
`;
