"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INITIATE_GROUP_ACCESS_PAYMENT = void 0;
const client_1 = require("@apollo/client");
exports.INITIATE_GROUP_ACCESS_PAYMENT = (0, client_1.gql) `
  mutation (
    $groupId: String!
    $email: String!
    $checkoutParams: StripeCheckoutSessionCreateParams
  ) {
    initiateGroupAccessPayment(
      groupId: $groupId
      email: $email
      checkoutParams: $checkoutParams
    ) {
      id
      url
    }
  }
`;
