"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAppRepositories = void 0;
const client_1 = require("@apollo/client");
const getAppRepos_1 = require("../queries/getAppRepos");
const useGetAppRepositories = () => {
    const { data, loading, error, refetch } = (0, client_1.useQuery)(getAppRepos_1.GET_APP_REPOS, { fetchPolicy: "network-only" });
    return {
        repos: loading ? [] : (data === null || data === void 0 ? void 0 : data.githubAppRepositories) || [],
        error,
        refetch,
        loading,
    };
};
exports.useGetAppRepositories = useGetAppRepositories;
