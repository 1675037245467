"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_MY_STUDENT_TASKS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_MY_STUDENT_TASKS = (0, client_1.gql) `
  query FindMyStudentTasks($userId: String!, $programId: String!) {
    findMyStudentTasks(userId: $userId, programId: $programId) {
      id
      state
      orderIndex
      taskReview {
        id
        reviewState
      }
      taskTemplate {
        id
        title
        orderIndex
        lessonTemplate {
          id
          name
          theme
          taskTemplates {
            id
          }
        }
      }
    }
  }
`;
