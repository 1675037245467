"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPublicLessonTaskTemplates = void 0;
const client_1 = require("@apollo/client");
const findPublicLessonTaskTemplates_1 = require("../queries/findPublicLessonTaskTemplates");
const useFindPublicLessonTaskTemplates = (lessonTemplateId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findPublicLessonTaskTemplates_1.FIND_PUBLIC_LESSON_TASK_TEMPLATES, {
        variables: {
            lessonTemplateId,
        },
    });
    return {
        taskTemplates: loading ? [] : (data === null || data === void 0 ? void 0 : data.findPublicLessonTaskTemplates) || [],
        loading,
        refetch,
        error,
    };
};
exports.useFindPublicLessonTaskTemplates = useFindPublicLessonTaskTemplates;
