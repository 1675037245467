"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SETUP_DISCORD_CHANNELS_FOR_TEAM = void 0;
const core_1 = require("@apollo/client/core");
exports.SETUP_DISCORD_CHANNELS_FOR_TEAM = (0, core_1.gql) `
  mutation SetupDiscordChannelsForTeam($teamId: String!) {
    setupDiscordChannelsForTeam(teamId: $teamId) {
      id
      discordVoiceChannelId
      discordTextChannelId
    }
  }
`;
