"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRenewOwnedSubscription = void 0;
const client_1 = require("@apollo/client");
const renewMySubscription_1 = require("../mutations/renewMySubscription");
const useRenewOwnedSubscription = () => {
    const [renew, { error, loading, client: { cache }, },] = (0, client_1.useMutation)(renewMySubscription_1.RENEW_OWNED_SUBSCRIPTION);
    const renewSubscription = () => __awaiter(void 0, void 0, void 0, function* () {
        const { data, errors } = yield renew({
            update: (_cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.renewMySubscription))
                    return;
                updateSubscriptionInCache(data.renewMySubscription.id, data.renewMySubscription.isOnExpirationPeriod);
            },
        });
        return {
            data,
            errors,
        };
    });
    const updateSubscriptionInCache = (subscriptionId, isOnExpirationPeriod) => {
        const subscriptionToUpdate = {
            id: subscriptionId,
            __typename: "SubscriptionEntity",
        };
        cache.modify({
            id: cache.identify(subscriptionToUpdate),
            fields: {
                isOnExpirationPeriod(prev) {
                    return isOnExpirationPeriod || prev;
                },
            },
        });
    };
    return {
        renewSubscription,
        loading,
        error,
    };
};
exports.useRenewOwnedSubscription = useRenewOwnedSubscription;
