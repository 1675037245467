"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapUserSkillsToUserSkillsWithPublicUserData = void 0;
const mapUserSkillsToUserSkillsWithPublicUserData = (userSkills) => {
    return {
        experienceLevel: userSkills.experienceLevel,
        hasExperienceInTeamWork: userSkills.hasExperienceInTeamWork,
        hasExperienceWithAPIs: userSkills.hasExperienceWithAPIs,
        hasParticipatedInHackathon: userSkills.hasParticipatedInHackathon,
        employmentType: userSkills.employmentType,
        specialization: userSkills.specialization,
        techStack: !!userSkills.techStack.length
            ? userSkills.techStack.map((tech) => tech.name)
            : [],
        email: userSkills.user.email,
        username: userSkills.user.username || "",
    };
};
exports.mapUserSkillsToUserSkillsWithPublicUserData = mapUserSkillsToUserSkillsWithPublicUserData;
