"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindLessonSchedule = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findLessonSchedule_1 = require("../queries/findLessonSchedule");
const useFindLessonSchedule = (id) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findLessonSchedule_1.FIND_LESSON_SCHEDULE, {
        variables: { id },
        fetchPolicy: "cache-first",
    });
    return {
        lessonSchedule: loading
            ? constants_1.initialLessonSchedule
            : (data === null || data === void 0 ? void 0 : data.findLessonSchedule) || constants_1.initialLessonSchedule,
        loading,
        refetch,
        error,
    };
};
exports.useFindLessonSchedule = useFindLessonSchedule;
