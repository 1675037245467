"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_OWNED_LESSON_TASK_TEMPLATES = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_OWNED_LESSON_TASK_TEMPLATES = (0, core_1.gql) `
  query FindOwnedLessonTaskTemplates($lessonTemplateId: String!) {
    findOwnedLessonTaskTemplates(lessonTemplateId: $lessonTemplateId) {
      id
      title
      orderIndex
      isPublished
      lessonTemplate {
        name
        theme
      }
    }
  }
`;
