"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_TEXT_SOLUTION_TEMPLATE = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_TEXT_SOLUTION_TEMPLATE = (0, core_1.gql) `
  query FindTextSolutionTemplate($taskTemplateId: String!) {
    findTextSolutionTemplate(taskTemplateId: $taskTemplateId) {
      id
      templateId
    }
  }
`;
