"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialProgram = void 0;
const group_1 = require("../group");
exports.initialProgram = {
    groupId: "",
    id: "",
    group: group_1.initialGroup,
    projectSchedules: [],
};
