"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddProjectToProgram = void 0;
const client_1 = require("@apollo/client");
const addProjectToProgram_1 = require("../mutations/addProjectToProgram");
const useAddProjectToProgram = () => {
    const [addContent, { loading, called }] = (0, client_1.useMutation)(addProjectToProgram_1.ADD_PROJECT_TO_PROGRAM);
    const addProjectToProgram = (programId, projectTemplate, collaborationType) => __awaiter(void 0, void 0, void 0, function* () {
        return yield addContent({
            variables: {
                programId,
                projectTemplate,
                collaborationType,
            },
            update: (_cache, { data }) => {
                if (data === null || data === void 0 ? void 0 : data.addProgramProject) {
                }
            },
        });
    });
    return { addProjectToProgram, loading, called };
};
exports.useAddProjectToProgram = useAddProjectToProgram;
