"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REJECT_TASK = void 0;
const client_1 = require("@apollo/client");
exports.REJECT_TASK = (0, client_1.gql) `
  mutation RejectTaskReview($taskId: String!) {
    rejectTask(taskId: $taskId) {
      id
      taskReview {
        id
        reviewState
      }
    }
  }
`;
