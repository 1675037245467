"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_TASK_PULL_NUMBER = void 0;
const core_1 = require("@apollo/client/core");
exports.UPDATE_TASK_PULL_NUMBER = (0, core_1.gql) `
  mutation UpdateTaskPullNumber(
    $taskId: String!
    $pullNumber: Int!
    $branchName: String!
  ) {
    pullAttach(
      taskId: $taskId
      pullNumber: $pullNumber
      branchName: $branchName
    ) {
      id
    }
  }
`;
