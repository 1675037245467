"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapTaskEntityToTask = void 0;
const __1 = require("../..");
const mapTaskEntityToTask = (taskQuery) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    if (!taskQuery.taskTemplate) {
        throw Error("TaskTemplate include not found");
    }
    if (!((_a = taskQuery.taskTemplate) === null || _a === void 0 ? void 0 : _a.lessonTemplate)) {
        throw Error("LessonTemplate include not found");
    }
    return {
        id: taskQuery.id,
        title: taskQuery.taskTemplate.title,
        type: taskQuery.taskTemplate.type,
        briefId: taskQuery.taskTemplate.briefId,
        lessonId: taskQuery.lessonId || "",
        taskTemplateId: taskQuery.taskTemplate.id || "",
        taskScheduleId: (taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.taskScheduleId) || null,
        isEditable: !taskQuery.taskScheduleId,
        user: {
            id: ((_b = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.user) === null || _b === void 0 ? void 0 : _b.id) || "",
            username: ((_c = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.user) === null || _c === void 0 ? void 0 : _c.username) || "",
            email: ((_d = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.user) === null || _d === void 0 ? void 0 : _d.email) || "",
            avatarUrl: ((_e = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.user) === null || _e === void 0 ? void 0 : _e.avatarUrl) || "",
        },
        assignee: ((_f = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.taskSchedule) === null || _f === void 0 ? void 0 : _f.assignee)
            ? {
                id: taskQuery.taskSchedule.assignee.id,
                username: taskQuery.taskSchedule.assignee.username || "",
                email: taskQuery.taskSchedule.assignee.email,
                avatarUrl: taskQuery.taskSchedule.assignee.avatarUrl || "",
            }
            : null,
        tags: ((_h = (_g = taskQuery.taskTemplate) === null || _g === void 0 ? void 0 : _g.tags) === null || _h === void 0 ? void 0 : _h.map((tag) => tag.name)) || [],
        projectId: ((_j = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.lesson) === null || _j === void 0 ? void 0 : _j.projectId) || "",
        lessonTemplateName: ((_k = taskQuery.taskTemplate) === null || _k === void 0 ? void 0 : _k.lessonTemplate.name) || "",
        tasksAmountInLesson: ((_m = (_l = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.lesson) === null || _l === void 0 ? void 0 : _l.tasks) === null || _m === void 0 ? void 0 : _m.length) || 0,
        state: taskQuery.state,
        theme: ((_p = (_o = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.taskTemplate) === null || _o === void 0 ? void 0 : _o.lessonTemplate) === null || _p === void 0 ? void 0 : _p.theme) || __1.Theme.DEFAULT,
        taskTemplateOrderIndex: taskQuery.taskTemplate.orderIndex + 1,
        reviewState: ((_q = taskQuery === null || taskQuery === void 0 ? void 0 : taskQuery.taskReview) === null || _q === void 0 ? void 0 : _q.reviewState) || __1.TaskReviewState.NO_REVIEW_REQUESTED,
        orderIndex: taskQuery.orderIndex,
    };
};
exports.mapTaskEntityToTask = mapTaskEntityToTask;
