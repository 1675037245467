"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DELETE_UPSELL = void 0;
const core_1 = require("@apollo/client/core");
exports.DELETE_UPSELL = (0, core_1.gql) `
  mutation DeleteUpsell($groupUpsellId: String!) {
    deleteUpsell(groupUpsellId: $groupUpsellId) {
      id
    }
  }
`;
