"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useClearLevelUp = void 0;
const client_1 = require("@apollo/client");
const clearLevelUp_1 = require("../mutations/clearLevelUp");
const useClearLevelUp = () => {
    return (0, client_1.useMutation)(clearLevelUp_1.CLEAR_LEVEL_UP);
};
exports.useClearLevelUp = useClearLevelUp;
