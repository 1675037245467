"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_USER_SKILLS = void 0;
const client_1 = require("@apollo/client");
exports.GET_USER_SKILLS = (0, client_1.gql) `
  query GetUserSkills {
    getUserSkills {
      hasParticipatedInHackathon
      hasExperienceInTeamWork
      hasExperienceWithAPIs
      specialization
      experienceLevel
      employmentType
      techStack {
        name
      }
      user {
        email
        username
      }
    }
  }
`;
