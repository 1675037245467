"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GITHUB_ORG_CREDENTIALS_UPDATED = void 0;
const client_1 = require("@apollo/client");
exports.GITHUB_ORG_CREDENTIALS_UPDATED = (0, client_1.gql) `
  subscription GithubOrgCredentialsUpdated($organisationId: String!) {
    githubOrganisationCredentialsUpdated(organisationId: $organisationId) {
      installationId
    }
  }
`;
