"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindProjectTemplateCover = void 0;
const client_1 = require("@apollo/client");
const findProjectTemplateCover_1 = require("../queries/findProjectTemplateCover");
const useFindProjectTemplateCover = (projectTemplateId) => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(findProjectTemplateCover_1.FIND_PROJECT_TEMPLATE_COVER, {
        variables: { projectTemplateId },
        fetchPolicy: "cache-first",
    });
    return {
        coverImage: loading ? null : (data === null || data === void 0 ? void 0 : data.findProjectTemplateCover) || null,
        error,
        loading,
        refetch,
    };
};
exports.useFindProjectTemplateCover = useFindProjectTemplateCover;
