"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_EVALUATION_IS_PUBLIC = void 0;
const client_1 = require("@apollo/client");
exports.SET_EVALUATION_IS_PUBLIC = (0, client_1.gql) `
  mutation setEvaluationIsPublic($evaluationId: String!, $isPublic: Boolean!) {
    setEvaluationIsPublic(evaluationId: $evaluationId, isPublic: $isPublic) {
      id
      rating
      comment
      isPublic
    }
  }
`;
