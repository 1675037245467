"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindTextSolutionTemplate = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findTextSolutionTemplate_1 = require("../queries/findTextSolutionTemplate");
const useFindTextSolutionTemplate = (taskTemplateId) => {
    const { data, loading } = (0, client_1.useQuery)(findTextSolutionTemplate_1.FIND_TEXT_SOLUTION_TEMPLATE, {
        variables: {
            taskTemplateId,
        },
        fetchPolicy: "network-only",
    });
    return {
        textSolutionTemplate: loading
            ? constants_1.initialTextSolutionTemplate
            : (data === null || data === void 0 ? void 0 : data.findTextSolutionTemplate) || constants_1.initialTextSolutionTemplate,
        loading,
    };
};
exports.useFindTextSolutionTemplate = useFindTextSolutionTemplate;
