"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetRepoPulls = void 0;
const client_1 = require("@apollo/client");
const getTaskPulls_1 = require("../queries/getTaskPulls");
const useGetRepoPulls = (projectId) => {
    const { data, loading, error, refetch } = (0, client_1.useQuery)(getTaskPulls_1.GET_TASK_PULLS, {
        variables: { projectId },
        fetchPolicy: "network-only",
    });
    return {
        pulls: loading ? [] : (data === null || data === void 0 ? void 0 : data.pulls) || [],
        error,
        refetch,
        loading,
    };
};
exports.useGetRepoPulls = useGetRepoPulls;
