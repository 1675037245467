"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_PUBLIC_TASK_TEMPLATE = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_PUBLIC_TASK_TEMPLATE = (0, core_1.gql) `
  query FindPublicTaskTemplate($taskTemplateId: String!) {
    findPublicTaskTemplate(taskTemplateId: $taskTemplateId) {
      id
      title
      type
      isPublished
      lessonTemplateId
      briefId
      orderIndex
      tags {
        name
      }
      reviewCondition {
        isOn
        isRequired
      }
      lessonTemplate {
        id
        name
        theme
        projectTemplateId
      }
    }
  }
`;
