"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GENERATE_PROJECT_TEMPLATE_REPO = void 0;
const core_1 = require("@apollo/client/core");
exports.GENERATE_PROJECT_TEMPLATE_REPO = (0, core_1.gql) `
  mutation GenerateProjectRepo($projectTemplateId: String!) {
    generateProjectRepository(projectTemplateId: $projectTemplateId) {
      id
      githubRepoTemplateName
    }
  }
`;
