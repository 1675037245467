"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMyGithubOrgCredentials = exports.initialCredentials = void 0;
const client_1 = require("@apollo/client");
const getGithubOrgCredentials_1 = require("../queries/getGithubOrgCredentials");
const githubOrgCredentialsUpdated_1 = require("../subscriptions/githubOrgCredentialsUpdated");
exports.initialCredentials = {
    id: "",
    installationId: "",
    organisationId: "",
    orgName: "",
    createdAt: "",
    updatedAt: "",
};
const useMyGithubOrgCredentials = () => {
    const { data, loading, error, refetch, subscribeToMore } = (0, client_1.useQuery)(getGithubOrgCredentials_1.GET_GITHUB_ORG_CREDENTIALS);
    const subscribeToGithubCredentials = (organisationId) => {
        subscribeToMore({
            document: githubOrgCredentialsUpdated_1.GITHUB_ORG_CREDENTIALS_UPDATED,
            variables: {
                organisationId,
            },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data)
                    return prev;
                const { githubOrganisationCredentialsUpdated } = subscriptionData.data;
                return {
                    findMyOrganisationGithubCredentials: Object.assign(Object.assign({}, prev.findMyOrganisationGithubCredentials), githubOrganisationCredentialsUpdated),
                };
            },
        });
    };
    return {
        githubOrganisationCredentials: loading
            ? exports.initialCredentials
            : (data === null || data === void 0 ? void 0 : data.findMyOrganisationGithubCredentials) || exports.initialCredentials,
        loading,
        refetch,
        error,
        subscribeToGithubCredentials,
    };
};
exports.useMyGithubOrgCredentials = useMyGithubOrgCredentials;
