"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROGRAM_PROGRESS_UPDATED = void 0;
const client_1 = require("@apollo/client");
exports.PROGRAM_PROGRESS_UPDATED = (0, client_1.gql) `
  subscription ProgramProgressRecalculated($userId: String!) {
    programProgressRecalculated(userId: $userId) {
      programProgressInPercent
    }
  }
`;
