"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteUpsell = void 0;
const client_1 = require("@apollo/client");
const deleteUpsell_1 = require("../mutations/deleteUpsell");
const findGroupUpsells_1 = require("../mutations/findGroupUpsells");
const useDeleteUpsell = () => {
    const [deleteUpsell, { error, loading }] = (0, client_1.useMutation)(deleteUpsell_1.DELETE_UPSELL);
    const deleteGroupUpsell = (groupUpsellId, groupId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield deleteUpsell({
            variables: {
                groupUpsellId,
            },
            update: (cache) => {
                updateGroupsInCache(cache, groupId, groupUpsellId);
            },
        });
    });
    const updateGroupsInCache = (cache, groupId, groupUpsellId) => {
        const { findGroupUpsells } = cache.readQuery({
            query: findGroupUpsells_1.FIND_GROUP_UPSELLS,
            variables: { groupId },
        });
        const updatedUpsells = findGroupUpsells.filter((upsell) => upsell.id !== groupUpsellId);
        cache.writeQuery({
            query: findGroupUpsells_1.FIND_GROUP_UPSELLS,
            variables: { groupId },
            data: {
                findGroupUpsells: updatedUpsells,
            },
        });
    };
    return { deleteGroupUpsell, loading, error };
};
exports.useDeleteUpsell = useDeleteUpsell;
