"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPublicGroups = void 0;
const client_1 = require("@apollo/client");
const queries_1 = require("../queries");
const useFindPublicGroups = () => {
    const { data, error, loading, refetch } = (0, client_1.useQuery)(queries_1.FIND_PUBLIC_GROUPS, { fetchPolicy: "cache-first" });
    return {
        groups: loading ? [] : (data === null || data === void 0 ? void 0 : data.findPublicGroups) || [],
        error,
        loading,
        refetch,
    };
};
exports.useFindPublicGroups = useFindPublicGroups;
