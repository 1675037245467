"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGithubCredentials = void 0;
const client_1 = require("@apollo/client");
const getGithubCredentials_1 = require("../queries/getGithubCredentials");
const githubCredentialsUpdated_1 = require("../subscriptions/githubCredentialsUpdated");
const initialCredentials = {
    createdAt: "",
    id: "",
    username: "",
    userId: "",
};
const useGithubCredentials = (userId) => {
    const { data, loading, error, refetch, subscribeToMore } = (0, client_1.useQuery)(getGithubCredentials_1.GET_GITHUB_CREDENTIALS, {
        variables: { userId },
    });
    const subscribeToGithubCredentials = () => {
        subscribeToMore({
            document: githubCredentialsUpdated_1.GITHUB_CREDENTIALS_UPDATED,
            variables: { userId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data)
                    return prev;
                const { githubUserCredentialsUpdated } = subscriptionData.data;
                return {
                    githubCredentials: Object.assign(Object.assign({}, prev.githubCredentials), githubUserCredentialsUpdated),
                };
            },
        });
    };
    return {
        githubCredentials: loading
            ? initialCredentials
            : (data === null || data === void 0 ? void 0 : data.githubCredentials) || initialCredentials,
        loading,
        refetch,
        error,
        subscribeToGithubCredentials,
    };
};
exports.useGithubCredentials = useGithubCredentials;
