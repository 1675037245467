"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateProjectFromTemplate = void 0;
const client_1 = require("@apollo/client");
const createFromTemplate_1 = require("../mutations/createFromTemplate");
const findProjectTemplates_1 = require("../queries/findProjectTemplates");
const useCreateProjectFromTemplate = () => {
    const [create, { error, loading }] = (0, client_1.useMutation)(createFromTemplate_1.CREATE_PROJECT_FROM_TEMPLATE);
    const createProjectFromTemplate = (projectTemplateId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const response = yield create({
            variables: { projectTemplateId },
            update: (cache, { data }) => {
                if (!(data === null || data === void 0 ? void 0 : data.createProjectFromTemplate))
                    return;
                updateProjects(cache, data.createProjectFromTemplate);
            },
        });
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.createProjectFromTemplate;
    });
    const updateProjects = (cache, addedProject) => {
        const { findProjectTemplates } = cache.readQuery({
            query: findProjectTemplates_1.FIND_MY_PROJECTS_TEMPLATES,
        });
        if (!addedProject)
            return;
        cache.writeQuery({
            query: findProjectTemplates_1.FIND_MY_PROJECTS_TEMPLATES,
            data: {
                findProjectTemplates: [...findProjectTemplates, addedProject],
            },
        });
    };
    return { createProjectFromTemplate, loading, error };
};
exports.useCreateProjectFromTemplate = useCreateProjectFromTemplate;
