"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindOwnedLessonTemplate = void 0;
const client_1 = require("@apollo/client");
const constants_1 = require("../constants");
const findLessonTemplate_1 = require("../queries/findLessonTemplate");
const useFindOwnedLessonTemplate = (lessonTemplateId) => {
    const { data, loading, refetch, error } = (0, client_1.useQuery)(findLessonTemplate_1.FIND_LESSON_TEMPLATE, {
        variables: { id: lessonTemplateId },
        fetchPolicy: "cache-first",
    });
    return {
        lessonTemplate: loading
            ? constants_1.initialLessonTemplateValues
            : (data === null || data === void 0 ? void 0 : data.findLessonTemplate) || constants_1.initialLessonTemplateValues,
        loading,
        refetch,
        error,
    };
};
exports.useFindOwnedLessonTemplate = useFindOwnedLessonTemplate;
