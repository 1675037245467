"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RUN_TASK_TESTS = void 0;
const client_1 = require("@apollo/client");
exports.RUN_TASK_TESTS = (0, client_1.gql) `
  mutation RunTaskTests($taskId: String!) {
    runTaskTests(taskId: $taskId) {
      id
    }
  }
`;
